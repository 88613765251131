import * as userActions from './actions';

import { ActionType } from 'typesafe-actions';

export * from './actions';
export * from './epics';
export * from './reducer';
export * from './types';

export type UserActions = ActionType<typeof userActions>;
