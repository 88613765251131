export const declineDropDownItems = {
  Branding: 'Branding',
  'Editorial Policy': 'Editorial Policy',
  'Fair Trading': 'Fair Trading',
  Legal: 'Legal',
  'Marketing team': 'Marketing team',
  'Programme team': 'Programme team',
  Other: 'Other',
};
export const approveCatDropDownItems = {
  'Logo approved': 'Logo approved',
  'Text or copyright word approved': 'Text or copyright word approved',
  'Hyperlink approved': 'Hyperlink approved',
  'BBC identity approved': 'BBC identity approved',
  Other: 'Other',
};

export const redirectCatDropDownItems = {
  'BBC History': 'BBC History',
  'Brand Enforcement': 'Brand Enforcement',
  'Commercial agency': 'Commercial agency',
  Copyright: 'Copyright',
  'Fair Trading': 'Fair Trading',
  'I &amp; A': 'I &amp; A',
  Legal: 'Legal',
  'Marketing team': 'Marketing team',
  'Motion Gallery': 'Motion Gallery',
  'News Rights': 'News Rights',
  'News website requests': 'News website requests',
  'Picture Publicity': 'Picture Publicity',
  Worldwide: 'Worldwide',
  Other: 'Other',
};
