import React from 'react';

import { Button } from 'components/styled/shared/Button';
import { SubmitButton } from 'components/styled/shared/SubmitButton';
import FieldLabelText from 'components/styled/workflow/FieldLabelText';
import Field from 'components/styled/workflow/Field';

interface FormButtonsProps {
  onPreview?: (e: any) => void;
  onReset?: (e: any) => void;
  disabled: boolean;
}

const FormButtons: React.FC<FormButtonsProps> = ({
  onPreview,
  onReset,
  disabled,
}) => {
  return (
    <Field>
      <FieldLabelText />
      <div style={{ alignSelf: 'flex-start', flex: 3 }}>
        <SubmitButton type="submit" disabled={disabled} value="send" />
        {onPreview && <Button onClick={onPreview}>PREVIEW</Button>}
        {onReset && <Button onClick={onReset}>RESET</Button>}
      </div>
    </Field>
  );
};

export default FormButtons;
