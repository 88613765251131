import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import AdminPageHeader from '../AdminPageHeader';
import FormBody from '../../../styled/workflow/FormBody';
import FormDescription from '../../../styled/workflow/FormDescription';
import DumbFormSection from '../../shared/DumbFormSection';
import FormField from '../Field';
import FormFieldRead from '../FieldRead';
import FormButtons from '../FormButtons';
import { AcceptFormProps } from '../../../../dataaccess/requests/types';
import FormFieldReadLogos from '../FieldReadLogos';
import { ApplicationState } from '../../../../store';
import { AcceptLicenseSuccess } from '../../../container/AcceptLicenseSuccess';
import { AcceptLicenseCancelled } from '../../../container/AcceptLicenseCancelled';
import { LoadingView } from '../../shared/LoadingView';
import * as submitActions from '../../../../store/submit';
import { getFrontEndUrlForPath } from '../../../../utils/workflowUtils';

const AcceptForm: React.FC<AcceptFormProps> = ({ details }) => {
  const { data, license, logos, schedules } = details;

  const dispatch = useDispatch();
  const {
    isSubmitSuccess,
    isSubmitting,
    logoGuidelines,
    submitError,
  } = useSelector((state: ApplicationState) => state.submit);

  const {
    branding_request_id,
    name,
    company_name,
    position,
    email,
    address,
    telephone,
    brand_assets,
    other_assets,
    territory,
    film_name,
  } = data;

  const { register, handleSubmit, formState, errors, reset } = useForm({
    submitFocusError: true,
    defaultValues: {
      company_name,
      name,
      position,
      agreeString: undefined,
      request_id: branding_request_id,
    },
  });

  const onSubmit = (data: any) => {
    if (!formState.isSubmitting) {
      data.request_id = branding_request_id;
      data.agree = data.agreeString === 'true';
      data.licenseUrl = getFrontEndUrlForPath(
        `/agreement/{request_id}`,
        branding_request_id
      );
      dispatch(submitActions.acceptLicense(data));
    }
  };

  if (isSubmitSuccess) {
    if (logoGuidelines) {
      return <AcceptLicenseSuccess />;
    } else {
      return <AcceptLicenseCancelled />;
    }
  }

  if (isSubmitting) {
    return <LoadingView />;
  }

  return (
    <>
      <AdminPageHeader
        title="Production Licence Agreement"
        subtitle={`Production Licence Agreement for the use of BBC assets in ${film_name ||
          '"missing film_name"'}`}
      />

      <FormBody onSubmit={handleSubmit(onSubmit)}>
        <FormDescription>
          Please accept the terms and conditions of the Production Licence
          Agreement and enter a date at the bottom of the page.
        </FormDescription>

        <DumbFormSection title={'1. COMPANY'}>
          <FormFieldRead labelText="Company Name:" value={company_name} />
          <FormFieldRead labelText="Contact Name:" value={name} />
          <FormFieldRead labelText="Position:" value={position} />
          <FormFieldRead labelText="Address:" value={address} />
          <FormFieldRead labelText="Telephone:" value={telephone} />
          <FormFieldRead labelText="Email adress:" value={email} />
        </DumbFormSection>

        <DumbFormSection title="2. TRADE MARK(S) / ASSETS">
          {logos && (
            <FormFieldReadLogos
              labelText="BBC logos requested:"
              logos={logos}
            />
          )}
          {other_assets && (
            <FormFieldRead
              labelText="BBC programme logos:"
              value={other_assets}
            />
          )}
        </DumbFormSection>

        <DumbFormSection title={'3. TITLE OF THE FILM/PROGRAMME'}>
          <FormFieldRead value={film_name} />
        </DumbFormSection>

        <DumbFormSection title={'4. TERRITORY'}>
          <FormFieldRead value={territory} />
        </DumbFormSection>

        <DumbFormSection
          fullWidth={true}
          title="5. THE AGREEMENT"
          subtitle={`USE OF BBC LOGO/ASSETS IN "${film_name ||
            'missing film_name'}" (the "Film")`}
        >
          {schedules?.length > 0
            ? schedules.map(s => (
                <FormFieldRead
                  labelText={`Schedule ${s.letter}`}
                  value={s.name}
                  key={s.name}
                />
              ))
            : null}
        </DumbFormSection>

        <DumbFormSection fullWidth title="">
          <div
            dangerouslySetInnerHTML={{
              __html:
                license?.agreement ||
                'something went wrong with the license text',
            }}
          />
        </DumbFormSection>

        <DumbFormSection title="Agreed by or on behalf of the Company:">
          <FormField
            inputType="input"
            fieldName="company_name"
            labelText="Company Name:"
            register={register}
            required="Please provide a company name"
            errors={errors}
          />

          <FormField
            inputType="input"
            fieldName="name"
            labelText="Name:"
            register={register}
            required="Please provide your name"
            errors={errors}
          />

          <FormField
            inputType="input"
            fieldName="position"
            labelText="Position:"
            register={register}
            required="Please provide your position"
            errors={errors}
          />

          <FormField
            labelText={'Date:'}
            fieldName={'date'}
            inputType={'date'}
            dateValue={undefined}
            register={register}
            errors={errors}
            required="Please provide a signature date"
          />

          <FormField
            inputType="agree_radio"
            fieldName="agreeString"
            labelText="Please choose:"
            options={[
              {
                value: true,
                label:
                  '  I agree with the terms and conditions of the Production Licence Agreement',
              },
              {
                value: false,
                label:
                  'I do not accept the terms and conditions of the Production Licence Agreement and wish to cancel the request',
              },
            ]}
            register={register}
            required="Please choose an option"
            errors={errors}
          />
        </DumbFormSection>
        <DumbFormSection title="Agreed on behalf of the BBC:">
          <FormFieldRead labelText="Name:" value={details.license?.bbc_name} />

          <FormFieldRead
            labelText="Position:"
            value={details.license?.bbc_position}
          />

          <FormFieldRead
            labelText="Date:"
            dateValue={details.license?.bbc_date}
          />
        </DumbFormSection>
        <FormButtons disabled={formState.isSubmitting} onReset={reset} />
      </FormBody>
    </>
  );
};

export default AcceptForm;
