import * as React from 'react';
import { LabelInline, LabelPlaceholder } from 'components/styled/shared/Label';
import { LabelText } from 'components/styled/shared/LabelText';
import { InputField } from 'components/styled/shared/InputField';

interface CommonItem {
  name: string;
  request_type_id: number;
}

export interface LogoItem extends CommonItem {
  logo_id: string;
}

export interface AssetItem extends CommonItem {
  asset_id: string;
}

interface LogosProps {
  logos: LogoItem[];
  assets: AssetItem[];
  requestType: number;
  register: () => void;
  text?: string;
  requestedLogos?: string[];
  requestedAssets?: string[];
}

export const Logos: React.FC<LogosProps> = ({
  logos,
  assets,
  requestType,
  register,
  text,
  requestedLogos,
  requestedAssets,
}) => {
  return (
    <LabelPlaceholder key="logos">
      <LabelText>{text || 'BBC logo(s) you wish to use'}</LabelText>
      <InputField>
        {logos.map(
          (logo: { logo_id: string; name: string; request_type_id: number }) =>
            logo.request_type_id === requestType && (
              <LabelInline
                key={logo.logo_id}
                width={requestType === 1 ? 'full' : ''}
              >
                <input
                  name={`logos[${logo.logo_id}]`}
                  type="checkbox"
                  ref={register}
                  defaultChecked={
                    !!requestedLogos?.find(
                      requestedLogo => requestedLogo === logo.logo_id
                    )
                  }
                />
                {logo.name}
              </LabelInline>
            )
        )}
        {assets.map(
          (asset: {
            asset_id: string;
            name: string;
            request_type_id: number;
          }) =>
            asset.request_type_id === requestType && (
              <LabelInline
                key={asset.asset_id}
                width={requestType === 1 ? 'full' : ''}
              >
                <input
                  name={`assets[${asset.asset_id}]`}
                  type="checkbox"
                  ref={register}
                  defaultChecked={
                    !!requestedAssets?.find(
                      requestedAsset => requestedAsset === asset.asset_id
                    )
                  }
                />
                {asset.name}
              </LabelInline>
            )
        )}
      </InputField>
    </LabelPlaceholder>
  );
};
