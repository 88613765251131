import React, { useEffect } from 'react';
import { PageTitle, PageSubTitle } from '../presentational/shared/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import * as requestsAction from 'store/requests/actions';
import { ApplicationState } from 'store';
import { LoadingView } from 'components/presentational/shared/LoadingView';
import { RequestsList } from 'components/presentational/request/RequestsList';
import {
  brandingTableColumns,
  tmlTableColumns,
  newsTableColumns,
  plaTableColumns,
} from 'components/presentational/request/RequestsTableColumns';

export const NewRequests: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isNewRequestsLoading,
    data
  } = useSelector((state: ApplicationState) => state.requests);
  useEffect(() => {
    dispatch(requestsAction.getNewRequests());
  }, [dispatch]);

  if (isNewRequestsLoading) {
    return <LoadingView />;
  }

  const {
    newRequests: { branding, news, pla, tml },
  } = data;

  return (
    <>
      <PageTitle title="Branding requests" />
      <RequestsList
        data={branding}
        customTableColumns={brandingTableColumns}
        sortable={false}
        displayOnlyTableBody={true}
      />
      <PageSubTitle title="Trade Mark Licence accepted/Final artwork upload" />
      <RequestsList
        data={tml}
        customTableColumns={tmlTableColumns}
        sortable={false}
        displayOnlyTableBody={true}
      />
      <PageTitle title="BBC News in a film or programme requests" />
      <RequestsList
        data={news}
        customTableColumns={newsTableColumns}
        sortable={false}
        displayOnlyTableBody={true}
      />
      <PageSubTitle title="Production Licence Agreement accepted" />
      <RequestsList
        data={pla}
        customTableColumns={plaTableColumns}
        sortable={false}
        displayOnlyTableBody={true}
      />
    </>
  );
};
