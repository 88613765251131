import styled from 'styled-components';

interface CollapseProps {
  isOpen?: boolean;
}

export const CollapseWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 12px 0;
`;

export const CollapseHeader = styled.div<CollapseProps>`
  background: ${({ theme }) => theme.colors.collapseHeaderBackground};
  padding: 12px 0px 12px 8px;
  margin-bottom: 12px;
  font-size: ${({ theme }) => theme.font.size.h4};
  position: relative;
  cursor: pointer;
  &:after {
    font-family: 'FontAwesome';
    content: '\\f078';
    color: #666;
    float: right;
    margin-right: 20px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
  }
`;

export const CollapseContent = styled.div<CollapseProps>`
  width: 100%;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  overflow: hidden;
`;
