import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import * as requestsAction from 'store/requests/actions';
import queryString from 'query-string';

const useMessageReply = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    expires = '',
    signature = '',
  }: { expires?: string; signature?: string } = queryString.parse(
    location.search
  );

  useEffect(() => {
    dispatch(requestsAction.getMessage(id, expires, signature));
  }, [dispatch, id]);

  const { message, error: actionError } = useSelector(
    (state: ApplicationState) => state.requests
  );

  const sendReply = (message: string) => {
    if (expires && signature) {
      dispatch(requestsAction.replyToMessage(id, message, expires, signature));
    } else {
      dispatch(
        requestsAction.getError({ message: 'Sending reply is not authorised.' })
      );
    }
  };

  return {
    messageData: message, 
    sendReply,
    isSubmitting: message?.isSubmitting || false,
    error: actionError,
  };
};

export default useMessageReply;
