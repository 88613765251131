import * as React from 'react';
import { PicturePad } from 'components/styled/shared/CustomTable';
import { FileData, FileStatus } from '../../../dataaccess/requests/types';

interface UploadedFilesProps {
  files: FileData[];
}

const fileStatusTexts = [
  {
    status: FileStatus.processing,
    text: '(processing)',
  },
  {
    status: FileStatus.cleared,
    text: '',
  },
  {
    status: FileStatus.removed,
    text: '(removed)',
  },
];

export const UploadedFiles: React.FC<UploadedFilesProps> = ({ files }) => {
  return (
    <>
      {files.map((file: FileData, index: number) => {
        return (
          <PicturePad key={index}>
            {file.status === FileStatus.cleared ? (
              <a href={file.url} target="_blank">
                {file.name}
              </a>
            ) : (
              <span style={{color: 'red'}}>
                {file.name +
                  ' ' +
                  fileStatusTexts.find(
                    statusText => statusText.status === file.status
                  )?.text}
              </span>
            )}
          </PicturePad>
        );
      })}
    </>
  );
};
