import * as React from 'react';
import { PicturePad } from 'components/styled/shared/CustomTable';

interface PlainListProps {
  list: string[];
}

export const PlainList: React.FC<PlainListProps> = ({ list }) => {
  return (
    <>
      {list.map((item: any, index: number) => {
        return <PicturePad key={index}>{item}</PicturePad>;
      })}
    </>
  );
};
