import * as React from 'react';
import {
  CustomTable,
  TableRow,
  HeaderCell,
  DataCell,
} from 'components/styled/shared/CustomTable';
import { Collapse } from 'components/presentational/shared/Collapse';
import { Artwork, RequestStatuses } from '../../../dataaccess/requests/types';
import readableDate from '../../../utils/readableDate';
import { UploadedFiles } from './UploadedFiles';
import WorkflowActionButtons from '../workflow/WorkflowActionButtons';
import {
  approveArtworkDecision,
  declineArtworkDecision,
  forwardArtworkDecision,
} from '../../../types/workflow';

interface FinalArtworkUploadProps {
  requestId: string;
  artwork?: Artwork;
}

export const FinalArtworkUpload: React.FC<FinalArtworkUploadProps> = ({
  requestId,
  artwork,
}) => (
  <CustomTable>
    <tbody>
      <TableRow>
        <HeaderCell>Final artwork upload:</HeaderCell>
        <DataCell>
          <UploadedFiles files={artwork?.uploads ?? []} />
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Final artwork date received:</HeaderCell>
        <DataCell>{readableDate(artwork?.created_at)}</DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Other comments on upload:</HeaderCell>
        <DataCell>{artwork?.other_comments}</DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell></HeaderCell>
        <DataCell>
          {artwork && (
            <WorkflowActionButtons
              decisions={[
                approveArtworkDecision,
                declineArtworkDecision,
                forwardArtworkDecision,
              ]}
              brandingRequestId={requestId}
            />
          )}
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Decline artwork message:</HeaderCell>
        <DataCell>
          <div
            dangerouslySetInnerHTML={{
              __html: artwork?.decline_message || '',
            }}
          />
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Decline artwork date:</HeaderCell>
        <DataCell>{readableDate(artwork?.declined_date)}</DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Artwork approval message:</HeaderCell>
        <DataCell>
          <div
            dangerouslySetInnerHTML={{
              __html: artwork?.approve_message || '',
            }}
          />
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Artwork approval date:</HeaderCell>
        <DataCell>{readableDate(artwork?.approved_date)}</DataCell>
      </TableRow>
    </tbody>
  </CustomTable>
);
