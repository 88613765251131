import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as userActions from 'store/user/actions';
import { ApplicationState } from 'store';
import { LoadingView } from 'components/presentational/shared/LoadingView';

export const withAuthVerification = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => ({ ...props }) => {
  const dispatch = useDispatch();
  const state = useSelector((state: ApplicationState) => state.user);

  useEffect(() => {
    dispatch(userActions.verifyToken());
  }, [dispatch]);

  if (state.isLoading) {
    return <LoadingView />;
  }

  return <Component {...(props as P)} />;
};
