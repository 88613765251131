import React from 'react';
import { FieldError, NestDataObject } from 'react-hook-form';

interface RegisterType {
  required?: boolean | string;
  pattern?: RegExp;
  maxLength?: number;
}

type RegisterFuncType = (
  e: HTMLSelectElement | null,
  arg1: RegisterType
) => (instance: HTMLSelectElement) => void;

interface DropDownProps<T> {
  name: string;
  className?: string;
  required?: boolean | string;
  pattern?: RegExp;
  maxLength?: number;
  register: RegisterFuncType;
  errors?: NestDataObject<T, FieldError>;
  items: { [key: string]: string };
  value?: string | number | null;
  disableDefaultText?: boolean;
}

type DropDownType<T = any> = React.FC<DropDownProps<T>>;

export const DropDown: DropDownType = ({
  name,
  items,
  className,
  required,
  pattern,
  maxLength,
  register,
  errors,
  value,
  disableDefaultText,
}) => {
  const finalClassName = className ?? (errors && errors[name] ? 'error' : '');
  return (
    <select
      name={name}
      ref={e => register(e, { required, pattern, maxLength })}
      className={finalClassName}
      defaultValue={value || ''}
    >
      {' '}
      <option disabled={true} hidden={true} value="">
      {!disableDefaultText && 'Please select'}
      </option>
      {Object.keys(items).map((item, index) => {
        return (
          <option key={index} value={item}>
            {items[item]}
          </option>
        );
      })}
    </select>
  );
};
