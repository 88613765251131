export interface Schedule {
  name: string;
  letter: string;
}

export enum ActionTypes {
  ApproveWithTML = "ApproveWithTML",
  ApproveNoTML = "ApproveNoTML",
  ApproveWithPLA = "ApproveWithPLA",
  NoVisualSendDesign = "NoVisualSendDesign",
  DeclineWithMessage = "DeclineWithMessage",
  ForwardAsEmail = "ForwardAsEmail",
  SendEmail = "SendEmail",
  RedirectAsEmail = "RedirectAsEmail",
  SetAsInactive = "SetAsInactive",
  AcceptAgreement = "AcceptAgreement",
  SendReminder = "SendReminder",
  ApproveArtwork = "ApproveArtwork",
  DeclineArtwork = "DeclineArtwork",
  ForwardArtwork = "ForwardArtwork",
}

export interface NotificationPreviewRequest {
  action_id: string;
  request_id: string;
  recipient_name?: string;
  message?: string;
  message2?: string;
  sender: string;
  url?: string;
}

export interface ActionRequest {
  action_id: string;
  request_id: string;
}

export interface SetAsInactiveActionRequest extends ActionRequest {
  status: number;
}

export interface ApproveNoTmlRequest extends ActionRequest {
  category: string;
  category_other: string;
  sender: string;
  url: string;
  recipient: string;
  recipient_address: string;
  message: string;
  message2: string;
}

export interface ApproveWithTmlRequest extends ActionRequest {
  category: string;
  category_other: string;
  bbc_name: string;
  bbc_position: string;
  bbc_tml_date: string;
  url: string;
  agreement: string;
}

export interface NoVisualSendDesignRequest extends ActionRequest {
  url: string;
  recipient: string;
  message: string;
  sender: string;
  message2: string;
  recipient_address: string;
}

export interface DeclineWithMessageRequest extends ActionRequest {
  category: string;
  category_other: string;
  recipient: string;
  recipient_address: string;
  message: string;
  sender: string;
}

export interface ForwardAsEmailRequest extends ActionRequest {
  recipient_address: string;
  sender_address: string;
  cc_address: string;
  subject: string;
  message: string;
}

export interface SendEmailRequest extends ActionRequest {
  cc_address: string;
  recipient_address?: string;
  subject: string;
  message: string;
  sender: string;
  recipient: string;
}

export interface RedirectAsEmailRequest extends ForwardAsEmailRequest {
  category: string;
  category_other: string;
}

export interface ApproveWithPlaRequest extends ApproveWithTmlRequest {
  schedules: Schedule[];
}

export interface AcceptAgreementRequest extends ActionRequest {
  agree: "agree" | "disagree"
  company_name: string;
  name: string;
  position: string;
  license: string;
}

export interface SendReminderRequest extends ActionRequest {
  url: string;
  recipient: string;
  recipient_address: string;
  message: string;
  sender: string;
}

export type AllActionRequest = SetAsInactiveActionRequest |
  ApproveNoTmlRequest |
  ApproveWithTmlRequest |
  ApproveWithPlaRequest |
  NoVisualSendDesignRequest |
  DeclineWithMessageRequest |
  ForwardAsEmailRequest |
  SendEmailRequest |
  RedirectAsEmailRequest |
  AcceptAgreementRequest |
  SendReminderRequest;
