import { Reducer } from 'redux';
import { RequestsState } from './types';
import * as requestsActions from './actions';
import { getType } from 'typesafe-actions';

export const initialState: RequestsState = {
  data: {},
  isLoading: true,
  error: null,
  isMetadataLoading: true,
  metadata: {},
  isDetailsLoading: true,
  isNewRequestsLoading: true,
  details: undefined,
  query: '',
  isSearching: false,
  searchResults: [],
  summary: {
    total_requests_by_status: [],
    approved_by_category: [],
    declined_by_category: [],
  },
  messages: [],
  comment: {
    isCommentEditing: false,
    hasEditError: false,
  },
  message: null,
};

const reducer: Reducer<RequestsState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(requestsActions.requestListAction): {
      return { ...state, isLoading: true };
    }
    case getType(requestsActions.getListSuccess): {
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isLoading: false,
        error: null,
        dataLength: action.payload.length,
      };
    }
    case getType(requestsActions.getError): {
      console.log('action.payload: ', action.payload);
      const { message, keepData } = action.payload;
      return {
        ...state,
        data: keepData ? state.data : {},
        metadata: keepData ? state.metadata : {},
        error: message,
        isLoading: false,
        isMetadataLoading: false,
        isDetailsLoading: false,
        query: '',
        searchResults: [],
        isSearching: false,
      };
    }
    case getType(requestsActions.getSearchQuery): {
      return {
        ...state,
        query: action.payload.query,
        isSearching: true,
      };
    }

    case getType(requestsActions.getSearchSuccess): {
      return {
        ...state,
        searchResults: action.payload,
        isLoading: false,
        error: null,
        isSearching: false,
      };
    }

    case getType(requestsActions.requestsMetadata): {
      return { ...state, isMetadataLoading: true };
    }

    case getType(requestsActions.getMetadataSuccess): {
      return {
        ...state,
        metadata: action.payload,
        isMetadataLoading: false,
        error: null,
      };
    }

    case getType(requestsActions.requestDetailsAction): {
      return { ...state, isDetailsLoading: true };
    }

    case getType(requestsActions.requestDetailsWithSignatureAction): {
      return { ...state, isDetailsLoading: true };
    }

    case getType(requestsActions.getRequestDetailsSuccess): {
      return {
        ...state,
        isDetailsLoading: false,
        details: action.payload,
        error: null,
        isCommentEditing: false,
        comment: {
          ...state.comment,
          isCommentEditing: false,
          hasEditError: false,
        },
      };
    }

    case getType(requestsActions.getNewRequestsSuccess): {
      return {
        ...state,
        data: { ...state.data, newRequests: action.payload },
        isNewRequestsLoading: false,
        error: null,
      };
    }

    case getType(requestsActions.getRequestsSummary): {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case getType(requestsActions.getRequestsSummarySuccess): {
      return {
        ...state,
        summary: action.payload,
        isLoading: false,
        error: null,
      };
    }

    case getType(requestsActions.getRequestMessages): {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case getType(requestsActions.setRequestMessages): {
      return {
        ...state,
        messages: action.payload.data,
        isLoading: false,
        error: null,
      };
    }

    case getType(requestsActions.getNotifications): {
      return {
        ...state,
        isLoading: true,
        error: null,
        notifications: undefined,
      };
    }

    case getType(requestsActions.setNotifications): {
      return {
        ...state,
        notifications: action.payload.data,
        isLoading: false,
        error: null,
      };
    }

    case getType(requestsActions.getMessageSuccess): {
      return {
        ...state,
        message: action.payload,
      };
    }

    case getType(requestsActions.replyToMessageSuccess): {
      return {
        ...state,
        message: {
          ...state.message,
          isSubmitting: false,
          isReplied: true,
        },
      };
    }
    case getType(requestsActions.replyToMessage): {
      return {
        ...state,
        message: {
          ...state.message,
          isSubmitting: true,
        },
      };
    }

    case getType(requestsActions.editComment): {
      return {
        ...state,
        comment: {
          isCommentEditing: true,
        },
      };
    }

    case getType(requestsActions.editCommentError): {
      return {
        ...state,
        comment: {
          isCommentEditing: false,
          hasEditError: true,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as requestsReducer };
