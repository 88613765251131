import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as requestsAction from 'store/requests/actions';
import * as layoutAction from 'store/layout/actions';
import { ApplicationState } from 'store';
import { Button } from 'components/styled/shared/Button';
import { useParams } from 'react-router';
import { Modal } from 'components/presentational/shared/Modal';
import { useForm } from 'react-hook-form';
import { LabelInline, LabelPlaceholder } from 'components/styled/shared/Label';
import TextAreaField from 'components/presentational/shared/TextAreaField';
import { CommentData } from 'dataaccess/messages/types';
import { AlertSpan } from 'components/styled/shared/AlertSpan';
import readableDate from 'utils/readableDate';

interface CommentProps {
  commentData?: CommentData;
}

interface FormData {
  general_comment: string;
}

export const Comment: React.FC<CommentProps> = ({ commentData }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit = handleSubmit(submitData => {
    dispatch(requestsAction.editComment(id, submitData.general_comment));
  });

  return (
    <>
      {commentData?.commentText ? (
        <>
          <span>{commentData?.commentText}</span>
          <Button onClick={() => setIsCommentModalOpen(true)}>
            Edit comment
          </Button>
        </>
      ) : (
        <>
          <span>There is no comment</span>
          <Button onClick={() => setIsCommentModalOpen(true)}>
            Add comment
          </Button>
        </>
      )}
      <Modal
        title="Add a comment"
        isOpen={isCommentModalOpen}
        onClose={() => setIsCommentModalOpen(false)}
      >
        <div>
          <strong>Last updated:</strong> {readableDate(commentData?.date)}
        </div>
        <form onSubmit={onSubmit}>
          {commentData?.state?.hasEditError && (
            <AlertSpan>Something went wrong, please try again later!</AlertSpan>
          )}
          <LabelPlaceholder>
            <LabelInline width="full">
              <TextAreaField
                name="general_comment"
                register={register}
                value={commentData?.commentText}
              />
            </LabelInline>
          </LabelPlaceholder>
          <Button disabled={commentData?.state?.isCommentEditing}>
            Submit
          </Button>
        </form>
      </Modal>
    </>
  );
};
