import * as React from 'react';
import { Link } from 'react-router-dom';

import { SideNavigationWrapper } from 'components/styled/navigation/SideNavigation';
import { sideNavigationData } from '../../../config/NavigationConfig';

interface SideNavigationProps {
  className: string;
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  className,
}) => {
  return (
    <SideNavigationWrapper className={className}>
      <ul>
        {sideNavigationData.map((item, index) => (
          <li key={index}>
            <Link to={item.url}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </SideNavigationWrapper>
  );
};
