import React, { useEffect } from 'react';
import FormField from '../Field';
import FormFieldRead from '../FieldRead';
import { Decision, DecisionType } from 'types/workflow';
import {
  RequestDetailsData,
  RequestType,
} from '../../../../dataaccess/requests/types';
import FormFieldReadLogos from '../FieldReadLogos';

interface ForwardFormProps {
  data: RequestDetailsData;
  register?: any;
  decision: Decision;
  errors: any;
  control: any;
  setValue: any;
  watch: any;
}

const ForwardForm: React.FC<ForwardFormProps> = ({
  register,
  data,
  errors,
  decision,
  setValue,
  control,
  watch,
}) => {
  const {
    email,
    address,
    company_name,
    name,
    start_date,
    end_date,
    brand_assets,
    position,
    medium_format,
    purpose_of_use,
    telephone,
    territory,
    business,
    contact_name,
    contact_email,
    relationship,
    other_assets,
    context,
    other_info,
    film_name,
  } = data.data;

  const { logos, uploads, artwork } = data;

  const redirectCategories = [
    {
      value: '',
      label: '---- Please select redirect category ----',
      disabled: true,
      selected: true,
    },
    {
      value: 'bbc_history',
      label: 'BBC History',
    },
    {
      value: 'brand_enforcement',
      label: 'Brand Enforcement',
    },
    {
      value: 'commercial_agency',
      label: 'Commercial agency',
    },
    {
      value: 'copyright',
      label: 'Copyright',
    },
    {
      value: 'fair_trading',
      label: 'Fair Trading',
    },
    {
      value: 'i_and_a',
      label: 'I & A',
    },
    {
      value: 'legal',
      label: 'Legal',
    },
    {
      value: 'marketing_team',
      label: 'Marketing Team',
    },
    {
      value: 'motion_gallery',
      label: 'Motion gallery',
    },
    {
      value: 'news_rights',
      label: 'News Rights',
    },
    {
      value: 'news_website_request',
      label: 'News Website Request',
    },
    {
      value: 'picture_publicity',
      label: 'Picture publicity',
    },
    {
      value: 'worldwide',
      label: 'Worldwide',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  const currentCategory = watch('category');

  useEffect(() => {
    setValue('recipient_address', '');
  }, [setValue]);

  return (
    <>
      <FormField
        inputType="hidden"
        fieldName="action_id"
        defaultValue={decision.actionType}
        register={register}
      />

      <FormField
        inputType="hidden"
        fieldName="sender"
        register={register}
        errors={errors}
      />

      {decision.type === DecisionType.REDIRECT && (
        <>
          <FormField
            labelText="Redirect categories:"
            inputType="selector"
            fieldName="category"
            required="Please choose a category"
            options={redirectCategories}
            control={control}
            register={register}
            errors={errors}
          />
          {currentCategory === 'other' && (
            <FormField
              labelText="Detailed reason:"
              inputType="input"
              fieldName="category_other"
              register={register}
              required="Please provide a reason"
              errors={errors}
            />
          )}
        </>
      )}

      <FormField
        labelText="Email address to:"
        inputType="input"
        fieldName="recipient_address"
        required="Please provide the recipient's email address"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Email address from:"
        inputType="input"
        fieldName="sender_address"
        required="Please provide the sender's email address"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="CC:"
        inputType="input"
        fieldName="cc_address"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Subject:"
        inputType="input"
        fieldName="subject"
        register={register}
        required="Please add your subject"
        errors={errors}
      />

      <FormField
        labelText="Message"
        inputType="editor"
        fieldName="message"
        required="Please compose your message"
        register={register}
        errors={errors}
      />

      <h5>Details to be forwarded</h5>
      {decision.type === DecisionType.FORWARD_ARTWORK ? (
        <FormField
          labelText="Artworks:"
          fieldName="uploadedFiles"
          inputType="uploadedFiles"
          uploads={artwork?.uploads}
          register={register}
        />
      ) : (
        <>
          <FormFieldRead labelText="Company name:" value={company_name} />
          <FormFieldRead labelText="Contact name:" value={name} />
          <FormFieldRead labelText="Position:" value={position} />
          <FormFieldRead labelText="Address:" value={address} />
          <FormFieldRead labelText="Telephone:" value={telephone} />
          <FormFieldRead labelText="Email address:" value={email} />
          <FormFieldRead labelText="Nature of business:" value={business} />
          <FormFieldRead
            labelText="Relationship to the BBC:"
            value={relationship}
          />
          <FormFieldRead labelText="BBC contact name:" value={contact_name} />
          <FormFieldRead labelText="BBC contact email:" value={contact_email} />
          {logos && (
            <FormFieldReadLogos
              labelText="BBC logos requested:"
              logos={logos}
            />
          )}
          {brand_assets && (
            <FormFieldRead
              labelText="BBC programme logos:"
              value={brand_assets}
            />
          )}
          {other_assets && (
            <FormFieldRead labelText="Other BBC assets:" value={other_assets} />
          )}
          {data.data.request_type === RequestType.BRANDING ? (
            <>
              <FormFieldRead
                labelText="Purpose of use:"
                value={purpose_of_use}
              />
              <FormFieldRead
                labelText="Where to be used:"
                value={medium_format}
              />
              <FormFieldRead
                labelText="Start date:"
                value=""
                dateValue={start_date}
              />
              <FormFieldRead
                labelText="End date:"
                value=""
                dateValue={end_date}
              />
            </>
          ) : (
            <FormFieldRead
              labelText="Title of the film/programme:"
              value={film_name}
            />
          )}
          <FormFieldRead labelText="Territory:" value={territory} />
          <FormFieldRead labelText="Context:" value={context} />
          <FormField
            labelText="Uploaded file(s):"
            fieldName="uploadedFiles"
            inputType="uploadedFiles"
            uploads={uploads}
            register={register}
          />
          <FormFieldRead labelText="Other info:" value={other_info} />
        </>
      )}
    </>
  );
};

export default ForwardForm;
