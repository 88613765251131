import { createAction } from 'typesafe-actions';
import {
  AcceptLicenseData,
  AcceptLicenseResponse,
} from '../../dataaccess/requests/types';
import { FileSubmissionRequest } from '../../dataaccess/upload/types';

export const submitRequest = createAction('@@submit/SUBMIT_REQUEST', data => ({
  data,
}))();

export const submitRequestSuccess = createAction(
  '@@submit/SUBMIT_REQUEST_SUCCESS'
)();

export const getError = createAction('@@submit/GET_ERROR', error => error)();

export const acceptLicense = createAction(
  '@@submit/ACCEPT_LICENSE',
  (data: AcceptLicenseData) => ({
    data,
  })
)();

export const submitFiles = createAction(
  '@@submit/SUBMIT_FILES',
  (data: FileSubmissionRequest) => ({
    data,
  })
)();

export const setLogoGuidelines = createAction(
  '@@submit/SET_LOGO_GUIDELINES',
  (data: AcceptLicenseResponse) => ({
    data,
  })
)();
