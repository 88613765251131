import { Observable, of } from 'rxjs';
import { Epic, combineEpics } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import * as submitActions from './actions';
import { RootState } from 'store';
import { File } from 'react-filepond';
import { AcceptLicenseData, AcceptLicenseResponse, FileData } from '../../dataaccess/requests/types';
import { FileSubmissionRequest } from '../../dataaccess/upload/types';
import { SubmitActions } from './index';

const submitRequestEpic: Epic<SubmitActions, SubmitActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(submitActions.submitRequest)),
    map(action => {
      const data = action.payload.data;
      if (data.files) {
        data.uploadedFiles = data.files.map((file: File) => file.serverId);
      }
      delete data.files;
      return data;
    }),
    switchMap(
      (data): Observable<SubmitActions> => {
        return dataRepositories.requests.submitRequest(data).pipe(
          map(data => {
            return submitActions.submitRequestSuccess();
          }),
          catchError(error => {
            return of(submitActions.getError(error.response.message));
          })
        );
      }
    )
  );

const submitFilesEpic: Epic<SubmitActions, SubmitActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(submitActions.submitFiles)),
    map(action => action.payload.data),
    switchMap(
      (data: FileSubmissionRequest): Observable<SubmitActions> => {
        return dataRepositories.upload.submitFiles(data).pipe(
          map(data => {
            return submitActions.submitRequestSuccess();
          }),
          catchError(error => {
            return of(submitActions.getError(error));
          })
        );
      }
    )
  );

const acceptLicenseEpic: Epic<SubmitActions, SubmitActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(submitActions.acceptLicense)),
    map(action => {
      return action.payload.data;
    }),
    switchMap(
      (data: AcceptLicenseData): Observable<SubmitActions> => {
        return dataRepositories.requests.acceptLicense(data).pipe(
          map((data: AcceptLicenseResponse) => {
            return submitActions.setLogoGuidelines(data);
          }),
          catchError(error => {
            return of(submitActions.getError({ ...error, keepData: true }));
          })
        );
      }
    )
  );

export const submitEpics = combineEpics(submitRequestEpic, acceptLicenseEpic, submitFilesEpic);
