import React from 'react';
import SectionRow from 'components/styled/workflow/SectionRow';
import SectionTitle from 'components/styled/workflow/SectionTitle';
import SectionContent from 'components/styled/workflow/SectionContent';

interface DumbSectionProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
  warningColor?: boolean
}

const DumbFormSection: React.FC<DumbSectionProps> = ({
  title,
  children,
  fullWidth,
  subtitle,
  warningColor
}) => {
  return (
    <SectionRow fullWidth={fullWidth}>
      {title && (
        <SectionTitle fullWidth={fullWidth}>
          <p>{title}</p>
          <br />
          {subtitle && (
            <>
              <span style={{ color: warningColor ? 'red' :  'black'}}>{subtitle}</span>
              <br />
              <br />
            </>
          )}
        </SectionTitle>
      )}
      <SectionContent>{children}</SectionContent>
    </SectionRow>
  );
};

export default DumbFormSection;
