import styled from 'styled-components';

export const NotificationDropDown = styled.div<{ show: boolean }>`
  position: absolute;
  top: 20px;
  left: -300px;
  width: 300px;
  background: ${({ theme }) => theme.colors.defaultBackground};
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.175);
  border: 1px solid 1px solid ${({ theme }) => theme.colors.border};
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 1000;
`;

export const NotificationDropDownHeader = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.defaultText};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  margin: 0;
  padding: 8px;
`;

export const NotificationDropDownContent = styled.div`
  color: ${({ theme }) => theme.colors.defaultText};
  margin: 0;
  padding: 8px;
  ul {
    li {
      padding: 6px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
      background: #f4f4f4;
      &.news {
        background: #f9e1e2;
      }
      a {
        font-size: ${({ theme }) => theme.font.size.small};
        color: ${({ theme }) => theme.colors.link};
        .date {
          color: ${({ theme }) => theme.colors.defaultText};
        }
      }
    }
  }
`;
