import * as React from 'react';
import { InputField } from 'components/styled/shared/InputField';
import FormLabel from 'components/presentational/form/FormLabel';
import { DropDown } from 'components/presentational/form/DropDown';
import {
  declineDropDownItems,
  approveCatDropDownItems,
  redirectCatDropDownItems,
} from 'config/EditFormDropDownItems';
import { TextInputField } from 'components/presentational/shared/TextInputField';
import { RequestData, RequestStatuses } from '../../../dataaccess/requests/types';

interface DropDownItems {
  [key: string]: string;
}

interface StatusFieldsProps {
  statusValue: number;
  errors?: any;
  register: any;
  statusesDropDownItems: DropDownItems;
  decline_reason: string;
  approve_cat: string;
  redirect_cat: string;
  data: RequestData;
}

export const StatusFields: React.FC<StatusFieldsProps> = ({
  statusValue,
  errors,
  register,
  statusesDropDownItems,
  approve_cat,
  decline_reason,
  redirect_cat,
  data,
}) => {

  return (
    <>
      <FormLabel text="Status">
        <InputField>
          <DropDown
            name="status"
            register={register}
            errors={errors}
            required="Please select a status"
            items={statusesDropDownItems}
            value={data.request_status}
          />
        </InputField>
      </FormLabel>

      <FormLabel
        text="Please select one of the following reasons"
        hidden={Number(statusValue) !== RequestStatuses.Declined}
      >
        <InputField>
          <DropDown
            name="decline_reason"
            register={register}
            errors={errors}
            required={Number(statusValue) === RequestStatuses.Declined}
            items={declineDropDownItems}
            value={data.decline_reason}
          />
        </InputField>
      </FormLabel>

      <FormLabel
        text="Please select one of the following reasons"
        hidden={Number(statusValue) !== RequestStatuses.Approved}
      >
        <InputField>
          <DropDown
            name="approve_cat"
            register={register}
            errors={errors}
            required={Number(statusValue) === RequestStatuses.Approved}
            items={approveCatDropDownItems}
            value={data.approve_cat}
          />
        </InputField>
      </FormLabel>

      <FormLabel
        text="Please select one of the following reasons"
        hidden={Number(statusValue) !== RequestStatuses.Redirected}
      >
        <InputField>
          <DropDown
            name="redirect_cat"
            register={register}
            errors={errors}
            required={Number(statusValue) === RequestStatuses.Redirected}
            items={redirectCatDropDownItems}
            value={data.redirect_cat}
          />
        </InputField>
      </FormLabel>

      <FormLabel text="Please specify:" hidden={decline_reason !== 'Other'}>
        <TextInputField
          name="decline_other"
          required={decline_reason === 'Other'}
          register={register}
          errors={errors}
          value={data.decline_other}
        />
      </FormLabel>

      <FormLabel text="Please specify:" hidden={approve_cat !== 'Other'}>
        <TextInputField
          name="approve_other"
          required={approve_cat === 'Other'}
          register={register}
          errors={errors}
          value={data.approve_other}
        />
      </FormLabel>

      <FormLabel text="Please specify:" hidden={redirect_cat !== 'Other'}>
        <TextInputField
          name="redirect_other"
          required={redirect_cat === 'Other'}
          register={register}
          errors={errors}
          value={data.redirect_other}
        />
      </FormLabel>
    </>
  );
};
