import styled from 'styled-components';

export const SubmitButton = styled.input`
  color: ${({ theme }) => theme.colors.whiteText};
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.size.default};
  text-transform: uppercase;
  padding: 0.15rem 0.65rem;
  border-radius: 0.25rem;
  line-height: ${({ theme }) => theme.font.lineHeight.button};
  
  margin-right: 1rem;

  &:disabled {
    opacity: 0.3;
  }
`;
