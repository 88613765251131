import styled from 'styled-components';

export const SectionRow = styled.div<{ fullWidth?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.fullWidth ? 'column' : 'row')};
  border-top: 1px solid black;
  padding: 15px 0;
`;

export default SectionRow;
