import React from 'react';
import Field from 'components/styled/workflow/Field';
import FieldLabelText from 'components/styled/workflow/FieldLabelText';
import FieldInput from 'components/styled/workflow/FieldInput';
import readableDate from 'utils/readableDate';

interface FormFieldReadProps {
  value?: string;
  dateValue?: string; // workaround for date fields
  labelText?: string;
  link?: boolean;
  url?: string;
  logos?: any[]
}

const FormFieldRead: React.FC<FormFieldReadProps> = ({
  value,
  labelText,
  dateValue,
  link,
  url,
  logos,
}) => (
  <Field>
    <FieldLabelText>{labelText}</FieldLabelText>
    <FieldInput>
      {link ? (
        <a target="_blank" href={url ?? value}>
          {value}
        </a>
      ) : dateValue ? (
        readableDate(dateValue)
      ) : logos ? logos.map(l => <div>{l.name}</div>) : (
        value
      )}
    </FieldInput>
  </Field>
);

export default FormFieldRead;
