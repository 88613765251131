import * as React from 'react';
import FormLabel from 'components/presentational/form/FormLabel';
import TextAreaField from 'components/presentational/shared/TextAreaField';
import { TextInputField } from 'components/presentational/shared/TextInputField';

interface CommonFieldsBodyProps {
  data: any;
  register: any;
  errors: any;
}

export const CommonFieldsBody: React.FC<CommonFieldsBodyProps> = ({
  data,
  register,
  errors,
}) => {
  return (
    <>
      <FormLabel text="Request reference:">
        <TextInputField
          name="reqref"
          required="Please enter a reference name"
          maxLength={8}
          register={register}
          errors={errors}
          value={data.reqref}
        />
      </FormLabel>
      <FormLabel text="Company name:">
        <TextInputField
          name="company_name"
          required="Please enter a company name"
          register={register}
          errors={errors}
          value={data.company_name}
        />
      </FormLabel>
      <FormLabel text="Contact name:">
        <TextInputField
          name="name"
          required="Please enter a name"
          register={register}
          errors={errors}
          value={data.name}
        />
      </FormLabel>
      <FormLabel text="Position:">
        <TextInputField
          name="position"
          register={register}
          value={data.position}
        />
      </FormLabel>
      <FormLabel text="Address:">
        <TextAreaField
          name="address"
          required="Please enter a name"
          register={register}
          errors={errors}
          value={data.address}
        />
      </FormLabel>
      <FormLabel text="Telephone:">
        <TextInputField
          name="telephone"
          register={register}
          value={data.telephone}
        />
      </FormLabel>
      <FormLabel text="Email address:">
        <TextInputField
          name="email"
          required="Please enter a valid email address"
          register={register}
          errors={errors}
          value={data.email}
        />
      </FormLabel>
      <FormLabel text="Nature of business:">
        <TextInputField
          name="business"
          register={register}
          value={data.business}
        />
      </FormLabel>
      <FormLabel text="Relationship to the BBC:">
        <TextInputField
          name="relationship"
          register={register}
          value={data.relationship}
        />
      </FormLabel>
      <FormLabel text="BBC Contact name:">
        <TextInputField
          name="contact_name"
          register={register}
          value={data.contact_name}
        />
      </FormLabel>
      <FormLabel text="BBC Contact email:">
        <TextInputField
          name="contact_email"
          register={register}
          value={data.contact_email}
        />
      </FormLabel>
    </>
  );
};
