import styled from 'styled-components';

export const ErrorField = styled.div`
  display: none;
  color: ${({ theme }) => theme.colors.alert};
  vertical-align: top;
  padding: 6px 0;
`;

export const ErrorBlock = styled.div`
  color: ${({ theme }) => theme.colors.alert};
  padding: 6px 0;
  width: 100%;
`;
