import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBackground};
  color: ${({ theme }) => theme.colors.lightText};
  height: 110px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.screen.size.small}px) {
    height: auto;
  }
`;
