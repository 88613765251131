import { Observable, of } from 'rxjs';
import { Epic, combineEpics } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import {
  filter,
  switchMap,
  catchError,
  map,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import * as requestsActions from './actions';
import * as userActions from '../user/actions';
import { RootState } from 'store';
import {
  RequestListData,
  SearchResults,
  SummaryResponse,
} from 'dataaccess/requests/types';
import { MessageData, NotificationData } from '../../dataaccess/messages/types';
import { RequestsActions } from './index';

const getRequestsEpic: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.requestListAction)),
    switchMap(
      (action): Observable<RequestsActions> => {
        return dataRepositories.requests
          .getList(
            action.payload.status,
            action.payload.offset,
            action.payload.amount
          )
          .pipe(
            map((data: RequestListData) => {
              return requestsActions.getListSuccess(data);
            }),
            catchError(error => {
              if (error.status === 401 || error.status === 403) {
                sessionStorage.removeItem('session_id');
                return of(userActions.logout());
              }
              return of(requestsActions.getError(error));
            })
          );
      }
    )
  );

const getMetadata: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.requestsMetadata)),
    switchMap(
      (): Observable<RequestsActions> => {
        return dataRepositories.requests.getMetadata().pipe(
          // TODO: correct data type
          map((data: any) => {
            return requestsActions.getMetadataSuccess(data);
          }),
          catchError(error => {
            if (error.status === 401 || error.status === 403) {
              sessionStorage.removeItem('session_id');
              return of(userActions.logout());
            }
            return of(requestsActions.getError(error));
          })
        );
      }
    )
  );

const getAdminMetadata: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.requestsAdminMetadata)),
    switchMap(
      (): Observable<RequestsActions> => {
        return dataRepositories.requests.getAdminMetadata().pipe(
          // TODO: correct data type
          map((data: any) => {
            return requestsActions.getMetadataSuccess(data);
          }),
          catchError(error => {
            if (error.status === 401 || error.status === 403) {
              sessionStorage.removeItem('session_id');
              return of(userActions.logout());
            }
            return of(requestsActions.getError(error));
          })
        );
      }
    )
  );

const getRequestDetails: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.requestDetailsAction)),
    switchMap(
      (action): Observable<RequestsActions> => {
        return dataRepositories.requests
          .getRequestDetails(action.payload.id)
          .pipe(
            map((data: any) => {
              return requestsActions.getRequestDetailsSuccess(data);
            }),
            catchError(error => {
              if (error.status === 401 || error.status === 403) {
                sessionStorage.removeItem('session_id');
                return of(userActions.logout());
              }
              return of(requestsActions.getError(error));
            })
          );
      }
    )
  );

const getRequestDetailsWithSignature: Epic<
  RequestsActions,
  RequestsActions,
  RootState
> = (action$, _$, { dataRepositories }) =>
  action$.pipe(
    filter(isActionOf(requestsActions.requestDetailsWithSignatureAction)),
    switchMap(
      (action): Observable<RequestsActions> => {
        return dataRepositories.requests
          .getRequestDetailsWithSignature(
            action.payload.id,
            action.payload.expires,
            action.payload.signature
          )
          .pipe(
            map((data: any) => {
              return requestsActions.getRequestDetailsSuccess(data);
            }),
            catchError(error => {
              return of(requestsActions.getError(error));
            })
          );
      }
    )
  );

const getSearchResult: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.getSearchQuery)),
    debounceTime(200),
    filter(action => action.payload.query.length > 0),
    distinctUntilChanged(),
    switchMap(
      (action): Observable<RequestsActions> =>
        dataRepositories.requests
          .getSearch(action.payload.query, action.payload.status)
          .pipe(
            map((searchResults: SearchResults) => {
              return requestsActions.getSearchSuccess(searchResults);
            }),
            catchError(error => {
              if (error.status === 401 || error.status === 403) {
                sessionStorage.removeItem('session_id');
                return of(userActions.logout());
              }
              return of(requestsActions.getError(error));
            })
          )
    )
  );

const editRequestEpic: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.editRequest)),
    map(action => {
      return action.payload.data;
    }),
    switchMap(
      (data): Observable<RequestsActions> => {
        return dataRepositories.requests.editRequest(data).pipe(
          map(() => {
            return requestsActions.requestDetailsAction(data.request_id);
          }),
          catchError(error => {
            if (error.status === 401 || error.status === 403) {
              sessionStorage.removeItem('session_id');
              return of(userActions.logout());
            }
            return of(requestsActions.getError(error));
          })
        );
      }
    )
  );

const getNewRequestsEpic: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.getNewRequests)),
    switchMap(
      (): Observable<RequestsActions> => {
        return dataRepositories.requests.getNewRequests().pipe(
          map((data: any) => {
            return requestsActions.getNewRequestsSuccess(data);
          }),
          catchError(error => {
            if (error.status === 401 || error.status === 403) {
              sessionStorage.removeItem('session_id');
              return of(userActions.logout());
            }
            return of(requestsActions.getError(error));
          })
        );
      }
    )
  );

const getMessageEpic: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.getMessage)),
    switchMap(
      (action): Observable<RequestsActions> => {
        const { id, expires, signature } = action.payload;
        return dataRepositories.messages
          .getReplyMessage(id, expires, signature)
          .pipe(
            map((data: any) => {
              return requestsActions.getMessageSuccess(data);
            }),
            catchError(error => {
              return of(requestsActions.getError(error));
            })
          );
      }
    )
  );

const replyToMessageEpic: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.replyToMessage)),
    switchMap(
      (action): Observable<RequestsActions> => {
        const { id, message, expires, signature } = action.payload;
        return dataRepositories.messages.replyToMessage(id, message, expires, signature).pipe(
          map(() => {
            return requestsActions.replyToMessageSuccess();
          }),
          catchError(error => {
            return of(requestsActions.getError(error));
          })
        );
      }
    )
  );

const getRequestsSummaryEpic: Epic<
  RequestsActions,
  RequestsActions,
  RootState
> = (action$, _$, { dataRepositories }) =>
  action$.pipe(
    filter(isActionOf(requestsActions.getRequestsSummary)),
    switchMap(
      (): Observable<RequestsActions> => {
        return dataRepositories.requests.getRequestsSummary().pipe(
          map((data: SummaryResponse) => {
            return requestsActions.getRequestsSummarySuccess(data);
          }),
          catchError(error => {
            if (error.status === 401 || error.status === 403) {
              sessionStorage.removeItem('session_id');
              return of(userActions.logout());
            }
            return of(requestsActions.getError(error));
          })
        );
      }
    )
  );

const getRequestMessagesEpic: Epic<
  RequestsActions,
  RequestsActions,
  RootState
> = (action$, _$, { dataRepositories }) =>
  action$.pipe(
    filter(isActionOf(requestsActions.getRequestMessages)),
    switchMap(
      (action): Observable<RequestsActions> => {
        return dataRepositories.messages
          .getMessages(action.payload.requestId)
          .pipe(
            map((data: MessageData[]) => {
              return requestsActions.setRequestMessages(data);
            }),
            catchError(error => {
              if (error.status === 401 || error.status === 403) {
                sessionStorage.removeItem('session_id');
                return of(userActions.logout());
              }
              return of(requestsActions.getError(error));
            })
          );
      }
    )
  );

const getNotificationsEpic: Epic<
  RequestsActions,
  RequestsActions,
  RootState
  > = (action$, _$, { dataRepositories }) =>
  action$.pipe(
    filter(isActionOf(requestsActions.getNotifications)),
    switchMap(
      (_): Observable<RequestsActions> => {
        return dataRepositories.messages
          .getNotifications()
          .pipe(
            map((data: NotificationData[]) => {
              return requestsActions.setNotifications(data);
            }),
            catchError(error => {
              return of(requestsActions.getNotificationsError(error));
            })
          );
      }
    )
  );

const editCommentEpic: Epic<RequestsActions, RequestsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(requestsActions.editComment)),
    switchMap(
      (action): Observable<RequestsActions> => {
        console.log('action: ', action);
        const { id, comment } = action.payload;
        return dataRepositories.messages.editComment(id, comment).pipe(
          map(() => {
            return requestsActions.requestDetailsAction(id);
          }),
          catchError(error => {
            if (error.status === 401 || error.status === 403) {
              sessionStorage.removeItem('session_id');
              return of(userActions.logout());
            }
            return of(requestsActions.editCommentError(error));
          })
        );
      }
    )
  );

export const requestsEpics = combineEpics(
  getRequestsEpic,
  getMetadata,
  getAdminMetadata,
  getSearchResult,
  getRequestDetails,
  getRequestDetailsWithSignature,
  getNewRequestsEpic,
  editRequestEpic,
  getRequestsSummaryEpic,
  getRequestMessagesEpic,
  getNotificationsEpic,
  editCommentEpic,
  getMessageEpic,
  replyToMessageEpic
);
