import React, { useEffect, useState } from 'react';
import { Button } from 'components/styled/shared/Button';
import { Modal } from 'components/presentational/shared/Modal';
import {
  HeaderRow,
  LogTable,
  ReplyRow,
  MessageBlock,
} from 'components/styled/shared/CustomTable';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import readableDate from '../../../utils/readableDate';
import * as requestsActions from '../../../store/requests/actions';
import { sendEmailDecision } from 'types/workflow';
import AdminResponseForm from '../../container/AdminResponseForm';
import { LoadingView } from '../shared/LoadingView';

export const EmailLog: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { details, messages, isDetailsLoading, isLoading } = useSelector(
    (state: ApplicationState) => state.requests
  );
  const directEmailMessages = messages.filter(message => !message.is_forward);

  const [isNewMessageModalOpen, setNewMessageModalOpen] = useState(false);

  const loadRequestMessages = () => {
    dispatch(requestsActions.getRequestMessages(id));
  };

  const { isActionProcessing, isPutActionSuccess } = useSelector(
    (state: ApplicationState) => state.actions
  );

  const loadRequestDetails = () => {
    dispatch(requestsActions.requestDetailsAction(id));
  };

  // load request data
  useEffect(() => {
    loadRequestMessages();
    loadRequestDetails();
  }, [dispatch, id]);

  // reload messages after popup is closed
  useEffect(() => {
    if (!isNewMessageModalOpen) {
      loadRequestMessages();
    }
  }, [isNewMessageModalOpen]);

  const onSubmitted = () => {
    setNewMessageModalOpen(false);
  };

  if (isDetailsLoading || (isLoading && !messages) || !details) {
    return <LoadingView />;
  }

  return (
    <>
      <h3>Email log</h3>
      <h5>Direct messages and replies</h5>
      <Button uppercase={true}>
        <Link to={`/admin/details/${id}`}>Back to details</Link>
      </Button>
      <LogTable>
        <tbody>
          <HeaderRow>
            <td>Date</td>
            <td>From</td>
            <td>Message/Reply</td>
          </HeaderRow>
          {directEmailMessages.map((message, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>{readableDate(message.email_date)}</td>

                <td>{message.email_from ?? 'BBC Brand Guardianship'}</td>

                <td>
                  <p>
                    <strong>{message.email_subject}</strong>
                  </p>
                  <div />
                  <MessageBlock
                    dangerouslySetInnerHTML={{
                      __html: message.message || '',
                    }}
                  />
                </td>
              </tr>
              {message.message_reply && (
                <ReplyRow>
                  <td>{readableDate(message.reply_date)}</td>

                  <td>{details.data.name}</td>

                  <td>
                    <p>
                      <strong>RE: {message.email_subject}</strong>
                    </p>
                    <div />
                    <MessageBlock
                      dangerouslySetInnerHTML={{
                        __html: message.message_reply || '',
                      }}
                    />
                  </td>
                </ReplyRow>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </LogTable>
      <Button uppercase={true} onClick={() => setNewMessageModalOpen(true)}>
        New message
      </Button>

      <Modal
        title="Send message"
        isOpen={isNewMessageModalOpen}
        onClose={() => setNewMessageModalOpen(false)}
      >
        <AdminResponseForm
          decision={sendEmailDecision}
          onSubmitted={onSubmitted}
          requestDetails={details}
        />
      </Modal>
    </>
  );
};
