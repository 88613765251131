import styled from 'styled-components';
import { SecondaryButton } from './SecondaryButton';

export const ActionButton = styled(SecondaryButton)`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  font-weight: ${({ theme }) => theme.font.weight.default};
  font-size: ${({ theme }) => theme.font.size.default};
  margin-right: 0.75rem;
  margin-bottom: 0.4rem;
`
