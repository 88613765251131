import * as React from 'react';
import LoadingSpinner from './LoadingSpinner';
import { LoadingViewWrapper } from 'components/styled/loading/LoadingViewWrapper';

interface LoadingViewProps {
  isVisible?: boolean;
}

export const LoadingView: React.FC<LoadingViewProps> = ({isVisible = true}) => {
  return (
    <LoadingViewWrapper style={{display: isVisible ? 'block' : 'none'}}>
      <LoadingSpinner />
    </LoadingViewWrapper>
  );
};
