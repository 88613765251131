import { SubmitState, submitReducer, SubmitActions, submitEpics } from './submit';
import { ActionsState, actionsReducer, ActionsActions, actionsEpics } from './actions';
import { LayoutState, layoutReducer, LayoutActions } from './layout';
import {
  RequestsState,
  requestsReducer,
  RequestsActions,
  requestsEpics,
} from './requests';
import { UserState, userReducer, UserActions, userEpics } from './user';
import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { History } from 'history';
import {
  connectRouter,
  RouterState,
  RouterAction,
} from 'connected-react-router';

/*
 * Top-level state type
 */
export interface ApplicationState {
  readonly submit: SubmitState;
  readonly actions: ActionsState;
  readonly layout: LayoutState;
  readonly user: UserState;
  readonly requests: RequestsState;
  readonly router: RouterState;
}

/*
 * Root types
 */
// prettier-ignore
export type RootState = RouterState
  & SubmitState
  & ActionsState
  & LayoutState
  & RequestsState
  & UserState
;

// prettier-ignore
export type RootAction = RouterAction
  & SubmitActions
  & ActionsActions
  & LayoutActions
  & UserActions
  & RequestsActions
;

/*
 * Combine all reducers to create a root
 */
export const createRootReducer = (history: History) =>
  combineReducers({
  submit: submitReducer,
  actions: actionsReducer,
  layout: layoutReducer,
  requests: requestsReducer,
  user: userReducer,
  router: connectRouter(history),
});

/*
 * Combine all epics to create a root
 */
// prettier-ignore
export const createRootEpics = () => combineEpics(
  submitEpics,
  actionsEpics,
  requestsEpics,
  userEpics,
);
