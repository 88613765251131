import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as requestsAction from 'store/requests/actions';
import { ApplicationState } from 'store';
import { PageTitle } from '../presentational/shared/PageTitle';
import {
  InlineTableWrapper,
  InlineTable,
  InlineTableHeaderRow,
  InlineTableTitleCell,
  InlineTableDataCell,
  InlineTableRow,
} from 'components/styled/shared/InlineTable';
import { LoadingView } from '../presentational/shared/LoadingView';

export const Summary: React.FC = () => {
  const dispatch = useDispatch();
  const { summary, error, isLoading } = useSelector((state: ApplicationState) => state.requests);

  useEffect(() => {
    dispatch(requestsAction.getRequestsSummary());
  }, [dispatch]);

  if (isLoading || !summary) {
    return <LoadingView />;
  }

  return (
    <>
      <PageTitle title="Summary" />
      <InlineTableWrapper width={33}>
        <InlineTableHeaderRow>Summary</InlineTableHeaderRow>
        <InlineTable>
          <tbody>
            {summary.total_requests_by_status.map((item) => (
              <InlineTableRow>
                <InlineTableTitleCell>{item.name}</InlineTableTitleCell>
                <InlineTableDataCell>
                  {item.count}
                </InlineTableDataCell>
              </InlineTableRow>
            ))}
          </tbody>
        </InlineTable>
      </InlineTableWrapper>

      <InlineTableWrapper width={33}>
        <InlineTableHeaderRow>Approved by category</InlineTableHeaderRow>
        <InlineTable>
          <tbody>
            {summary.approved_by_category.map((item) => (
              <InlineTableRow>
                <InlineTableTitleCell>{item.name}</InlineTableTitleCell>
                <InlineTableDataCell>
                  {item.count}
                </InlineTableDataCell>
              </InlineTableRow>
            ))}
          </tbody>
        </InlineTable>
      </InlineTableWrapper>

      <InlineTableWrapper width={33}>
        <InlineTableHeaderRow>Declined by category</InlineTableHeaderRow>
        <InlineTable>
          <tbody>
            {summary.declined_by_category.map((item) => (
              <InlineTableRow>
                <InlineTableTitleCell>{item.name}</InlineTableTitleCell>
                <InlineTableDataCell>
                  {item.count}
                </InlineTableDataCell>
              </InlineTableRow>
            ))}
          </tbody>
        </InlineTable>
      </InlineTableWrapper>
    </>
  );
};
