import { map } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { Observable } from 'rxjs';
import { AllActionRequest } from './types';
import { Endpoint } from 'dataaccess/endpoints';
import { createRequest } from 'dataaccess/request/createRequest';

const putAction = (requestData: AllActionRequest): Observable<void> =>
  createRequest({
    endpoint: Endpoint.putAction,
    body: requestData,
  }).pipe(
    map((response: AjaxResponse) => response.response)
  );

export const actionsRepository = { putAction };
