import styled from 'styled-components';

export const TopNavigationWrapper = styled.div`
  width: 100%;
  height: 42px;
  border-top: 1px solid ${({ theme }) => theme.colors.headerBorder};
  padding-left: 14.5%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.screen.size.small}px) {
    padding-left: 0;
    height: auto;
  }
  ul {
    height: 100%;
    li {
      display: inline-block;
      height: 100%;
      border-left: 1px solid ${({ theme }) => theme.colors.headerBorder};
      &:last-child {
        border-right: 1px solid ${({ theme }) => theme.colors.headerBorder};
      }
      a {
        color: ${({ theme }) => theme.colors.whiteText};
        padding: 8px 22px;
        height: 100%;
        &.active {
          border-bottom: 3px solid ${({ theme }) => theme.colors.whiteText};
        }
      }
    }
  }
`;
