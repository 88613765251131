import React from 'react';

import { DetailsParagraph } from 'components/styled/shared/DetailsParagraph';
import fillTemplate from '../../../utils/fillTemplate';
import readableDate from 'utils/readableDate';

interface AdminPageHeaderProps {
  contact_name?: string;
  title?: string;
  subtitle?: string;
  company_name?: string;
  created_at?: string;
}

const AdminPageHeader: React.FC<AdminPageHeaderProps> = ({
  title,
  contact_name,
  subtitle,
  company_name,
  created_at,
}) => {
  return (
    <>
      <h3>{title}</h3>
      <DetailsParagraph>
        <h5>
          {fillTemplate(subtitle, {
            COMPANY_NAME: company_name || 'unknown',
            CONTACT_NAME: contact_name || 'unknown',
            DATE: (created_at && readableDate(created_at)) || '',
          })}
        </h5>
      </DetailsParagraph>
    </>
  );
};

export default AdminPageHeader;
