import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';

import { getDecisions } from 'types/workflow';

import { ApplicationState } from '../../store';
import * as actionsActions from 'store/actions';
import * as requestsAction from 'store/requests/actions';

import AdminResponseForm from 'components/container/AdminResponseForm';
import { LoadingView } from 'components/presentational/shared/LoadingView';
import AdminPageHeader from 'components/presentational/workflow/AdminPageHeader';
import { Button } from 'components/styled/shared/Button';

const AdminResponsePage: React.FC = () => {
  const { id, type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { details, isDetailsLoading } = useSelector(
    (state: ApplicationState) => state.requests
  );

  const { isPutActionSuccess } = useSelector(
    (state: ApplicationState) => state.actions
  );

  useEffect(() => {
    if (!details) dispatch(requestsAction.requestDetailsAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isPutActionSuccess) {
      dispatch(actionsActions.resetAction());
    }
  }, [dispatch, isPutActionSuccess]);

  if (isDetailsLoading) {
    return <LoadingView />;
  }

  if (isPutActionSuccess) {
    history.goBack();
    return <></>;
  }

  const decision = getDecisions().find(d => d.type === type);

  const { title, subtitle } = decision!;
  const { name, company_name, created_at, branding_request_id } = details!.data;

  return (
    <>
      <AdminPageHeader
        title={title}
        subtitle={subtitle}
        contact_name={name}
        company_name={company_name}
        created_at={created_at}
      />

      <Button uppercase={true}>
        <Link to={`/admin/details/${branding_request_id}`}>
          Back to details
        </Link>
      </Button>

      <AdminResponseForm decision={decision!} requestDetails={details!} />
    </>
  );
};

export default AdminResponsePage;
