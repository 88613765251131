import React from 'react';

import { Decision, DecisionType } from 'types/workflow';

import FormField from '../Field';
import FormFieldRead from '../FieldRead';
import { RequestDetailsData } from '../../../../dataaccess/requests/types';
import { getFrontEndUrlForPath } from '../../../../utils/workflowUtils';
import FormFieldReadLogos from '../FieldReadLogos';

interface NoTMLFormProps {
  data: RequestDetailsData;
  decision: Decision;
  register?: any;
  errors: any;
  control: any;
  watch: any;
}

const SendLinkForm: React.FC<NoTMLFormProps> = ({
  data,
  register,
  decision,
  errors,
  control,
  watch,
}) => {
  const { answerString } = decision;
  const { logos } = data;
  const { brand_assets, branding_request_id } = data.data;

  const approvalCategories = [
    {
      value: '',
      label: '---- Please select approval category ----',
      disabled: true,
      selected: true,
    },
    {
      value: 'logo_approved',
      label: 'Logo approved',
    },
    {
      value: 'text_approved',
      label: 'Text or copyright word approved',
    },
    {
      value: 'hyperlink_approved',
      label: 'Hyperlink approved',
    },
    {
      value: 'bbc_identity_approved',
      label: 'BBC identity approved',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  const currentCategory = watch('category');

  return (
    <>
      {decision.link && (
        <FormField
          inputType="hidden"
          fieldName="url"
          defaultValue={getFrontEndUrlForPath(
            decision.link,
            branding_request_id
          )}
          register={register}
        />
      )}

      <FormField
        inputType="hidden"
        fieldName="action_id"
        defaultValue={decision.actionType}
        register={register}
      />

      {decision.type === DecisionType.APPROVE && (
        <>
          <FormField
            labelText="Approval categories:"
            inputType="selector"
            fieldName="category"
            register={register}
            errors={errors}
            options={approvalCategories}
            control={control}
            required="Please choose an option"
          />
          {currentCategory === 'other' && (
            <FormField
              labelText="Detailed reason:"
              inputType="input"
              fieldName="category_other"
              register={register}
              required="Please provide a reason"
              errors={errors}
            />
          )}
        </>
      )}

      <FormField
        labelText="Email to:"
        inputType="input"
        fieldName="recipient"
        required="Please provide the recipient name"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Email address:"
        inputType="input"
        fieldName="recipient_address"
        required="Please provide the recipient email address"
        register={register}
        errors={errors}
      />

      {decision.subject && (
        <FormField
          labelText="Subject:"
          inputType="input"
          fieldName="subject"
          defaultValue={decision.subject}
          register={register}
          required="Please add your subject"
          errors={errors}
        />
      )}

      <FormField
        labelText="Message"
        inputType="editor"
        fieldName="message"
        required="Please compose your message"
        register={register}
        errors={errors}
        defaultValue={answerString}
      />

      {(decision.type === DecisionType.APPROVE ||
        decision.type === DecisionType.NO_VISUAL) && (
        <>
          <>
            {logos && (
              <FormFieldReadLogos
                labelText="BBC logos requested:"
                logos={logos}
              />
            )}

            {brand_assets && (
              <FormFieldRead
                labelText="BBC programme logos:"
                value={brand_assets}
              />
            )}
          </>

          <FormField
            labelText="Final artwork upload:"
            inputType="textarea"
            fieldName="message2"
            textAreaRows={4}
            register={register}
            errors={errors}
          />
        </>
      )}

      <FormField
        labelText="Email from:"
        inputType="input"
        fieldName="sender"
        required="Please provide your name"
        register={register}
        errors={errors}
      />
    </>
  );
};

export default SendLinkForm;
