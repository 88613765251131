import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import * as userActions from 'store/user/actions';

import { ErrorMessage, useForm } from 'react-hook-form';
import { Redirect } from 'react-router';
import { Button } from 'components/styled/shared/Button';
import {
  LoginFormWrapper,
  LoginFormErrorField,
  ButtonWrapper,
  VerifyForm,
} from 'components/styled/login/LoginFormWrapper';
import { ErrorField } from '../../styled/shared/ErrorField';

type FormData = {
  previousPassword: string;
  newPassword: string;
  newPasswordAgain: string;
  accessToken: string;
};

export const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch } = useForm<FormData, any>();
  const userState = useSelector((state: ApplicationState) => state.user);
  const { verification } = userState;
  const password = useRef({});
  password.current = watch('newPassword', '');

  if (!verification || !verification.challengeName || !verification.session) {
    return <Redirect to="/login"/>;
  }

  const onSubmit = handleSubmit(({ previousPassword, newPassword }) => {
    if (verification.user && verification.session) {
      dispatch(
        userActions.changePassword(
          verification.user,
          newPassword,
          verification.session,
        ),
      );
    }
  });

  return (
    <LoginFormWrapper>
      <h2>Change Temporary Password</h2>
      <VerifyForm onSubmit={onSubmit}>
        {(userState.error || userState.error === 0) && (
          <LoginFormErrorField>
            <span>
             Password change was unsuccessful.
            </span>
          </LoginFormErrorField>
        )}
        <input
          name="newPassword"
          ref={register({
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          })}
          autoFocus={true}
          placeholder="New password"
          type="password"
        />

        <input
          name="newPasswordAgain"
          ref={register({
            validate: value =>
              value === password.current || 'The passwords do not match',
          })}
          placeholder="Repeat new password"
          type="password"
        />

        {errors.newPassword &&
        <ErrorField style={{display: 'block'}}>
          {errors.newPassword?.message}
        </ErrorField>
        }

        {errors.newPasswordAgain &&
          <ErrorField style={{display: 'block'}}>
            {errors.newPasswordAgain?.message}
          </ErrorField>
        }

        <ButtonWrapper>
          <Button>Change password</Button>
        </ButtonWrapper>
      </VerifyForm>
    </LoginFormWrapper>
  );
};
