import { createAction } from 'typesafe-actions';

export const sideNavToggle = createAction(
  '@@layout/SIDENAV_OPEN',
  data => data
)<void>();

export const previewToggle = createAction(
  '@@layout/PREVIEW_TOGGLE',
  () => {}
)<void>();

export const setPreviewText = createAction(
  '@@layout/PREVIEW_TEXT',
  data => data
)<void>();
