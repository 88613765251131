import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import readableDate from 'utils/readableDate';

import { pdfStyles } from './pdf-styles';
import {
  PDFAgreedSection,
  PDFDocProps,
  PDFField,
  PDFInlineSection,
  PDFScheduleField,
  PDFSection,
} from './PdfComponents';
import { getSchedulesWithLink } from '../../../../utils/workflowUtils';
import AgreementView from './AgreementView';

const PLADocument: React.FC<PDFDocProps> = ({ title, subtitle, details }) => {
  const {
    email,
    address,
    company_name,
    name,
    brand_assets,
    position,
    telephone,
    territory,
    film_name,
  } = details.data;

  const { schedules, uploads } = details;

  const schedulesWithLink = getSchedulesWithLink(schedules, uploads);

  const {
    licensee_name,
    licensee_position,
    bbc_name,
    bbc_position,
    licensee_date,
    agreement,
    bbc_date,
  } = details.license!;

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <Text style={pdfStyles.title}>{title}</Text>
        <Text style={pdfStyles.subtitle}>{subtitle}</Text>

        <PDFInlineSection title="1. LICENSEE">
          <PDFField label="Company name:" value={company_name} />
          <PDFField label="Contact name:" value={name} />
          <PDFField label="Email address:" value={email} />
          <PDFField label="Position:" value={position} />
          <PDFField label="Address:" value={address} />
          <PDFField label="Telephone:" value={telephone} />
        </PDFInlineSection>

        <PDFInlineSection title="2. TRADE MARK(S)">
          <PDFField value={brand_assets} />
        </PDFInlineSection>

        <PDFInlineSection title="3. TITLE OF THE FILM/PROGRAMME">
          <PDFField value={film_name} />
        </PDFInlineSection>

        <PDFInlineSection title="4. TERRITORY">
          <PDFField value={territory} />
        </PDFInlineSection>

        <PDFSection
          title="5. THE AGREEMENT"
          subtitle={`Use of BBC logo/assets in "${film_name}"`}
        >
          {schedulesWithLink?.map(s => (
            <PDFScheduleField
              label={'Schedule ' + s.letter}
              value={s.name}
              url={s.link}
              key={s.name + s.letter}
            />
          ))}
        </PDFSection>

        <PDFSection title="">
          <AgreementView agreement={agreement} />
        </PDFSection>

        <PDFAgreedSection title="Agreed by or on behalf of the Licensee">
          <PDFField label="Contact name:" value={licensee_name ?? name} />
          <PDFField label="Position:" value={licensee_position ?? position} />
          <PDFField label="Start date:" value={readableDate(licensee_date)} />
        </PDFAgreedSection>

        <PDFAgreedSection title="Agreed on behalf of the BBC">
          <PDFField label="Name:" value={bbc_name} />
          <PDFField label="Position:" value={bbc_position} />
          <PDFField label="Date:" value={readableDate(bbc_date)} />
        </PDFAgreedSection>
      </Page>
    </Document>
  );
};

export default PLADocument;
