import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as requestsAction from 'store/requests/actions';
import { ApplicationState } from 'store';
import { addDays, addMonths, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { DetailsParagraph } from 'components/styled/shared/DetailsParagraph';
import { PageTitle } from '../presentational/shared/PageTitle';
import { useParams } from 'react-router';
import FormLabel from 'components/presentational/form/FormLabel';
import TextAreaField from 'components/presentational/shared/TextAreaField';
import { TextInputField } from 'components/presentational/shared/TextInputField';
import { Button } from 'components/styled/shared/Button';
import { Logos } from 'components/presentational/form/Logos';
import {
  Label,
  LabelInline,
  LabelInlineText,
  LabelPlaceholder,
} from 'components/styled/shared/Label';
import { LabelText } from 'components/styled/shared/LabelText';
import { InputField } from 'components/styled/shared/InputField';
import { FileUploadSection } from 'components/presentational/edit/FileUploadSection';
import { FormData } from './types';
import { TypeSelector } from 'components/presentational/shared/TypeSelector';
import { StatusFields } from 'components/presentational/edit/StatusFields';
import { FinalArtworkUpload } from 'components/presentational/edit/FinalArtworkUpload';
import { TradeMarkLicense } from 'components/presentational/edit/TradeMarkLicense';
import { ProductionLicenseAgreement } from 'components/presentational/edit/ProductionLicenseAgreement';
import { License } from 'components/presentational/edit/License';
import { CommonFieldsBody } from 'components/presentational/edit/CommonFieldsBody';
import { LoadingView } from '../presentational/shared/LoadingView';
import { RequestType } from '../../dataaccess/requests/types';
import readableDate from '../../utils/readableDate';
import {
  scheduleDropDownItems,
  prepareScheduleData,
} from 'utils/workflowUtils';

export const Edit: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setValue, watch } = useForm<
    FormData
  >();

  useEffect(() => {
    dispatch(requestsAction.requestDetailsAction(id));
  }, [dispatch, id]);

  const {
    isDetailsLoading,
    details,
    metadata: { statuses, logos, assets },
    error,
  } = useSelector((state: ApplicationState) => state.requests);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [agreeDateCo, setAgreeDateCo] = useState<Date | undefined>(
    details?.license?.licensee_date
      ? new Date(details?.license?.licensee_date)
      : undefined
  );
  const [agreeDateBbc, setAgreeDateBbc] = useState<Date | undefined>(
    details?.license?.bbc_date
      ? new Date(details?.license?.bbc_date)
      : undefined
  );

  useEffect(() => {
    const startDateData =
      details?.data?.start_date !== undefined
        ? new Date(details.data.start_date)
        : new Date();
    const endDateData =
      details?.data?.end_date !== undefined
        ? new Date(details.data.end_date)
        : new Date();
    setStartDate(startDateData);
    setEndDate(endDateData);
  }, [details]);

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };
  const handleAgreeDateCoChange = (date: Date) => {
    setAgreeDateCo(date);
  };
  const handleAgreeDateBbcChange = (date: Date) => {
    setAgreeDateBbc(date);
  };

  if (isDetailsLoading || !id || !details) {
    return <LoadingView />;
  }
  if (error) {
    return <>Something went wrong, please try again later.</>;
  }

  const {
    status: statusValue,
    decline_reason,
    approve_cat,
    redirect_cat,
  } = watch(['status', 'approve_cat', 'decline_reason', 'redirect_cat']);

  const mergeLicenseData = (submitData: any) => {
    return {
      ...submitData.license,
      bbc_date: agreeDateBbc,
      licensee_date:
        details.data?.request_type === RequestType.NEWS
          ? agreeDateCo
          : submitData.license.licensee_date,
    };
  };

  const onSubmit = handleSubmit(submitData => {
    dispatch(
      requestsAction.editRequest({
        ...submitData,
        type: details.data.request_type,
        request_id: details.data.branding_request_id,
        start_date: format(startDate, 'yyyy-MM-dd'),
        end_date: format(endDate, 'yyyy-MM-dd'),
        license: {
          ...mergeLicenseData(submitData),
        },
        schedules: prepareScheduleData(submitData.schedule),
        uploadedFiles: submitData.files?.map((file: any) => file.serverId),
      })
    );
  });

  const getStatusNameById = (id: number) => {
    return statuses.find(
      (status: { status_id: number; name: string }) => status.status_id === id
    ).name;
  };

  const statusesDropDownItems = statuses.reduce(
    (
      acc: { [key: string]: string },
      status: { status_id: number; name: string }
    ) => ({
      ...acc,
      [status.status_id]: status.name,
    }),
    {}
  );

  return (
    <>
      <PageTitle title="Update Request details" />

      <DetailsParagraph>
        <h5>
          Details of a request from {details.data.name} of{' '}
          {details.data.company_name} received on{' '}
          {readableDate(details.data.created_at)}.
        </h5>
        <h5>Status: {getStatusNameById(details.data.request_status)}.</h5>
      </DetailsParagraph>

      <form onSubmit={onSubmit}>
        <StatusFields
          statusValue={statusValue}
          errors={errors}
          register={register}
          statusesDropDownItems={statusesDropDownItems}
          approve_cat={approve_cat}
          decline_reason={decline_reason}
          redirect_cat={redirect_cat}
          data={details.data}
        />

        <TypeSelector
          requestType={details.data.request_type}
          showIf={RequestType.BRANDING}
        >
          <FormLabel text="Status category:">
            {/* TODO: MISSING DATA */}
          </FormLabel>
        </TypeSelector>

        <CommonFieldsBody
          data={details.data}
          register={register}
          errors={errors}
        />

        <Logos
          register={register}
          requestType={details.data.request_type}
          logos={logos}
          assets={assets}
          text="BBC logos requested"
          requestedLogos={details.logos?.map((logo: any) => logo.logo_id)}
          requestedAssets={details.assets?.map((asset: any) => asset.asset_id)}
        />

        <TypeSelector
          requestType={details.data.request_type}
          showIf={RequestType.BRANDING}
        >
          <FormLabel text="BBC programme logos:">
            <TextInputField
              name="brand_assets"
              register={register}
              value={details.data.brand_assets}
            />
          </FormLabel>

          <FormLabel text="Purpose of use:">
            <TextInputField
              name="purpose_of_use"
              register={register}
              value={details.data.purpose_of_use}
            />
          </FormLabel>

          <FormLabel text="Where to be used:">
            <TextInputField
              name="medium_format"
              register={register}
              value={details.data.medium_format}
            />
          </FormLabel>

          <Label>
            <LabelText>Start date:</LabelText>
            <InputField>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat={'dd-MM-yyyy'}
                className="datePickerField"
              />
            </InputField>
          </Label>

          <Label>
            <LabelText>End date:</LabelText>
            <InputField>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                className="datePickerField"
                dateFormat={'dd-MM-yyyy'}
                minDate={addDays(startDate, 1)}
                maxDate={addMonths(startDate, 6)}
              />
            </InputField>
          </Label>
        </TypeSelector>

        <TypeSelector
          requestType={details.data.request_type}
          showIf={RequestType.NEWS}
        >
          <FormLabel text="Other BBC programme logos:">
            <TextAreaField
              name="brand_assets"
              register={register}
              value={details.data.other_assets}
            />
          </FormLabel>

          <FormLabel text="Other BBC assets:">
            <TextAreaField
              name="other_assets"
              register={register}
              value={details.data.other_assets}
            />
          </FormLabel>

          <FormLabel text="Title of the Film/Programme:">
            <TextInputField
              name="film_name"
              register={register}
              value={details.data.film_name}
            />
          </FormLabel>
        </TypeSelector>

        <FormLabel text="Territory:">
          <TextInputField
            name="territory"
            register={register}
            value={details.data.territory}
          />
        </FormLabel>

        <FormLabel text="Context:">
          <TextAreaField
            name="context"
            register={register}
            value={details.data.context}
          />
        </FormLabel>

        <FileUploadSection
          register={register}
          uploads={details.uploads}
          errors={errors}
          setValue={setValue}
        />

        <FormLabel text="Other info:">
          <TextAreaField
            name="other_info"
            register={register}
            value={details.data.other_info}
          />
        </FormLabel>

        <FormLabel text="Message (if declined):">
          {/* TODO: MISSING DATA */}
        </FormLabel>

        <FormLabel text="Data message sent:">
          {/* TODO: MISSING DATA */}
        </FormLabel>

        <TypeSelector
          requestType={details.data.request_type}
          showIf={RequestType.BRANDING}
        >
          <FinalArtworkUpload artwork={details.artwork} />

          <TradeMarkLicense data={details.license} register={register} />

          <License data={details.license} register={register} />
        </TypeSelector>

        <TypeSelector
          requestType={details.data.request_type}
          showIf={RequestType.NEWS}
        >
          <ProductionLicenseAgreement
            data={details.license}
            register={register}
            schedulesData={details.schedules}
            scheduleDropDownItems={scheduleDropDownItems(details)}
          />

          <LabelPlaceholder>
            <LabelText>For the Company:</LabelText>

            <InputField>
              <LabelInline width="full">
                <h5>Agreed:</h5> {details.license?.agree ? 'Yes' : 'No'}
              </LabelInline>

              <LabelInline width="full">
                <h5>Company name: </h5> {details.license?.licensee_company}
              </LabelInline>

              <LabelInline width="full">
                <LabelInlineText>Name:</LabelInlineText>
                <TextInputField
                  name="license[licensee_name]"
                  register={register}
                  value={details.license?.licensee_name}
                />
              </LabelInline>

              <LabelInline width="full">
                <LabelInlineText>Position:</LabelInlineText>
                <TextInputField
                  name="license[licensee_position]"
                  register={register}
                  value={details.license?.licensee_position}
                />
              </LabelInline>

              <LabelInline width="full">
                <LabelInlineText>Date:</LabelInlineText>
                <InputField>
                  <DatePicker
                    selected={agreeDateCo}
                    onChange={handleAgreeDateCoChange}
                    dateFormat={'dd-MM-yyyy'}
                    className="datePickerField"
                  />
                </InputField>
              </LabelInline>
            </InputField>
          </LabelPlaceholder>
        </TypeSelector>

        <LabelPlaceholder>
          <LabelText>For the BBC:</LabelText>

          <InputField>
            <LabelInline width="full">
              <LabelInlineText>Name:</LabelInlineText>
              <TextInputField
                name="license[bbc_name]"
                register={register}
                value={details.license?.bbc_name}
              />
            </LabelInline>

            <LabelInline width="full">
              <LabelInlineText> Position:</LabelInlineText>
              <TextInputField
                name="license[bbc_position]"
                register={register}
                value={details.license?.bbc_position}
              />
            </LabelInline>

            <LabelInline width="full">
              <LabelInlineText>Date:</LabelInlineText>
              <InputField>
                <DatePicker
                  selected={agreeDateBbc}
                  value={undefined}
                  onChange={handleAgreeDateBbcChange}
                  dateFormat={'dd-MM-yyyy'}
                  className="datePickerField"
                />
              </InputField>
            </LabelInline>
          </InputField>
        </LabelPlaceholder>

        <Button>Update</Button>
      </form>
    </>
  );
};
