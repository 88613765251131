import React from 'react';
import { Redirect, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import AcceptTMLForm from 'components/presentational/workflow/forms/AcceptTMLForm';
import AcceptPLAForm from 'components/presentational/workflow/forms/AcceptPLAForm';
import { ContentWrapper } from 'components/styled/shared/ContentWrapper';

import { ApplicationState } from 'store';
import { LoadingView } from 'components/presentational/shared/LoadingView';
import { LicenseType } from 'types/workflow';
import usePublicRequest from '../../hooks/usePublicRequest';
import ErrorMessage from '../presentational/shared/ErrorMessage';
import { ThankYouWrapper } from '../styled/shared/ThankYouWrapper';

const AcceptPage: React.FC = () => {
  const { type } = useParams();

  const { details, isDetailsLoading, error } = usePublicRequest();

  const { isActionProcessing, isPutActionSuccess } = useSelector(
    (state: ApplicationState) => state.actions
  );

  if (isDetailsLoading || isActionProcessing) return <LoadingView />;

  if (error || !details)
    return <ErrorMessage>Resource does not exist.</ErrorMessage>;

  const isFormAlreadyProcessed = details.license?.agree || details.license?.cancel
  if (isFormAlreadyProcessed) {
    const formState = details.license?.agree ? 'accepted' : 'cancelled'
    return (
      <ThankYouWrapper>
        <h2>This license has already been {formState}.</h2>
        <p>The requested license has already been {formState}.</p>
        <p>
          Thank you.
          <br /> BBC Brand Guardianship
        </p>
      </ThankYouWrapper>
    );
  }

  const renderForm = (type: string) => {
    switch (type) {
      case LicenseType.TML:
        return <AcceptTMLForm details={details} />;
      case LicenseType.PLA:
        return <AcceptPLAForm details={details} />;
    }
  };

  if (isPutActionSuccess) {
    return <Redirect to={`/thankyou`} />;
  }

  return <ContentWrapper>{renderForm(type)}</ContentWrapper>;
};

export default AcceptPage;
