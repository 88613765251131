import * as React from 'react';
import { format } from 'date-fns';
import { FormHeadline } from 'components/styled/form/FormHeadline';
import FormLabel from 'components/presentational/form/FormLabel';
import { UploadedFiles } from 'components/presentational/details/UploadedFiles';
import { InputField } from 'components/styled/shared/InputField';
import readableDate from 'utils/readableDate';

interface FinalArtworkUploadProps {
  artwork?: any;
}

export const FinalArtworkUpload: React.FC<FinalArtworkUploadProps> = ({
  artwork,
}) => {
  return (
    <>
      <FormHeadline>Final Artwork upload</FormHeadline>
      <FormLabel text="Final artwork upload:">
        <InputField>
          {artwork?.uploads && <UploadedFiles files={artwork.uploads} />}
        </InputField>
      </FormLabel>
      <FormLabel text="Final artwork date received:">
        <InputField>{artwork?.created_at && readableDate(artwork.created_at)}</InputField>
      </FormLabel>
      <FormLabel text="Other comments on upload:">
        <InputField>{artwork?.other_comments && artwork.other_comments}</InputField>
      </FormLabel>
      <FormLabel text="Decline artwork message:">
        <InputField>{artwork?.decline_message && artwork.decline_message}</InputField>
      </FormLabel>
      <FormLabel text="Decline artwork date:">
        <InputField>{artwork?.declined_date && readableDate(artwork.declined_date)}</InputField>
      </FormLabel>
      <FormLabel text="Artwork approval message:">
        <InputField>{artwork?.approve_message && artwork.approve_message}</InputField>
      </FormLabel>
      <FormLabel text="Artwork approval date:">
        <InputField>{artwork?.approved_date && readableDate(artwork.approved_date)}</InputField>
      </FormLabel>
    </>
  );
};
