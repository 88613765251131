import styled from 'styled-components';

export const NotificationCounter = styled.span`
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  min-width: 17px;
  min-height: 17px;
  background-color: ${({ theme }) => theme.colors.alert};
  color: ${({ theme }) => theme.colors.whiteText};
  padding: 0.125rem 0.375rem;
  font-size: ${({ theme }) => theme.font.size.small};
  top: -2px;
  left: 15px;
`;
