import styled from 'styled-components';

export const HeaderText = styled.h1`
  color: #fff;
  font-size: 36px;
  line-height: 1;
  font-weight: 600;
  float: left;
  margin: 0;
  padding: 12px 0 0 20px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.screen.size.small}px) {
    padding: 5px;
    font-size: 24px;
  }
`;
