import { map, catchError } from 'rxjs/operators';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { Observable, throwError } from 'rxjs';
import { Endpoint } from 'dataaccess/endpoints';
import { createRequest } from 'dataaccess/request/createRequest';
import {
  RequestListData,
  Metadata,
  SearchResults,
  RequestDetailsData, SummaryResponse, AcceptLicenseData, FileData, AcceptLicenseResponse,
} from './types';

const getList = (
  status?: string,
  offset?: number,
  amount?: number
): Observable<RequestListData> => {
  const urlParamsMap = new Map();
  status && urlParamsMap.set('status', status);
  (offset || offset === 0) && urlParamsMap.set('offset', offset);
  amount && urlParamsMap.set('amount', amount);
  return createRequest({
    endpoint: status ? Endpoint.requests : Endpoint.requestsByStatus,
    urlParams: urlParamsMap,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

const getSearch = (
  query?: string,
  status?: string
): Observable<SearchResults> => {
  const urlParamsMap = new Map();
  urlParamsMap.set('query', query);
  urlParamsMap.set('status', status);
  return createRequest({
    endpoint: status ? Endpoint.searchWithStatus : Endpoint.search,
    urlParams: urlParamsMap,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

const getMetadata = (): Observable<Metadata> => {
  return createRequest({
    endpoint: Endpoint.metadata,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

const getAdminMetadata = (): Observable<Metadata> => {
  return createRequest({
    endpoint: Endpoint.adminMetadata,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

const getRequestDetails = (id: number): Observable<RequestDetailsData> => {
  return createRequest({
    endpoint: Endpoint.details,
    urlParams: new Map().set('id', id),
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

const getRequestDetailsWithSignature = (id: number, expires: string, signature: string): Observable<RequestDetailsData> => {
  const urlParamsMap = new Map();
  urlParamsMap.set('id', id);
  urlParamsMap.set('expires', expires);
  urlParamsMap.set('signature', signature);

  return createRequest({
    endpoint: Endpoint.detailsWithSignature,
    urlParams: urlParamsMap,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    })
  );
};

const submitRequest = (data: any): Observable<RequestDetailsData> => {
  return createRequest({
    endpoint: Endpoint.submitRequest,
    body: {
      ...data,
    },
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

const acceptLicense = (data: AcceptLicenseData): Observable<AcceptLicenseResponse> => {
  return createRequest({
    endpoint: Endpoint.acceptLicense,
    urlParams: new Map().set("id", data.request_id),
    body: data,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    })
  );
};

const editRequest = (data: any): Observable<RequestDetailsData> => {
  return createRequest({
    endpoint: Endpoint.editRequest,
    body: {
      ...data,
    },
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

const getNewRequests = (): Observable<Metadata> => {
  return createRequest({
    endpoint: Endpoint.newRequests,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

const getRequestsSummary = (): Observable<SummaryResponse> => {
  return createRequest({
    endpoint: Endpoint.requestsSummary,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    }),
    catchError((error: AjaxError) => throwError(error))
  );
};

export const requestsRepository = {
  getList,
  getMetadata,
  getAdminMetadata,
  getSearch,
  getRequestDetails,
  getRequestDetailsWithSignature,
  submitRequest,
  acceptLicense,
  getNewRequests,
  editRequest,
  getRequestsSummary,
};
