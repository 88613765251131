import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as requestsAction from 'store/requests/actions';
import { Field } from 'components/styled/shared/Field';
import { ApplicationState } from 'store';

interface SearchProps {
  statusId?: string;
}

const Search: React.FC<SearchProps> = ({ statusId }) => {
  const { query } = useSelector((state: ApplicationState) => state.requests);

  const [searchValue, setSearchValue] = useState(query);
  const dispatch = useDispatch();

  const handleSearch = (text: string) => {
    setSearchValue(text);
    dispatch(requestsAction.getSearchQuery(text, statusId));
  };

  return (
    <div>
      <Field
        value={searchValue}
        onChange={e => handleSearch(e.target.value)}
        placeholder="Search..."
      />
    </div>
  );
};

export default Search;
