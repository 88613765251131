import { Observable } from 'rxjs';
import { createRequest } from '../request/createRequest';
import { Endpoint } from '../endpoints';
import { map } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { MessageData, Message, NotificationData } from './types';

const getMessages = (requestId: string): Observable<MessageData[]> => {
  return createRequest({
    endpoint: Endpoint.getMessages,
    urlParams: new Map().set('id', requestId),
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    })
  );
};

const editComment = (
  requestId: string,
  comment: string
): Observable<Message[]> => {
  return createRequest({
    endpoint: Endpoint.editComment,
    urlParams: new Map().set('id', requestId),
    body: {
      comment,
    },
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    })
  );
};

const getMessage = (
  messageId: string,
  expires: string,
  signature: string
): Observable<MessageData[]> => {
  return createRequest({
    endpoint: Endpoint.getMessage,
    urlParams: new Map()
      .set('id', messageId)
      .set('expires', expires)
      .set('signature', signature),
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    })
  );
};

const replyToMessage = (
  messageId: string,
  message: string,
  expires: string,
  signature: string
): Observable<void> => {
  return createRequest({
    endpoint: Endpoint.replyToMessage,
    urlParams: new Map()
      .set('id', messageId)
      .set('expires', expires)
      .set('signature', signature),
    body: {
      message: message,
    },
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    })
  );
};

const getNotifications = (): Observable<NotificationData[]> => {
  return createRequest({
    endpoint: Endpoint.getNotifications,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    })
  );
};

export const messagesRepository = {
  getMessages,
  getNotifications,
  editComment,
  replyToMessage,
  getReplyMessage: getMessage,
};
