import React from 'react';

import { Document, Page, Text } from '@react-pdf/renderer';
import {
  PDFAgreedSection,
  PDFDocProps,
  PDFField,
  PDFInlineSection,
  PDFSection,
} from './PdfComponents';
import { pdfStyles } from './pdf-styles';
import readableDate from 'utils/readableDate';
import AgreementView from './AgreementView';

const TMLDocument: React.FC<PDFDocProps> = ({ title, subtitle, details }) => {
  const {
    email,
    address,
    company_name,
    name,
    start_date,
    end_date,
    brand_assets,
    position,
    medium_format,
    purpose_of_use,
    telephone,
    territory,
  } = details.data;

  const { logos } = details;

  const {
    main,
    main2,
    conditions,
    licensee_company,
    licensee_name,
    licensee_position,
    bbc_name,
    bbc_position,
    bbc_date,
    agreement,
  } = details.license!;

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <Text style={pdfStyles.title}>{title}</Text>
        <Text style={pdfStyles.subtitle}>{subtitle}</Text>

        <PDFInlineSection title="1. LICENSEE">
          <PDFField label="Company name:" value={company_name} />
          <PDFField label="Contact name:" value={name} />
          <PDFField label="Email address:" value={email} />
          <PDFField label="Position:" value={position} />
          <PDFField label="Address:" value={address} />
          <PDFField label="Telephone:" value={telephone} />
        </PDFInlineSection>

        <PDFInlineSection title="2. TRADE MARK(S)">
          <PDFField label="Requested logos:" value={brand_assets} />
          {logos &&
            logos.map(l => (
              <PDFField
                label="BBC logos requested:"
                value={l.name}
                key={l.name}
              />
            ))}
        </PDFInlineSection>

        <PDFInlineSection title="3. DESCRIPTION OF THE ITEM(S)">
          <PDFField value={medium_format} />
        </PDFInlineSection>

        <PDFInlineSection title="4. PURPOSE OF THE ITEM(S)">
          <PDFField value={purpose_of_use} />
        </PDFInlineSection>

        <PDFInlineSection title="5. PERIOD OF USE">
          <PDFField label="Start date:" value={readableDate(start_date)} />
          <PDFField label="End date:" value={readableDate(end_date)} />
        </PDFInlineSection>

        <PDFInlineSection title="6. TERRITORY">
          <PDFField value={territory} />
        </PDFInlineSection>

        <PDFInlineSection title="7. MAIN AGREEMENT">
          <PDFField value={main} />

          <PDFField value="OR" />
          <PDFField value={main2} />
        </PDFInlineSection>

        <PDFInlineSection title="8. ADDITIONAL CONDITIONS">
          <PDFField value={conditions} />
        </PDFInlineSection>

        <PDFSection title="">
          <AgreementView agreement={agreement} />
        </PDFSection>

        <PDFAgreedSection title="Agreed by or on behalf of the Licensee:">
          <PDFField
            label="Company name:"
            value={licensee_company ?? company_name}
          />
          <PDFField label="Contact name:" value={licensee_name ?? name} />
          <PDFField label="Position:" value={licensee_position ?? position} />
          <PDFField label="Start date:" value={readableDate(start_date)} />
        </PDFAgreedSection>

        <PDFAgreedSection title="Agreed on behalf of the BBC:">
          <PDFField label="Name:" value={bbc_name} />
          <PDFField label="Position:" value={bbc_position} />
          <PDFField label="Date:" value={readableDate(bbc_date)} />
        </PDFAgreedSection>
      </Page>
    </Document>
  );
};

export default TMLDocument;
