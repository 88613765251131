import React, { useMemo } from 'react';
import { Table } from 'components/presentational/shared/Table';
import { defaultTableColumns } from './RequestsTableColumns';

interface RequestsListProps {
  statusId?: string;
  customTableColumns?: {
    Header: string;
    accessor?: string;
  }[];
  sortable?: boolean;
  data: [];
  isSearching?: boolean;
  displayOnlyTableBody?: boolean;
  pageSize?: number;
  setPageSize?: any;
  total?: number;
  page?: number;
}

export const RequestsList: React.FC<RequestsListProps> = ({
  customTableColumns,
  sortable,
  data,
  isSearching,
  displayOnlyTableBody,
  pageSize,
  setPageSize,
  total,
  page,
  statusId,
}) => {
  const tableColumns = customTableColumns || defaultTableColumns;
  const columns = useMemo(() => tableColumns, [tableColumns]);

  return (
    <>
      <Table
        columns={columns}
        data={data}
        isSearching={isSearching}
        sortable={sortable}
        displayOnlyTableBody={displayOnlyTableBody}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
        page={page}
        statusId={statusId}
      />
    </>
  );
};
