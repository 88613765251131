import styled from 'styled-components';

export const ScreenContainer = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  z-index: 1000;
`;
export const ModalContainer = styled.div`
  width: 50vw;
  max-width: 60rem;
  max-height: 60vh;
  margin: auto auto;
  background-color: white;
  padding-bottom: 2rem;
  overflow: scroll;
`;
export const ModalHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.whiteText};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightText};

  & > p {
    margin: 0;
    padding: 0;
    font-size: ${({ theme }) => theme.font.size.h4};
  }
`;
export const CloseButton = styled.div`
  height: 1rem;
  width: 1rem;
  cursor: pointer;
`;
export const ModalBody = styled.div`
  height: 100%;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.lightText};
  padding: 1rem;

  & > p {
    margin: 0;
  }

  a:link,
  a:visited {
    color: ${({ theme }) => theme.colors.link};
  }
`;
