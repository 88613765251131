import { Reducer } from 'redux';
import { UserState } from './types';
import * as userActions from './actions';
import { getType } from 'typesafe-actions';

export const initialState: UserState = {
  userData: undefined,
  error: undefined,
  isLoading: true,
  isAuth: false,
  isSignIn: false,
  verification: null,
};

const successState = {
  error: null,
  isLoading: false,
};

const reducer: Reducer<UserState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(userActions.loginRequest): {
      return { ...state, isLoading: false, isSignIn: true };
    }

    case getType(userActions.loginRequestSuccess): {
      return {
        ...state,
        ...successState,
        isSignIn: false,
        isAuth: false,
        verification: {
          secretCode: action.payload.secretCode ?? null,
          accessToken: action.payload.accessToken ?? null,
          challengeName: action.payload.challengeName ?? null,
          session: action.payload.session ?? null,
          user: action.payload.user ?? null,
        },
      };
    }

    case getType(userActions.verifyToken): {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getType(userActions.tokenVerificationSuccess): {
      return {
        ...state,
        isLoading: false,
        isAuth: true,
      };
    }

    case getType(userActions.tokenVerificationError): {
      return {
        ...state,
        isLoading: false,
        isAuth: false,
      };
    }

    case getType(userActions.loginError): {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSignIn: false,
        isAuth: false,
      };
    }

    case getType(userActions.logoutSuccess): {
      return { ...state, isLoading: false, isAuth: false, error: null };
    }

    case getType(userActions.cancelVerification): {
      return { ...state, verification: null, error: null };
    }

    case getType(userActions.clearError): {
      return { ...state, error: null, isLoading: false };
    }

    case getType(userActions.authenticatorVerificationSuccess): {
      return {
        ...state,
        verification: {
          success: true,
        },
        ...successState,
      };
    }

    case getType(userActions.accountVerificationSuccess): {
      return {
        ...state,
        ...successState,
        verification: null,
        isAuth: true,
      };
    }

    case getType(userActions.changePassword): {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getType(userActions.changePasswordSuccess): {
      return {
        ...state,
        ...successState,
        verification: {
          success: true,
        },
      };
    }

    case getType(userActions.changePasswordError): {
      return {
        ...state,
        error: 'The password change was unsuccessful.',
        isLoading: false,
        isSignIn: false,
        isAuth: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as userReducer };
