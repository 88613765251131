import React from 'react';
import { HeaderWrapper } from '../styled/header/HeaderWrapper';
import { TopNavigation } from '../presentational/navigation/TopNavigation';
import { SiteHeader } from '../styled/header/SiteHeader';
import { HeaderText } from 'components/styled/header/HeaderText';
import { SideNavToggleButton } from 'components/styled/header/SideNavToggleButton';
import { LogoutButton } from 'components/styled/header/LogoutButton';
import { ClearFix } from 'components/styled/shared/ClearFix';
import { Notifications } from './Notifications';
import { RightContainer } from 'components/styled/header/RightContainer';
import useSideNavigation from 'hooks/useSideNavigation';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router';

export const Header: React.FC = () => {
  const { sideNavToggle } = useSideNavigation();
  const location = useLocation();

  const { isAuth, onLogout } = useAuth();

  return location.pathname !== '/404' ? (
    <HeaderWrapper>
      <SiteHeader>
        {isAuth ? (
          <>
            <SideNavToggleButton>
              <div className="toggle-bars" onClick={sideNavToggle}>
                <div className="burger" />
                <div className="burger" />
                <div className="burger" />
              </div>
            </SideNavToggleButton>
            <HeaderText>BBC Branding Requests</HeaderText>

            <RightContainer>
              <Notifications />
              <LogoutButton onClick={onLogout}>Log out</LogoutButton>
            </RightContainer>
          </>
        ) : (
          <>
            <SideNavToggleButton />
            <HeaderText>Branding</HeaderText>
          </>
        )}
      </SiteHeader>
      <ClearFix />
      <TopNavigation isAuth={isAuth} />
    </HeaderWrapper>
  ) : (
    <></>
  );
};
