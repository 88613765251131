import {
  LoginRequest,
  User,
  LoginError,
  LoginRequestRespond,
  Authenticator,
  Account, ChangePasswordRequest,
} from 'dataaccess/user/types';

import { createAction } from 'typesafe-actions';

export const loginRequest = createAction(
  '@@user/LOGIN_REQUEST',
  (user: String, password: String) => {
    return { user, password };
  }
)<LoginRequest>();

export const loginRequestSuccess = createAction(
  '@@user/LOGIN_REQUEST_SUCCESS',
  (data: LoginRequestRespond) => ({ ...data })
)<LoginRequestRespond>();

export const logout = createAction('@@user/LOGOUT')<void>();

export const logoutSuccess = createAction('@@user/LOGOUT_SUCCESS')<void>();

export const verifyToken = createAction('@@user/VERIFY_TOKEN')<void>();

export const tokenVerificationError = createAction('@@user/TOKEN_VERIFICATION_ERROR')<void>();

export const tokenVerificationSuccess = createAction('@@user/TOKEN_VERIFICATION_SUCCESS')<void>();

export const loginError = createAction(
  '@@user/LOGIN_ERROR',
  (data: LoginError) => data
)<LoginError>();

export const clearError = createAction('@@user/CLEAR_ERROR')<void>();

export const requestUser = createAction('@@user/REQUEST_USER')<void>();

export const verifySessionId = createAction('@@user/VERIFY_SESSION_ID')<void>();

export const cancelVerification = createAction('@@user/CANCEL_VERIFICATION')<
  void
>();

export const authenticatorVerification = createAction(
  '@@user/AUTHENTICATOR_VERIFICATION',
  (code: String, accessToken: String) => {
    return { code, accessToken };
  }
)<Authenticator>();

export const authenticatorVerificationSuccess = createAction(
  '@@user/AUTHENTICATOR_VERIFICATION_SUCCESS',
  data => data
)<Authenticator>();

export const accountVerification = createAction(
  '@@user/ACCOUNT_VERIFICATION',
  (code: String, session: String, user: string) => {
    return { code, session, user };
  }
)<Account>();

export const changePassword = createAction(
  '@@user/CHANGE_PASSWORD',
  (user: string, newPassword: string, session: string) => {
    return { user, newPassword, session };
  }
)<ChangePasswordRequest>();

export const changePasswordError = createAction('@@user/CHANGE_PASSWORD_ERROR')<void>();

export const changePasswordSuccess = createAction('@@user/CHANGE_PASSWORD_SUCCESS')<void>();

export const accountVerificationSuccess = createAction(
  '@@user/ACCOUNT_VERIFICATION_SUCCESS',
  data => data
)<User>();
