import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  table {
    width: 100%;
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.table.color};
    border-spacing: 0;
    a {
      color: ${({ theme }) => theme.colors.link};
      text-decoration: none;
    }

    thead {
      th {
        border-bottom: 1px solid ${({ theme }) => theme.colors.table.border};
      }
    }
    tbody {
      tr {
        :nth-of-type(odd) {
          background-color: ${({ theme }) => theme.colors.table.darkerRow};
        }
      }
    }

    th {
      font-weight: ${({ theme }) => theme.font.weight.bold};
      text-align: left;
      user-select: none;
      position: relative;
      &:before,
      &:after {
        opacity: 0.3;
      }
      &.sortable {
        &:before {
          position: absolute;
          top: 0.75em;
          right: 0.85em;
          content: '\\2191';
        }
        &:after {
          position: absolute;
          top: 0.75em;
          right: 0.5em;
          content: '\\2193';
        }
        &.asc {
          &:before {
            opacity: 1;
          }
        }
        &.desc {
          &:after {
            opacity: 1;
          }
        }
      }
    }
    th,
    td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid ${({ theme }) => theme.colors.table.border};
    }
  }
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  select {
    font-size: 1rem;
  }
  input {
    border: 1px solid #ced4da;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
`;

export const TableFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
