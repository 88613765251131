import { EnvironmentConfig } from 'config/EnvironmentConfig';
import { ajax } from 'rxjs/ajax';
import { EndpointDescription, FetchRequest } from './types';

export const getBaseHeaders = () => {
  return {
    'Content-type': 'application/json',
  };
};


// TODO: Delete this Header when we will use the Cookie auth
export const getAuthHeaders = () => {
  return {
    //Authorization: sessionStorage.getItem('session_id'),
    ...getBaseHeaders(),
  };
};

const getEndpointUrl = (
  endpoint: EndpointDescription,
  params?: Map<string, string>
) => {
  let urlWithParams: string = endpoint.url;

  if (params !== undefined) {
    params.forEach((value: string, key: string) => {
      const keyWithBraces = `{${key}}`;
      urlWithParams = urlWithParams.replace(keyWithBraces, value);
    });
  }
  return EnvironmentConfig.API_ENDPOINT_URL + urlWithParams;
};

export const createRequest = (request: FetchRequest) => {
  return ajax({
    method: request.endpoint.method,
    url: getEndpointUrl(request.endpoint, request.urlParams),
    headers: { ...request.endpoint.headers(), ...request.headers },
    body: request.body,
    withCredentials: true,
    crossDomain: true,
  });
};
