import * as submitActions from './actions';

import { ActionType } from 'typesafe-actions';

export * from './actions';
export * from './epics';
export * from './reducer';
export * from './types';

export type SubmitActions = ActionType<typeof submitActions>;
