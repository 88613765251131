import React from 'react';
import ErrorWrapper from 'components/styled/workflow/ErrorWrapper';
import Field from 'components/styled/workflow/Field';

interface ServerMessageProps {
  error?: string;
}

const ServerMessage: React.FC<ServerMessageProps> = ({ error }) => (
  <Field>
    <div style={{ flex: 1 }} />
    <div style={{ flex: 3, alignSelf: 'flex-start' }}>
      <ErrorWrapper>{error ? 'An error occurred when submitting the form. Please try again.' : 'Processing...'}</ErrorWrapper>
    </div>
  </Field>
);

export default ServerMessage;
