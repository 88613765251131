import * as React from 'react';
import { LabelText } from 'components/styled/shared/LabelText';
import {
  LabelInline,
  LabelPlaceholder,
  LabelInlineText,
} from 'components/styled/shared/Label';
import { InputField } from 'components/styled/shared/InputField';
import { TextInputField } from 'components/presentational/shared/TextInputField';
import readableDate from 'utils/readableDate';

interface LicenseProps {
  data: any;
  register: any;
}

export const License: React.FC<LicenseProps> = ({
  data,
  register,
}) => {
  return (
    <>
      <LabelPlaceholder>
        <LabelText>For the Licencee:</LabelText>
        <InputField>
          <LabelInline width="full">
            <input
              name="license[agree]"
              type="checkbox"
              ref={register}
              defaultChecked={data?.agree}
            />
            Agreed
          </LabelInline>
          <LabelInline width="full">
            <h5>Company name: </h5> {data?.licensee_company}
          </LabelInline>
          <LabelInline width="full">
            <LabelInlineText>Name:</LabelInlineText>
            <TextInputField
              name="license[licensee_name]"
              register={register}
              value={data?.licensee_name}
            />
          </LabelInline>
          <LabelInline width="full">
            <LabelInlineText>Position:</LabelInlineText>
            <TextInputField
              name="license[licensee_position]"
              register={register}
              value={data?.licensee_position}
            />
          </LabelInline>
          <LabelInline width="full">
            <h5>Date: </h5> {readableDate(data?.licensee_date)}
          </LabelInline>
        </InputField>
      </LabelPlaceholder>
    </>
  );
};
