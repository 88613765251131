import styled from 'styled-components';

export const Field = styled.input`
  type: text;
  border-radius: 3px;
  border-width: 0px;
  padding: 0.5rem 1rem;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark-color(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: textfield;
  background-color: -internal-light-dark-color(white, black);
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 11px system-ui;
  border-width: 2px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  padding: 1px;
  padding-left: 0.5rem;
  width: ${props => props.width};
  height: ${props => props.height ?? '33px'};
`;
