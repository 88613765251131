import { Reducer } from 'redux';
import { SubmitState } from './types';
import * as submitActions from './actions';
import { getType } from 'typesafe-actions';

export const initialState: SubmitState = {
  isSubmitSuccess: false,
  isSubmitting: false,
  submitError: undefined,
};

const reducer: Reducer<SubmitState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(submitActions.submitRequest): {
      return { ...state, isSubmitting: true, submitError: undefined };
    }

    case getType(submitActions.acceptLicense): {
      return { ...state, isSubmitting: true, submitError: undefined };
    }

    case getType(submitActions.submitFiles): {
      return { ...state, isSubmitting: true, submitError: undefined };
    }

    case getType(submitActions.setLogoGuidelines): {
      return {
        ...state,
        isSubmitting: false,
        submitError: undefined,
        isSubmitSuccess: true,
        logoGuidelines: action.payload.data.guidelines,
        licenseUrl: action.payload.data.licenseUrl,
      };
    }

    case getType(submitActions.submitRequestSuccess): {
      return {
        ...state,
        isSubmitting: false,
        submitError: undefined,
        isSubmitSuccess: true,
      };
    }

    case getType(submitActions.getError): {
      return {
        ...state,
        isSubmitting: false,
        submitError: action.payload,
        isSubmitSuccess: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as submitReducer };
