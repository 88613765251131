import React from 'react';

import { LoginForm } from '../../container/LoginForm';
import { useLocation } from 'react-router';

export const Login: React.FC = () => {
  const location = useLocation();

  return <LoginForm destination={location.search} />;
};
