import React from 'react';
import FormField from '../Field';
import { RequestData } from '../../../../dataaccess/requests/types';
import { ActionTypes } from '../../../../dataaccess/actions/types';

interface DeclineFormProps {
  data: RequestData;
  register?: any;
  decision: any;
  errors: any;
  control: any;
  watch: any;
}

const DeclineForm: React.FC<DeclineFormProps> = ({
  register,
  errors,
  control,
  watch,
}) => {
  const declineCategories = [
    {
      value: '',
      label: '---- Please select decline category ----',
      disabled: true,
      selected: true,
    },
    {
      value: 'legal',
      label: 'Legal',
    },
    {
      value: 'fair_trading',
      label: 'Fair Trading',
    },
    {
      value: 'editorial_policy',
      label: 'Editorial Policy',
    },
    {
      value: 'branding',
      label: 'Branding',
    },
    {
      value: 'programme_team',
      label: 'Programme Team',
    },
    {
      value: 'marketing_team',
      label: 'Marketing Team',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  const currentCategory = watch('category');

  return (
    <>
      <FormField
        inputType="hidden"
        fieldName="action_id"
        defaultValue={ActionTypes.DeclineWithMessage}
        register={register}
      />

      <FormField
        labelText="Decline categories:"
        fieldName="category"
        inputType="selector"
        register={register}
        required="Please choose a category"
        errors={errors}
        control={control}
        options={declineCategories}
      />
      {currentCategory === 'other' && (
        <FormField
          labelText="Decline reason:"
          inputType="input"
          fieldName="category_other"
          register={register}
          required="Please provide a reason"
          errors={errors}
        />
      )}

      <FormField
        labelText="Email to:"
        inputType="input"
        fieldName="recipient"
        required="Please provide the recipient name"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Email address:"
        inputType="input"
        fieldName="recipient_address"
        required="Please provide the recipient email address"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Message"
        inputType="editor"
        fieldName="message"
        required="Please compose your message"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Email from:"
        inputType="input"
        fieldName="sender"
        required="Please provide your name"
        register={register}
        errors={errors}
      />
    </>
  );
};

export default DeclineForm;
