import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { History } from 'history';
import { PrivateRoute } from 'components/presentational/shared/PrivateRedirect';
import './i18n';
import { withAuthVerification } from 'components/container/withAuthVerification';
import { Admin } from 'components/container/Admin';
import { Header } from 'components/container/Header';
import { LoadingView } from 'components/presentational/shared/LoadingView';
import { GlobalStyle } from 'components/styled/shared/GlobalStyle';
import { BrandRequest } from 'components/pages/BrandRequest';
import { NewsRequest } from 'components/pages/NewsRequest';
import { RequestThankYou } from 'components/pages/RequestThankYou';
import FileUploadPage from './components/pages/FileUploadPage';
import AcceptPage from './components/pages/AcceptPage';
import ViewAgreement from './components/pages/ViewAgreement';
import { AcceptLicenseSuccess } from './components/container/AcceptLicenseSuccess';
import ReplyToMessagePage from './components/pages/ReplyToMessagePage';
import { Landing } from 'components/pages/Landing';
import { Error404 } from './components/container/Error404';

interface AppProps {
  history: History;
}

const App: React.FC<AppProps> = ({ history }) => {
  return (
    <Suspense fallback={<LoadingView />}>
      <GlobalStyle />
      <ConnectedRouter history={history}>
        <Header />
        <Switch>
          <Route path="/fileupload/:id/:type" component={FileUploadPage} />
          <Route path="/accept/:type/:id" component={AcceptPage} />
          <Route path="/agreement/:id" component={ViewAgreement} />
          <Route path="/messages/:id/reply" component={ReplyToMessagePage} />
          <Route path="/brandrequest" component={BrandRequest} />
          <Route path="/newsrequest" component={NewsRequest} />
          <Route path="/thankyou" component={RequestThankYou} />
          <Route path="/licensesuccess" component={AcceptLicenseSuccess} />
          <Route path="/login" component={Landing} />
          <Route path="/404" component={Error404} />

          <PrivateRoute path="/admin/:subpage?/:id?" component={Admin} />
          <Redirect from="/" to="/brandrequest" exact />
          <Redirect from="*" to="/404" />
        </Switch>
      </ConnectedRouter>
    </Suspense>
  );
};

export default withAuthVerification(App);
