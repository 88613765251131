import React from 'react';

import FieldMessageEditor from './FieldMessageEditor';
import FieldDatePicker from './FieldDatePicker';

import FieldSelector from './FieldSelector';
import { ErrorMessage } from 'react-hook-form';
import { SelectFieldOption } from 'types/workflow';
import FileUploader from '../form/FileUploader';
import Field from 'components/styled/workflow/Field';
import ErrorWrapper from 'components/styled/workflow/ErrorWrapper';
import { FieldInput } from 'components/styled/workflow/FieldInput';
import { FieldLabelText } from 'components/styled/workflow/FieldLabelText';
import FieldRadioAgree from './FieldRadioAgree';
import { UploadedFiles } from '../details/UploadedFiles';
import { FileData } from '../../../dataaccess/requests/types';
import { DropDown } from '../form/DropDown';

interface FormFieldProps {
  inputType: string;
  fieldName: string;
  register: any;
  labelText?: string;
  defaultValue?: string;
  dateValue?: Date;
  textAreaRows?: number;
  uploads?: FileData[];
  options?: SelectFieldOption[];
  scheduleOptions?: { [key: string]: string };
  scheduleValue?: any;
  fullWidth?: boolean;
  errors?: any;
  setValue?: any;
  required?: string;
  pattern?: RegExp;
  patternError?: string;
  control?: any;
}

const FormField: React.FC<FormFieldProps> = ({
  inputType,
  fieldName,
  labelText,
  defaultValue,
  textAreaRows = 8,
  register,
  uploads,
  dateValue,
  fullWidth,
  options,
  errors,
  setValue,
  required,
  pattern,
  patternError,
  control,
  scheduleOptions,
  scheduleValue,
}) => {
  const FieldSwitch = () => {
    switch (inputType) {
      default:
      case 'input':
        return (
          <input
            name={fieldName}
            defaultValue={defaultValue}
            ref={register({ required, pattern: { value: pattern, message: patternError} })}
          />
        );
      case 'textarea':
        return <textarea name={fieldName} ref={register} rows={textAreaRows} />;
      case 'uploadedFiles':
        return <UploadedFiles files={uploads || []} />;
      case 'editor':
        return (
          <FieldMessageEditor
            name={fieldName}
            defaultValue={defaultValue}
            register={register({ required })}
          />
        );
      case 'date':
        return (
          <FieldDatePicker
            name={fieldName}
            dateValue={dateValue}
            register={register}
          />
        );
      case 'selector':
        return (
          <FieldSelector
            options={options}
            register={register}
            name={fieldName}
            control={control}
            required={required || ''}
          />
        );
      case 'schedule-selector':
        return (
          <DropDown
            name={fieldName}
            register={register}
            items={scheduleOptions ?? {}}
            value={scheduleValue}
            disableDefaultText={true}
          />
        );
      case 'agree_radio':
        return (
          <FieldRadioAgree
            options={options}
            fieldName={fieldName}
            register={register}
            required="Please choose an option"
          />
        );
      case 'fileuploader':
        return (
          <FileUploader
            name={fieldName}
            setValue={setValue}
            register={register}
            required="Please add a file"
          />
        );
      case 'hidden':
        return (
          <input
            name={fieldName}
            defaultValue={defaultValue}
            ref={register}
            style={{ display: 'none' }}
          />
        );
    }
  };

  return (
    <Field>
      {!fullWidth && <FieldLabelText>{labelText}</FieldLabelText>}
      <FieldInput>
        {FieldSwitch()}
        <ErrorWrapper>
          {errors && <ErrorMessage errors={errors} name={fieldName} />}
        </ErrorWrapper>
      </FieldInput>
    </Field>
  );
};

export default FormField;
