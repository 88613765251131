import { createAction } from 'typesafe-actions';
import { MessageData, NotificationData } from '../../dataaccess/messages/types';

export const requestListAction = createAction(
  '@@requests/REQUEST_LIST',
  (status, offset, amount) => ({ status, offset, amount })
)();

export const getListSuccess = createAction(
  '@@requests/GET_LIST_SUCCESS',
  data => data
)();

export const getSearchQuery = createAction(
  '@@requests/GET_SEARCH',
  (query, statusId) => {
    return {
      query,
      status: statusId,
    };
  }
)();

export const getSearchSuccess = createAction(
  '@@requests/GET_SEARCH_SUCCESS',
  searchResults => searchResults
)();

export const getError = createAction('@@requests/GET_ERROR', error => error)();

export const requestsMetadata = createAction('@@requests/METADATA')();
export const requestsAdminMetadata = createAction(
  '@@requests/ADMIN_METADATA'
)();

export const getMetadataSuccess = createAction(
  '@@requests/GET_METADATA_SUCCESS',
  data => data
)();

export const requestDetailsAction = createAction(
  '@@requests/REQUEST_DETAILS',
  id => ({ id })
)();

export const requestDetailsWithSignatureAction = createAction(
  '@@requests/REQUEST_DETAILS_WITH_SIGNATURE',
  (id: string, expires: string, signature: string) => ({
    id,
    expires,
    signature,
  })
)();

export const getRequestDetailsSuccess = createAction(
  '@@requests/REQUEST_DETAILS_SUCCESS',
  data => data
)();

export const editRequest = createAction('@@requests/EDIT_REQUEST', data => ({
  data,
}))();

export const getNewRequests = createAction('@@requests/NEW_REQUESTS')();

export const getNewRequestsSuccess = createAction(
  '@@requests/NEW_REQUESTS_SUCCESS',
  data => data
)();

export const getRequestsSummary = createAction(
  '@@requests/GET_REQUESTS_SUMMARY'
)();

export const getRequestsSummarySuccess = createAction(
  '@@requests/REQUESTS_SUMMARY_SUCCESS',
  data => data
)();

export const getRequestMessages = createAction(
  '@@requests/GET_REQUEST_MESSAGES',
  (requestId: string) => ({ requestId })
)();

export const setRequestMessages = createAction(
  '@@requests/SET_REQUEST_MESSAGES',
  (data: MessageData[]) => ({ data })
)();

export const getNotifications = createAction(
  '@@requests/GET_NOTIFICATIONS'
)();

export const setNotifications = createAction(
  '@@requests/SET_NOTIFICATIONS',
  (data: NotificationData[]) => ({ data })
)();

export const getNotificationsError = createAction('@@requests/GET_NOTIFICATIONS_ERROR', error => error)();

export const getMessage = createAction(
  '@@requests/GET_MESSAGE',
  (id: string, expires: string, signature: string) => ({
    id,
    expires,
    signature,
  })
)();

export const getMessageSuccess = createAction(
  '@@requests/GET_MESSAGE_SUCCESS',
  emailData => emailData[0]
)();

export const replyToMessage = createAction(
  '@@requests/REPLY_TO_MESSAGE',
  (id: string, message: string, expires: string, signature: string) => ({
    id,
    message,
    expires,
    signature,
  })
)();

export const replyToMessageSuccess = createAction('@@requests/REPLY_TO_MESSAGE_SUCCESS')();

export const editComment = createAction(
  '@@requests/EDIT_COMMENT',
  (id, comment) => ({ id, comment })
)();

export const editCommentError = createAction(
  '@@requests/EDIT_COMMENT_ERROR',
  error => error
)();
