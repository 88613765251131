import styled from 'styled-components';

export const DetailsParagraph = styled.div`
  margin-bottom: 30px;
  h5 {
    font-size: ${({ theme }) => theme.font.size.h5};
    font-weight: ${({ theme }) => theme.font.weight.default};
    margin: 0 0 0.5rem 0;
  }
`;
