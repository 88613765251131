import { isDateValid } from './isDateValid';
import moment from 'moment';

export default function(date?: string | Date, showAsDateTime: boolean = false) {
  if (!date) {
    return null;
  }
  const dateString = date.toString().replace(/-/g, '/');
  const correctDate = moment(dateString);
  return correctDate.format(showAsDateTime ? 'DD MMM yyy hh:mm:ss' : 'DD MMM yyy');
}
