import { useDispatch, useSelector } from 'react-redux';
import * as userActions from 'store/user/actions';
import { ApplicationState } from 'store';

const useAuth = () => {
  const dispatch = useDispatch();

  const { isAuth } = useSelector((state: ApplicationState) => state.user);

  const onLogout = () => {
    dispatch(userActions.logout());
  };

  return {
    isAuth,
    onLogout,
  };
};

export default useAuth;
