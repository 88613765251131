import * as React from 'react';
import { Label } from 'components/styled/shared/Label';
import { LabelText } from 'components/styled/shared/LabelText';
import { TextInputField } from 'components/presentational/shared/TextInputField';
import FileUploader from '../form/FileUploader';
import { submitFiles } from '../../../store/submit';
import { InputField } from '../../styled/shared/InputField';
import { FileData } from '../../../dataaccess/requests/types';
import { UploadedFiles } from '../details/UploadedFiles';

interface UploadedFilesProps {
  uploads?: FileData[];
  errors: any;
  register: any;
  setValue: any;
}

export const FileUploadSection: React.FC<UploadedFilesProps> = ({
  uploads,
  errors,
  register,
  setValue,
}) => {
  return (
    <Label key="file_uploaded">
      <LabelText>Uploaded files</LabelText>
      <InputField>
        <UploadedFiles files={uploads ?? []} />
        <FileUploader name="files" setValue={setValue} register={register} />
      </InputField>
    </Label>
  );
};
