import React from 'react';
import {
  ScreenContainer,
  ModalBody,
  ModalContainer,
  ModalHeader,
  CloseButton,
} from 'components/styled/shared/Modal';
import { ApplicationState } from 'store';
import { useSelector } from 'react-redux';

interface PreviewModalProps {
  onClose: (e: any) => void;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ onClose }) => {
  const { previewIsOpen, previewText } = useSelector(
    (state: ApplicationState) => state.layout
  );

  return (
    <ScreenContainer isOpen={previewIsOpen}>
      <ModalContainer>
        <ModalHeader>
          <p>Preview</p>
          <CloseButton onClick={onClose}>X</CloseButton>
        </ModalHeader>
        <ModalBody
          dangerouslySetInnerHTML={{
            __html: previewText,
          }}
        />
      </ModalContainer>
    </ScreenContainer>
  );
};

export default PreviewModal;
