import { Observable } from 'rxjs';
import { createRequest } from '../request/createRequest';
import { Endpoint } from '../endpoints';
import { map } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { FileSubmissionRequest } from './types';

const submitFiles = (data: FileSubmissionRequest): Observable<void> => {
  return createRequest({
    endpoint: Endpoint.submitFiles,
    urlParams: new Map().set('id', data.requestId),
    body: data,
  }).pipe(
    map((response: AjaxResponse) => {
      return response.response;
    })
  );
};

export const uploadRepository = {
  submitFiles
}
