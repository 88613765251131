import * as React from 'react';

interface TypeSelectorProps {
  requestType: number;
  showIf: number;
}

export const TypeSelector: React.FC<TypeSelectorProps> = ({
  requestType,
  showIf,
  children,
}) => {
  if (typeof showIf !== 'undefined' && showIf !== requestType) {
    return null;
  }
  return <>{children}</>;
};
