import * as React from 'react';
import { ThankYouWrapper } from 'components/styled/shared/ThankYouWrapper';

export const FileUploadSuccess: React.FC = () => (
  <ThankYouWrapper>
    <h2>File submission success</h2>
    <p>Your file(s) have been uploaded.</p>
    <p>
      Thank you.
      <br /> BBC Brand Guardianship
    </p>
  </ThankYouWrapper>
);
