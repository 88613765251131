import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { addYears, format } from 'date-fns';
import { isDateValid } from 'utils/isDateValid';

import 'react-datepicker/dist/react-datepicker.css';

enum DAtePickerOutputFormat {
  Date,
  DateTime
}

interface DatePickerProps {
  name: string;
  defaultValue?: any;
  register: any;
  dateValue: any;
}

const DatePickerField: React.FC<DatePickerProps> = ({
  name,
  dateValue,
  register,
}) => {
  const initDate = isDateValid(dateValue) ? new Date(dateValue) : new Date();
  const [selectedValue, setSelectedValue] = useState<any>(initDate);

  return (
    <>
      <input
        name={name}
        type="custom"
        ref={register}
        value={format(selectedValue, "yyyy-MM-dd")}
        style={{ display: 'none' }}
        onChange={() => {}}
      />
      <DatePicker
        selected={selectedValue}
        dateFormat="dd-MM-yyyy"
        onChange={(newDate: Date) => setSelectedValue(newDate)}
        minDate={new Date()}
        maxDate={addYears(new Date(), 30)}
      />
    </>
  );
};

export default DatePickerField;
