import React from 'react';
import { Route, Switch } from 'react-router';
import { Login } from 'components/pages/login/Login';
import { Authenticator } from 'components/pages/login/Authenticator';
import { Verify } from 'components/pages/login/Verify';
import { Wrapper } from 'components/styled/shared/Wrapper';
import { ChangePassword } from './login/PasswordChange';


interface LandingProps {}

export const Landing: React.FC<LandingProps> = () => {
  return (
    <Wrapper>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/login/authenticator" component={Authenticator} />
        <Route path="/login/verify" component={Verify} />
        <Route path="/login/change-password" component={ChangePassword} />
      </Switch>
    </Wrapper>
  );
};
