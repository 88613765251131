export enum RequestStatuses {
  Pending = 1,
  Declined,
  Approved,
  Redirected,
  BrandAdvice,
  InProcess,
  Cancelled,
  Inactive,
}

export interface RequestListItem {
  branding_request_id: string;
  created_at: string;
  request_type: string;
  request_status: string;
  company_name: string;
  contact_name: string;
  name: string;
  start_date: string;
  purpose_of_use: string;
  position: string;
  relationship: string;
}

export interface RequestListData {
  items: RequestListItem[];
}

export enum RequestType {
  BRANDING = 0,
  NEWS = 1,
}

export interface AcceptFormProps {
  details: RequestDetailsData;
}

export interface RequestData {
  action_id: string;
  branding_request_id: string;
  created_at: string;
  reqref: string;
  request_type: RequestType;
  request_status: RequestStatuses;
  company_name: string;
  name: string;
  position: string;
  address: string;
  telephone: string;
  email: string;
  business: string;
  relationship?: string;
  contact_name?: string;
  contact_email?: string;
  brand_assets?: string;
  other_assets?: string;
  purpose_of_use?: string;
  film_name?: string;
  medium_format?: string;
  start_date?: string;
  end_date?: string;
  territory: string;
  context: string;
  other_info?: string;
  assets_sent?: string;
  approve_cat?: string;
  approve_other?: string;
  decline_reason?: string;
  decline_other?: string;
  redirect_cat?: string;
  redirect_other?: string;
  message?: string;
  direct_message?: string;
  forward_message?: string;
  forward_cc?: string;
  msg_sent?: string;
  url?: string;
  general_comment?: string;
  general_comment_date?: Date;
}

export interface Metadata {
  id: number;
}

export enum FileStatus {
  cleared,
  processing,
  removed,
}

export interface FileData {
  name: string;
  url: string;
  status: FileStatus;
}

export interface Asset {
  asset_id: string;
  name: string;
}

export interface Logo {
  logo_id: string;
  name: string;
}

export interface Artwork {
  other_comments: string;
  created_at: string;
  decline_message: string;
  approve_message: string;
  approved_date: string;
  declined_date: string;
  uploads: FileData[];
}

export interface License {
  agree: boolean;
  cancel: boolean;
  licensee_company?: string;
  licensee_name?: string;
  licensee_position?: string;
  licensee_date?: string;
  bbc_name?: string;
  bbc_position?: string;
  bbc_date?: string;
  reminder: boolean;
  main: string;
  main2: string;
  conditions: string;
  agreement: string;
}

export interface Schedule {
  letter: string;
  name: string;
}

export interface ScheduleWithLink extends Schedule {
  link?: string;
}

export interface Messages {
  messageCount: number;
  forwardCount: number;
}

export interface RequestDetailsData {
  data: RequestData;
  uploads?: FileData[];
  assets?: Asset[];
  logos?: Logo[];
  artwork?: Artwork;
  license?: License;
  schedules: Schedule[];
  messages: Messages;
}

export interface SearchResults {
  branding_request_id: number;
  request_type: number;
  request_status: RequestStatuses;
  company_name: string;
  name: string;
  position: string;
  address?: string;
  telephone?: string;
  email?: string;
  business?: string;
  relationship?: string;
  contact_name?: string;
  contact_email?: string;
  brand_assets?: string;
  other_assets?: string;
  purpose_of_use?: string;
  film_name?: string;
  medium_format: string;
  start_date?: string;
  end_date?: string;
  territory: string;
  context?: string;
  other_info?: string;
  created_at: string;
}

export interface SummaryItem {
  name: string;
  count: number;
}

export interface SummaryResponse {
  total_requests_by_status: SummaryItem[];
  approved_by_category: SummaryItem[];
  declined_by_category: SummaryItem[];
}

export interface AcceptLicenseData {
  request_id: string;
  agreeString: string;
  agree: boolean;
  company_name: string;
  name: string;
  position: string;
  date: Date;
  licenseUrl: string;
}

export interface AcceptLicenseResponse {
  agreed: boolean;
  guidelines?: FileData[];
  licenseUrl: string;
}
