export const relationDropDownItems = {
  None: 'None',
  'member of BBC staff': 'I am a member of BBC staff',
  'film/programme maker': 'I am a film/programme maker',
  'independent production company': 'I am an independent production company',
  'partner of the BBC': 'I am a partner of the BBC',
};

export const acceptedFileTypes = [
  'image/*',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]
