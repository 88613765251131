import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as requestsAction from 'store/requests/actions';
import { ApplicationState } from 'store';
import { DetailsParagraph } from 'components/styled/shared/DetailsParagraph';
import WorkflowActionButtons from 'components/presentational/workflow/WorkflowActionButtons';
import readableDate from 'utils/readableDate';
import { useParams } from 'react-router';
import { PageTitle } from '../presentational/shared/PageTitle';
import {
  CustomTable,
  DataCell,
  HeaderCell,
  TableRow,
} from 'components/styled/shared/CustomTable';
import { PlainList } from 'components/presentational/details//PlainList';
import { UploadedFiles } from 'components/presentational/details/UploadedFiles';
import { Messages } from 'components/presentational/details/Messages';
import { FinalArtworkUpload } from 'components/presentational/details/FinalArtworkUpload';
import { TradeMarkLicense } from 'components/presentational/details/TradeMarkLicense';
import { ProductionLicenseAgreement } from 'components/presentational/details/ProductionLicenseAgreement';
import { TypeSelector } from 'components/presentational/shared/TypeSelector';
import { LoadingView } from '../presentational/shared/LoadingView';
import { RequestStatuses, RequestType } from '../../dataaccess/requests/types';
import { Collapse } from '../presentational/shared/Collapse';
import SetInactiveButton from 'components/presentational/workflow/SetInactiveButton';
import { CommentData } from 'dataaccess/messages/types';

export const Details: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    isDetailsLoading,
    details,
    metadata: { statuses },
    error,
    comment,
  } = useSelector((state: ApplicationState) => state.requests);

  const commentData: CommentData = {
    commentText: details?.data.general_comment,
    date: details?.data.general_comment_date,
    state: comment,
  };

  useEffect(() => {
    dispatch(requestsAction.requestDetailsAction(id));
  }, [dispatch, id]);

  const getStatusNameById = (id: number) => {
    return statuses.find(
      (status: { status_id: number; name: string }) => status.status_id === id
    ).name;
  };

  if (isDetailsLoading || !id || !details) {
    return <LoadingView />;
  }

  if (error) {
    return <>Something went wrong, please try again later.</>;
  }

  const statusString = getStatusNameById(details.data.request_status);

  const ActionButtonsSwitch = () => {
    switch (details.data.request_status) {
      case RequestStatuses.Pending:
        return (
          <WorkflowActionButtons
            requestType={details.data.request_type}
            brandingRequestId={details.data.branding_request_id}
          />
        )
      case RequestStatuses.Approved:
      case RequestStatuses.Inactive:
      case RequestStatuses.Declined:
        return null
      default:
        return <SetInactiveButton requestId={details.data.branding_request_id}/>
    }
  }

  return (
    <>
      <PageTitle title="Request details" />
      <DetailsParagraph>
        <h5>
          Details of a request from {details.data.name} of{' '}
          {details.data.company_name} received on{' '}
          {readableDate(details.data.created_at)}.
        </h5>
        <h5>Status: {statusString}.</h5>
      </DetailsParagraph>

      {ActionButtonsSwitch()}

      <CustomTable>
        <tbody>
          <TableRow>
            <HeaderCell>ID:</HeaderCell>
            <DataCell>{details.data.branding_request_id}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Request reference:</HeaderCell>
            <DataCell>{details.data.reqref}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Company name:</HeaderCell>
            <DataCell>{details.data.company_name}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Contact name:</HeaderCell>
            <DataCell>{details.data.name}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Position:</HeaderCell>
            <DataCell>{details.data.position}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Address:</HeaderCell>
            <DataCell>{details.data.address}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Telephone:</HeaderCell>
            <DataCell>{details.data.telephone}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Email address:</HeaderCell>
            <DataCell>{details.data.email}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Nature of business:</HeaderCell>
            <DataCell>{details.data.business}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Relationship to the BBC:</HeaderCell>
            <DataCell>{details.data.relationship}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>BBC contact name:</HeaderCell>
            <DataCell>{details.data.contact_name}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>BBC contact email:</HeaderCell>
            <DataCell>{details.data.contact_email}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>BBC logos requested:</HeaderCell>
            <DataCell>
              <PlainList list={details.logos?.map(item => item.name) ?? []} />
            </DataCell>
          </TableRow>

          <TableRow
            requestType={details.data.request_type}
            showIf={RequestType.NEWS}
          >
            <HeaderCell>Other BBC programme logos:</HeaderCell>
            <DataCell>
              <PlainList list={details.assets?.map(item => item.name) ?? []} />
            </DataCell>
          </TableRow>

          <TableRow
            requestType={details.data.request_type}
            showIf={RequestType.BRANDING}
          >
            <HeaderCell>BBC programme logos:</HeaderCell>
            <DataCell>{details.data.brand_assets}</DataCell>
          </TableRow>

          <TableRow
            requestType={details.data.request_type}
            showIf={RequestType.NEWS}
          >
            <HeaderCell>Other BBC assets:</HeaderCell>
            <DataCell>{details.data.brand_assets}</DataCell>
          </TableRow>

          <TableRow
            requestType={details.data.request_type}
            showIf={RequestType.BRANDING}
          >
            <HeaderCell>Purpose of use:</HeaderCell>
            <DataCell>{details.data.purpose_of_use}</DataCell>
          </TableRow>

          <TableRow
            requestType={details.data.request_type}
            showIf={RequestType.BRANDING}
          >
            <HeaderCell>Where to be used:</HeaderCell>
            <DataCell>{details.data.medium_format}</DataCell>
          </TableRow>

          <TableRow
            requestType={details.data.request_type}
            showIf={RequestType.BRANDING}
          >
            <HeaderCell>Start date:</HeaderCell>
            <DataCell>{readableDate(details.data.start_date)}</DataCell>
          </TableRow>

          <TableRow
            requestType={details.data.request_type}
            showIf={RequestType.BRANDING}
          >
            <HeaderCell>End date:</HeaderCell>
            <DataCell>{readableDate(details.data.end_date)}</DataCell>
          </TableRow>

          <TableRow
            showIf={RequestType.NEWS}
            requestType={details.data.request_type}
          >
            <HeaderCell>Title of the Film/Programme:</HeaderCell>
            <DataCell>{details.data.film_name}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Territory:</HeaderCell>
            <DataCell>{details.data.territory}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Context:</HeaderCell>
            <DataCell>{details.data.context}</DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Uploaded file(s):</HeaderCell>
            <DataCell>
              <UploadedFiles files={details.uploads ?? []} />
            </DataCell>
          </TableRow>

          <TableRow>
            <HeaderCell>Other info:</HeaderCell>
            <DataCell>{details.data.other_info}</DataCell>
          </TableRow>
        </tbody>
      </CustomTable>

      <Collapse headerText="Messages" >
        <Messages messages={details.messages} commentData={commentData} />
      </Collapse>

      <TypeSelector
        requestType={details.data.request_type}
        showIf={RequestType.BRANDING}
      >
        <Collapse headerText="Final Artwork upload">
          <FinalArtworkUpload
            requestId={details.data.branding_request_id}
            artwork={details.artwork}
          />
        </Collapse>
      </TypeSelector>

      <TypeSelector
        requestType={details.data.request_type}
        showIf={RequestType.BRANDING}
      >
        <Collapse headerText="Trade Mark Licence">
          <TradeMarkLicense data={details} />
        </Collapse>
      </TypeSelector>

      <TypeSelector
        requestType={details.data.request_type}
        showIf={RequestType.NEWS}
      >
        <Collapse headerText="Production Licence Agreement">
          <ProductionLicenseAgreement data={details} />
        </Collapse>
      </TypeSelector>
    </>
  );
};
