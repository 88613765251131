import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 20px;
  width: 100%;

  h3 {
    margin-top: 0;
    font-size: ${({ theme }) => theme.font.size.h3};
    font-weight: ${({ theme }) => theme.font.weight.default};
    margin-bottom: 20px;
  }
  hr { 
    margin: 30px 0;
  }
`;
