import React from 'react';

import { ContentWrapper } from 'components/styled/shared/ContentWrapper';
import AdminPageHeader from 'components/presentational/workflow/AdminPageHeader';
import FormBody from 'components/styled/workflow/FormBody';
import FormDescription from 'components/styled/workflow/FormDescription';
import { useForm } from 'react-hook-form';
import FormField from 'components/presentational/workflow/Field';
import FormButtons from 'components/presentational/workflow/FormButtons';
import fillTemplate from 'utils/fillTemplate';
import usePublicRequest from '../../hooks/usePublicRequest';
import { LoadingView } from '../presentational/shared/LoadingView';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import * as submitActions from '../../store/submit/actions';
import { FileSubmissionRequest, UploadType } from '../../dataaccess/upload/types';
import { File } from 'react-filepond';
import { FileUploadSuccess } from '../container/FileUploadSuccess';

const FileUploadPage = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const { details, isDetailsLoading, error } = usePublicRequest();
  const { isSubmitting, isSubmitSuccess, submitError } = useSelector((state: ApplicationState) => state.submit);
  const uploadPageType: UploadType = UploadType[type as keyof typeof UploadType];

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    formState,
    reset,
  } = useForm<FileSubmissionRequest>();

  if (isDetailsLoading || isSubmitting) {
    return <LoadingView/>;
  }

  if (isSubmitSuccess) {
    return <FileUploadSuccess />
  }

  if (error || !details || submitError) {
    return <h1>Resource does not exist.</h1>
  }

  const onSubmit = (data: FileSubmissionRequest) => {
    data.uploadType = uploadPageType;
    data.uploadedFiles = data.tempFiles.map((file: File) => file.serverId);
    data.requestId = details.data.branding_request_id;
    console.log(data)
    dispatch(submitActions.submitFiles(data));
  }

  return (
    <ContentWrapper>
      <AdminPageHeader
        title={formTitles[uploadPageType]}
        subtitle={fillTemplate('Welcome back {{NAME}}', {
          NAME: details.data.name
        })}
      />
      <FormBody onSubmit={handleSubmit(onSubmit)}>

        {formDescriptions[uploadPageType]}

        <FormField
          fieldName='tempFiles'
          inputType='fileuploader'
          labelText='File Upload'
          setValue={setValue}
          register={register}
          errors={errors}
        />

        <FormField
          fieldName='other_comments'
          inputType='textarea'
          labelText='Any other information you wish to add?'
          textAreaRows={3}
          defaultValue=''
          register={register}
        />

        <FormButtons onReset={reset} disabled={formState.isSubmitting}/>
      </FormBody>
    </ContentWrapper>
  )
}

const formDescriptions = [
  (<FormDescription>
    Please upload a jpg, png, pdf or Word document of your final artwork(s) or screen captures along with any relevant additional information, for final approval. Files should be less than 10Mb each please.
    <br/><br/>
    Please note: You will need to upload all artwork originally submitted for approval.
    <br/><br/>
    We will respond with written approval within 5 working days.
  </FormDescription>),
  (<FormDescription>
    Please upload your design visual (JPEG, PNG, PDF or Word document) along with any relevant additional information. Files should be less than 10MB.
    <br/>
    <br/>
    Following approval of the design visual you will receive written confirmation along with a Trade Mark Licence.
  </FormDescription>)
];

const formTitles = [
  'Upload your final artwork(s) for approval',
  'Upload your design(s) for approval'
]

export default FileUploadPage
