import styled from 'styled-components';
import { ErrorField } from 'components/styled/shared/ErrorField';

export const FileUploaderWrapper = styled.div`
  .filepond--root {
    margin-bottom: 0;
  }
  &.error {
    border: 1px solid ${({ theme }) => theme.colors.alert};
    & ~ ${ErrorField} {
      display: inline-block;
    }
  }
`;
