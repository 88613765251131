import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as userActions from 'store/user/actions';
import { Redirect } from 'react-router';
import { ApplicationState } from 'store';
import {
  LoginFormWrapper,
  LoginFormErrorField,
  LoginFormSuccessField,
} from 'components/styled/login/LoginFormWrapper';
import { Button } from 'components/styled/shared/Button';
import queryString from 'query-string';

type FormData = {
  user: string;
  password: string;
};

export const LoginForm: React.FC<{ destination: string }> = ({
  destination,
}) => {
  const dispatch = useDispatch();
  const { destination: destinationUrl, ...rest } = queryString.parse(
    destination
  );

  const { register, handleSubmit, errors } = useForm<FormData>();

  const userState = useSelector((state: ApplicationState) => state.user);

  const onSubmit = handleSubmit(({ user, password }) => {
    dispatch(userActions.loginRequest(user.trim(), password));
  });

  let redirectDestination =
    (destinationUrl && destinationUrl.toString()) || '/admin';

  if (rest) {
    for (const property in rest) {
      redirectDestination += `&${property}=${rest[property]}`;
    }
  }

  if (userState.verification?.secretCode) {
    return <Redirect to="/login/authenticator" />;
  }

  if (
    userState.verification?.challengeName === 'NEW_PASSWORD_REQUIRED' &&
    userState.verification?.session
  ) {
    return <Redirect to="/login/change-password" />;
  }

  if (
    userState.verification?.challengeName === 'SOFTWARE_TOKEN_MFA' &&
    userState.verification?.session
  ) {
    return <Redirect to="/login/verify" />;
  }

  if (userState.isAuth === true) {
    return <Redirect to={redirectDestination} />;
  }

  const loginError = !!(
    errors.user ||
    errors.password ||
    userState.error ||
    userState.error === 0
  );

  return (
    <LoginFormWrapper>
      <h2>Log in</h2>

      {userState.verification?.success && userState.verification?.challengeName === 'SOFTWARE_TOKEN_MFA' && (
        <LoginFormSuccessField>
          You have finished the google authenticator setup successfully. Now you
          can log in to the page.
        </LoginFormSuccessField>
      )}
      {loginError && (
        <LoginFormErrorField>
          <span>Invalid username or password!</span>
        </LoginFormErrorField>
      )}

      <form onSubmit={onSubmit}>
        <input
          placeholder="Username"
          name="user"
          ref={register({ required: true })}
          type="text"
          className={errors.user ? 'error' : ''}
          autoFocus
        />
        <input
          placeholder="Password"
          name="password"
          ref={register({ required: true })}
          type="password"
          className={errors.password ? 'error' : ''}
        />
        <Button disabled={userState.isSignIn}>Submit</Button>
      </form>
    </LoginFormWrapper>
  );
};
