import styled from 'styled-components';
import { InputField } from 'components/styled/shared/InputField';

export const Label = styled.label<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  width: 100%;

  margin-bottom: 25px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.screen.size.small}px) {
    margin-bottom: 10px;
  }
`;

export const LabelPlaceholder = styled.div`
  display: block;
  width: 100%;

  margin-bottom: 15px;
`;

export const LabelInline = styled.label<{ width?: string }>`
  display: inline-block;
  width: ${({ width }) => (width === 'full' ? '100%' : '30%')};
  cursor: pointer;
  vertical-align: text-top;
  margin: 15px 0;
  word-break: break-words;
  @media only screen and (max-width: ${({ theme }) =>
      theme.screen.size.small}px) {
    width: 100%;
  }
  & > input[type='checkbox'] {
    width: 15px;
    margin: 0 5px 0 0;
    display: inline-block;
  }
  h5 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: ${({ theme }) => theme.font.size.default};
    display: inline;
    margin: 0;
  }
  ${InputField} {
    width: 100%;
  }
`;

export const LabelInlineText = styled.label`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  width: 100%;
  display: block;
`;

export const FieldLabel = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.small};
  padding-bottom: 4px;
`;
