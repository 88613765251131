import * as React from 'react';
import { ThankYouWrapper } from 'components/styled/shared/ThankYouWrapper';

export const AcceptLicenseCancelled: React.FC = () => (
  <ThankYouWrapper>
    <h2>Your request has been cancelled</h2>
    <p>Your request to use a BBC brand has been cancelled.</p>
    <p>
      Thank you.
      <br /> BBC Brand Guardianship
    </p>
  </ThankYouWrapper>
);
