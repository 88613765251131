import React from 'react'
import styled from 'styled-components'

const Spinner = styled.div`
  width: auto;
  height: 80px;
  text-align: center;
  font-size: 10px;
  margin-left:40px;

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
`

const SpinnerItem = styled.div`
  background-color: #333;
  height: 100%;
  width: 15px;
  display: inline-block;
  margin-left: 1px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
`

const SpinnerItem2 = styled(SpinnerItem)`
-webkit-animation-delay: -1.1s;
animation-delay: -1.1s;
`
const SpinnerItem3 = styled(SpinnerItem)`
-webkit-animation-delay: -1.0s;
animation-delay: -1.0s;
`
const SpinnerItem4 = styled(SpinnerItem)`
-webkit-animation-delay: -0.9s;
animation-delay: -0.9s;
`
const SpinnerItem5 = styled(SpinnerItem)`
-webkit-animation-delay: -0.8s;
animation-delay: -0.8s;
`

export default function LoadingSpinner() {
  return (
    <Spinner>
      <SpinnerItem />
      <SpinnerItem2 />
      <SpinnerItem3 />
      <SpinnerItem4 />
      <SpinnerItem5 />
    </Spinner>
  )
}
