import styled from 'styled-components';

export const LabelText = styled.div`
  display: inline-block;
  width: 30%;
  text-align: left;
  padding-right: 15px;
  vertical-align: top;
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.default};
  @media screen and (max-width: ${({ theme }) => theme.screen.size.small}px) {
    width: 100%;
    margin-bottom: 5px;
  }
`;
