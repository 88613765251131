import React from 'react';
import styled from 'styled-components';
import { SelectFieldOption } from 'types/workflow';

interface FieldRadioAgreeProps {
  fieldName: string;
  options?: SelectFieldOption[];
  register: any;
  required: string;
}

const RadioOption = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const RadioOptionLabel = styled.label`
  flex: 1
`;


const FieldRadioAgree: React.FC<FieldRadioAgreeProps> = ({
  fieldName,
  options,
  register,
  required,
}) => (
  <>
    {options?.map(o => (
      <RadioOption key={o.value}>
        <input
          name={fieldName}
          type="radio"
          value={o.value}
          ref={register({ required })}
          style={{ width: '1rem', marginRight: '1rem' }}
        />
        <RadioOptionLabel>{o.label}</RadioOptionLabel>
      </RadioOption>
    ))}
  </>
);

export default FieldRadioAgree;
