import React, { useEffect } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { acceptedFileTypes } from '../../../config/SubmitFormConfig';
import { EnvironmentConfig } from '../../../config/EnvironmentConfig';
import { Endpoint } from '../../../dataaccess/endpoints';
import { FileUploaderWrapper } from 'components/styled/shared/FileUploaderWrapper';
import { ErrorMessage } from 'react-hook-form';
import { ErrorField } from 'components/styled/shared/ErrorField';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

interface FileUploaderProps {
  name: string;
  setValue: any;
  register: any;
  errors?: any;
  errorMessage?: string;
  required?: string | boolean;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  name,
  setValue,
  register,
  errors,
  errorMessage,
  required,
}) => {
  const fileUploadServer = `${EnvironmentConfig.API_ENDPOINT_URL}${Endpoint.fileUpload}`;

  useEffect(() => {
    register(name, { required: required });
  }, [register]);

  const finalClassName = errors && errors[name] ? 'error' : '';

  return (
    <>
      <FileUploaderWrapper className={finalClassName}>
        <FilePond
          name={name}
          allowMultiple={true}
          allowFileSizeValidation={true}
          maxFileSize={EnvironmentConfig.FILE_UPLOAD_MAX_SIZE}
          server={fileUploadServer}
          allowFileTypeValidation={true}
          acceptedFileTypes={acceptedFileTypes}
          onupdatefiles={fileItems => {
            setValue(name, fileItems);
          }}
        />
      </FileUploaderWrapper>
      {errors ? (
        <ErrorField>
          <ErrorMessage
            errors={errors}
            name={name}
            message={errorMessage || `Invalid ${name}`}
          />
        </ErrorField>
      ) : null}
    </>
  );
};

export default FileUploader;
