import React from 'react';
import { BrandRequestForm } from 'components/container/BrandRequestForm';
import { ContentWrapper } from 'components/styled/shared/ContentWrapper';
import { Center } from 'components/styled/shared/Center';

export const BrandRequest = () => (
  <ContentWrapper>
    <Center>
      <BrandRequestForm />
    </Center>
  </ContentWrapper>
);
