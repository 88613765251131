import * as React from 'react';
import { FormHeadline } from 'components/styled/form/FormHeadline';
import { TextInputField } from 'components/presentational/shared/TextInputField';
import FormLabel from 'components/presentational/form/FormLabel';
import TextAreaField from 'components/presentational/shared/TextAreaField';
import { License } from '../../../dataaccess/requests/types';

interface TradeMarkLicenseProps {
  data?: License;
  register: any;
}

export const TradeMarkLicense: React.FC<TradeMarkLicenseProps> = ({
  data,
  register,
}) => {
  return (
    <>
      <FormHeadline>Trade Mark Licence</FormHeadline>
      <FormLabel text="Main agreement:">
        <TextInputField
          name="license[main]"
          register={register}
          value={data?.main}
        />
      </FormLabel>
      <FormLabel
        text="OR"
        subtext="Enter as follows: An agreement dated [ ] made between [ ] and the Licensee"
      >
        <TextAreaField
          name="license[main2]"
          register={register}
          value={data?.main2}
        />
      </FormLabel>
      <FormLabel text="Additional conditions:">
        <TextAreaField
          name="license[conditions]"
          register={register}
          value={data?.conditions}
        />
      </FormLabel>
    </>
  );
};
