import { Reducer } from 'redux';
import { LayoutState } from './types';
import * as layoutActions from './actions';
import { getType } from 'typesafe-actions';

export const initialState: LayoutState = {
  sideNavIsOpen: true,
  previewIsOpen: false,
  previewText: '', 
};

const reducer: Reducer<LayoutState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(layoutActions.sideNavToggle): {
      return { ...state, sideNavIsOpen: action.payload };
    }
    case getType(layoutActions.previewToggle): {
      return {
        ...state,
        previewIsOpen: !state.previewIsOpen,
      };
    }
    case getType(layoutActions.setPreviewText): {
      return {
        ...state,
        previewText: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as layoutReducer };
