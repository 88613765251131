import { Reducer } from 'redux';
import { ActionsState } from './types';
import * as actionsActions from './actions';
import { getType } from 'typesafe-actions';

export const initialState: ActionsState = {
  isActionProcessing: false,
  error: undefined,
  isPutActionSuccess: false,
};

const reducer: Reducer<ActionsState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actionsActions.putActionRequest): {
      return {
        ...state,
        isActionProcessing: true,
        error: undefined,
        isPutActionSuccess: false,
      };
    }

    case getType(actionsActions.putActionError): {
      return {
        ...state,
        isActionProcessing: false,
        error: action.payload.message,
        isPutActionSuccess: false,
      };
    }

    case getType(actionsActions.putActionSuccess): {
      return {
        ...state,
        isActionProcessing: false,
        error: undefined,
        isPutActionSuccess: true,
      };
    }

    case getType(actionsActions.resetAction): {
      return {
        ...state,
        isActionProcessing: false,
        error: undefined,
        isPutActionSuccess: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as actionsReducer };
