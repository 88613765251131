import styled from 'styled-components';
import { Button } from 'components/styled/shared/Button';

export const LogoutButton = styled(Button)`
  display: inline-block;
  @media only screen and (max-width: ${({ theme }) =>
      theme.screen.size.small}px) {
    margin-right: 5px;
  }
`;
