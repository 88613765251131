import React, { useState } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

interface MessageEditorProps {
  name: string;
  defaultValue?: string;
  register: any;
}

const MessageEditor: React.FC<MessageEditorProps> = ({
  name,
  defaultValue = '',
  register,
}) => {
  const [editorValue, setEditorValue] = useState(defaultValue);

  return (
    <>
      <ReactQuill theme="snow" value={editorValue} onChange={setEditorValue} />
      <input
        name={name}
        type="custom"
        ref={register}
        value={editorValue}
        style={{ display: 'none' }}
        onChange={() => {}}
      />
    </>
  );
};

export default MessageEditor;


/* // THIS IS THE PROPER WAY, but can not modify because of Details and Edit pages (can not drill control={control})
<Controller
  as={ReactQuill}
  name={name}
  control={control}
  defaultValue={defaultValue}
  rules={{ required: true, validate: text => isValidAgreementText(text) }}
/>

*/