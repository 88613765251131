import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { Button } from 'components/styled/shared/Button';
import { HeaderRow, LogTable } from 'components/styled/shared/CustomTable';
import { Modal } from 'components/presentational/shared/Modal';
import { useForm } from 'react-hook-form';
import MessageEditor from 'components/presentational/workflow/FieldMessageEditor';
import { LabelInline } from 'components/styled/shared/Label';
import { InputField } from 'components/styled/shared/InputField';
import * as requestsActions from '../../../store/requests/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import readableDate from '../../../utils/readableDate';
import { LoadingView } from 'components/presentational/shared/LoadingView';

export const ForwardedLog: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isDetailsLoading, details, messages } = useSelector(
    (state: ApplicationState) => state.requests
  );

  const forwardedMessages = messages.filter(message => message.is_forward);

  useEffect(() => {
    dispatch(requestsActions.getRequestMessages(id));
    dispatch(requestsActions.requestDetailsAction(id));
  }, [dispatch]);

  const { register, handleSubmit } = useForm<FormData>();

  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);

  if (isDetailsLoading || !id || !details) {
    return <LoadingView />;
  }

  const onSubmit = handleSubmit(submitData => {
    console.log('submitData: ', submitData);
  });

  return (
    <>
      <h3>
        {'Forwarded email log for {{COMPANY_NAME}}'.replace(
          '{{COMPANY_NAME}}',
          details.data.company_name
        )}
      </h3>
      <Button uppercase={true}>
        <Link to={`/admin/details/${id}`}>Back to details</Link>
      </Button>
      <LogTable>
        <tbody>
          <HeaderRow>
            <td>
              <strong>Date</strong>
            </td>
            <td>
              <strong>Email to</strong>
            </td>
            <td>
              <strong>Subject</strong>
            </td>
            <td>
              <strong>Message</strong>
            </td>
          </HeaderRow>
          {forwardedMessages.map((message, index) => (
            <tr key={index}>
              <td>{readableDate(message.email_date)}</td>
              <td>
                <div />
                <div />
              </td>
              <td />
              <td>
                <p>
                  <strong>{message.email_subject}</strong>
                </p>
                <div />
                <div
                  dangerouslySetInnerHTML={{
                    __html: message.message || '',
                  }}
                />

                <Button onClick={() => setIsReplyModalOpen(true)}>
                  Paste in reply
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </LogTable>
      <Modal
        title="Insert reply to forwarded message sent on 03.06.2020"
        onClose={() => setIsReplyModalOpen(false)}
        isOpen={isReplyModalOpen}
      >
        <form onSubmit={onSubmit}>
          <LabelInline width="full">
            <InputField>
              <MessageEditor name="message" register={register} />
            </InputField>
          </LabelInline>
        </form>
        <Button uppercase={true}>Post reply to log</Button>
      </Modal>
    </>
  );
};
