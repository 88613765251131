import React from 'react';
import { InputField } from 'components/styled/shared/InputField';
import { ErrorMessage, FieldError, NestDataObject } from 'react-hook-form';
import { ErrorField } from '../../styled/shared/ErrorField';

interface RegisterType {
  required?: boolean | string;
  pattern?: RegExp;
  maxLength?: number;
}

interface TextInputFieldProps<T> {
  name: string;
  required?: boolean | string;
  register: (e: HTMLInputElement | null, arg1: RegisterType) => {};
  className?: string;
  pattern?: RegExp;
  maxLength?: number;
  children?: React.ReactNode;
  placeholder?: string;
  value?: string;
  errors?: NestDataObject<T, FieldError>;
  readonly?: boolean;
  autoFocus?: boolean;
  errorMessage?: string;
  type?: string;
}

type TextInputFieldType<T = any> = React.FC<TextInputFieldProps<T>>;

export const TextInputField: TextInputFieldType = ({
  name,
  required,
  register,
  className,
  pattern,
  maxLength,
  children,
  value,
  errors,
  readonly,
  errorMessage,
  autoFocus,
                                                     type = 'text',
  ...rest
}) => {
  const finalClassName = className ?? (errors && errors[name] ? 'error' : '');
  return (
    <InputField>
      <input
        name={name}
        ref={e => register(e, { required, pattern, maxLength })}
        type={type}
        className={finalClassName}
        {...rest}
        defaultValue={value}
        readOnly={readonly}
        autoFocus={autoFocus}
      />
      {errors ? (
        <ErrorField>
          <ErrorMessage
            errors={errors}
            name={name}
            message={errorMessage || `Invalid ${name}`}
          />
        </ErrorField>
      ) : null}
      {children}
    </InputField>
  );
};
