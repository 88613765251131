import React from 'react';
import { NewsRequestForm } from 'components/container/NewsRequestForm';
import { ContentWrapper } from 'components/styled/shared/ContentWrapper';
import { Center } from 'components/styled/shared/Center';

export const NewsRequest = () => (
  <ContentWrapper>
    <Center>
      <NewsRequestForm />
    </Center>
  </ContentWrapper>
);
