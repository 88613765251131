import * as React from 'react';
import {
  CustomTable,
  TableRow,
  HeaderCell,
  DataCell,
} from 'components/styled/shared/CustomTable';
import readableDate from '../../../utils/readableDate';
import { RequestDetailsData } from '../../../dataaccess/requests/types';
import { Link } from 'react-router-dom';

interface ProductionLicenseAgreementProps {
  data: RequestDetailsData;
}

export const ProductionLicenseAgreement: React.FC<ProductionLicenseAgreementProps> = ({
  data,
}) => (
  <CustomTable>
    <tbody>
      <TableRow>
        <HeaderCell>License:</HeaderCell>
        <DataCell>
          <div dangerouslySetInnerHTML={{
            __html:
              data.license?.agreement ||
              'something went wrong with the license text',
          }}
          />
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>For the Company:</HeaderCell>
        <DataCell>
          <CustomTable>
            <tbody>
              <TableRow>
                <DataCell>
                  <strong>Agreed: </strong>
                  {data.license?.agree ? 'Yes' : 'No'}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Company name: </strong>
                  {data.license?.licensee_company}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Name: </strong>
                  {data.license?.licensee_name}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Position: </strong>
                  {data.license?.licensee_position}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Date: </strong>
                  {readableDate(data.license?.licensee_date)}
                </DataCell>
              </TableRow>
            </tbody>
          </CustomTable>
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>For the BBC:</HeaderCell>
        <DataCell>
          <CustomTable>
            <tbody>
              <TableRow>
                <DataCell>
                  <strong>Name: </strong>
                  {data.license?.bbc_name}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Position: </strong>
                  {data.license?.bbc_position}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Date: </strong>
                  {readableDate(data.license?.bbc_date)}
                </DataCell>
              </TableRow>
            </tbody>
          </CustomTable>
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Production Licence Agreement:</HeaderCell>
        <DataCell>
          {data.license?.agree && (
            <Link to={`/agreement/${data.data.branding_request_id}`}>
              View Production License Agreement
            </Link>
          )}
        </DataCell>
      </TableRow>
    </tbody>
  </CustomTable>
);
