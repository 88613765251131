import styled from 'styled-components';
import { ErrorField } from 'components/styled/shared/ErrorField';
import { Button } from 'components/styled/shared/Button';

export const LoginFormWrapper = styled.div`
  margin: 0px auto;
  margin-top: 80px;
  form {
    width: 500px;
    input {
      display: block;
      width: 100%;
      padding: 6px;
      border: 1px solid ${({ theme }) => theme.colors.border};
      font-size: ${({ theme }) => theme.font.size.default};
      &.error {
        border: 1px solid ${({ theme }) => theme.colors.alert};
      }
    }
  }
  h2,
  input {
    margin-bottom: 12px;
  }
  h2 {
    font-weight: ${({ theme }) => theme.font.weight.default};
  }
`;

export const LoginInputField = styled.div``;

export const LoginFormErrorField = styled(ErrorField)`
  display: block;
`;

export const LoginFormSuccessField = styled.div`
  color: ${({ theme }) => theme.colors.success};
  margin-bottom: 15px;
`;

export const ButtonWrapper = styled.div`
  ${Button} {
    margin: 15px 15px 0 0;
  }
`;

export const AuthenticatorForm = styled.form`
  margin-top: 15px;
`;

export const VerifyForm = AuthenticatorForm;
