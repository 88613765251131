import styled from 'styled-components';

export const SideNavToggleButton = styled.div`
  width: 14.5%;
  float: left;
  padding: 20px 0px 0px 22px;
  color: #fff;
  @media only screen and (max-width: ${({ theme }) =>
      theme.screen.size.small}px) {
    padding-left: 5px;
  }
  .toggle-bars {
    cursor: pointer;
    width: 24px;
    padding-top: 4px;
    .burger {
      height: 3px;
      background-color: #fff;
      width: 100%;
      margin-bottom: 5px;
    }
  }
`;
