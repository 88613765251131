import { createAction } from 'typesafe-actions';
import { AllActionRequest } from '../../dataaccess/actions/types';
import { AjaxError } from 'rxjs/ajax';

export const putActionRequest = createAction(
  '@@actions/ACTION_PUT_ACTION_REQUEST',
  (request: AllActionRequest) => request
)<AllActionRequest>();

export const putActionSuccess = createAction(
  '@@actions/ACTION_PUT_ACTION_SUCCESS'
)<void>();

export const resetAction = createAction(
  '@@actions/ACTION_RESET_ACTION'
)<void>();

export const putActionError = createAction(
  '@@actions/ACTION_PUT_ACTION_ERROR',
  (error: AjaxError) => error
)<AjaxError>();
