import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from 'components/styled/shared/Wrapper';
import { SideNavigation } from 'components/presentational/navigation/SideNavigation';
import { ContentWrapper } from 'components/styled/shared/ContentWrapper';
import { ApplicationState } from 'store';
import { Requests } from 'components/container/Requests';
import { NewRequests } from 'components/container/NewRequests';
import * as requestsAction from 'store/requests/actions';
import { Details } from 'components/container/Details';
import { Summary } from 'components/container/Summary';
import { Edit } from 'components/container/Edit';
import { Route, Switch } from 'react-router';
import AdminResponsePage from '../pages/AdminResponsePage';
import { EmailLog } from 'components/presentational/email-log/EmailLog';
import { ForwardedLog } from 'components/presentational/email-log/ForwardedLog';
import useSideNavigation from 'hooks/useSideNavigation';

export const Admin: React.FC = () => {
  const dispatch = useDispatch();

  const { sideNavIsOpen } = useSideNavigation();

  const { isMetadataLoading } = useSelector(
    (state: ApplicationState) => state.requests
  );

  useEffect(() => {
    dispatch(requestsAction.requestsAdminMetadata());
  }, [dispatch]);

  return (
    <Wrapper>
      {!isMetadataLoading && (
        <>
          <SideNavigation className={sideNavIsOpen ? 'show' : 'hidden'} />
          <ContentWrapper>
            <Switch>
              <Route path="/admin/summary" component={Summary} />
              <Route path="/admin/details/:id" component={Details} />
              <Route
                path="/admin/requests/:id/email_log"
                component={EmailLog}
              />
              <Route
                path="/admin/requests/:id/forwarded_log"
                component={ForwardedLog}
              />
              <Route path="/admin/edit/:id" component={Edit} />
              <Route
                path="/admin/requests/:type/:id"
                component={AdminResponsePage}
              />
              <Route path="/admin/:subpage/:id?" component={Requests} />
              <Route path="/admin" component={NewRequests} exact />
            </Switch>
          </ContentWrapper>
        </>
      )}
    </Wrapper>
  );
};
