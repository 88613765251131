import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { FieldLabel, Label } from 'components/styled/shared/Label';
import { LabelText } from 'components/styled/shared/LabelText';
import { InputField } from 'components/styled/shared/InputField';
import { Button } from 'components/styled/shared/Button';
import DatePicker from 'react-datepicker';
import { addDays, addMonths, format } from 'date-fns';
import { TextInputField } from 'components/presentational/shared/TextInputField';
import { Form } from 'components/styled/shared/Form';
import * as requestsActions from 'store/requests/actions';
import * as submitActions from 'store/submit/actions';
import { RequestTypeId } from 'store/requests/types';

import 'react-datepicker/dist/react-datepicker.css';
import { Logos } from 'components/presentational/form/Logos';
import { LoadingView } from 'components/presentational/shared/LoadingView';
import FormLabel from 'components/presentational/form/FormLabel';
import TextAreaField from 'components/presentational/shared/TextAreaField';
import { DropDown } from 'components/presentational/form/DropDown';
import { relationDropDownItems } from 'config/SubmitFormConfig';
import FileUploader from '../presentational/form/FileUploader';
import { Redirect } from 'react-router';

import { File } from 'react-filepond';
import { ErrorWrapper } from 'components/styled/workflow/ErrorWrapper';
import ErrorMessage from '../presentational/shared/ErrorMessage';

type FormData = {
  company_name: string;
  name: string;
  reqref: string;
  position: string;
  address: string;
  telephone: string;
  email: string;
  business: string;
  relationship: string;
  purpose_of_use: string;
  medium_format: string;
  territory: string;
  context: string;
  picture1: string;
  fileupload: File[];
  other_info: string;
};

export const BrandRequestForm: React.FC = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, errors } = useForm<FormData>();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(startDate, 1));

  const {
    isMetadataLoading,
    metadata: { assets, logos },
    error,
  } = useSelector((state: ApplicationState) => state.requests);

  const { isSubmitting, isSubmitSuccess, submitError } = useSelector(
    (state: ApplicationState) => state.submit
  );

  useEffect(() => {
    dispatch(requestsActions.requestsMetadata());
  }, [dispatch]);

  useEffect(() => {
    error && window.scrollTo(0, 0);
  }, [error]);

  const onSubmit = handleSubmit(data => {
    dispatch(
      submitActions.submitRequest({
        ...data,
        type: RequestTypeId.Branding,
        start_date: format(startDate, 'yyyy-MM-dd'),
        end_date: format(endDate, 'yyyy-MM-dd'),
      })
    );
  });

  if (isSubmitSuccess) {
    return (
      <Redirect
        to={{ pathname: '/thankyou', state: { type: RequestTypeId.Branding } }}
      />
    );
  }

  if (isMetadataLoading) {
    return <LoadingView />;
  }

  if (error) {
    return (
      <ErrorMessage>
        An error occurred while requesting data. Please try again.
      </ErrorMessage>
    );
  }

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);

    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
      >
        <h1>Use of BBC Assets</h1>
        <hr />
        <p>
          In order to grant the use of our assets, we must understand the context
          in which it will be used.
        </p>
        <p>
          Please fill in the form and upload a copy of the text or a visual of the
          design concept, if relevant, to accompany your request.
        </p>

        <FormLabel text="Company name">
          <TextInputField
            name="company_name"
            required="Please enter your company name"
            register={register}
            errors={errors}
          />
        </FormLabel>

        <FormLabel text="Contact name">
          <TextInputField
            name="name"
            required="Please enter your name"
            register={register}
            errors={errors}
          />
        </FormLabel>

        <FormLabel
          text="Please enter a reference name:"
          subtext="This should be maximum 8 characters long (no spaces) and will help us process your request."
        >
          <TextInputField
            name="reqref"
            required="Please enter a reference"
            register={register}
            errors={errors}
          />
        </FormLabel>

        <FormLabel text="Position">
          <TextInputField
            name="position"
            required="Please enter your position"
            register={register}
            errors={errors}
          />
        </FormLabel>

        <FormLabel text="Address">
          <TextAreaField
            name="address"
            errors={errors}
            register={register}
            required="Please enter your address"
          />
        </FormLabel>

        <FormLabel text="Telephone">
          <TextInputField
            name="telephone"
            required="Please enter your telephone number"
            register={register}
            errors={errors}
          />
        </FormLabel>

        <FormLabel text="Email address">
          <TextInputField
            name="email"
            required="Please enter a valid email address"
            pattern={/^\S{3,}@\S{3,}.[a-z]{2,}$/i}
            register={register}
            errors={errors}
            errorMessage="Invalid email address"
          />
        </FormLabel>

        <FormLabel text="Nature of business">
          <TextInputField
            name="business"
            required="Please enter the nature of your business"
            register={register}
            errors={errors}
          />
        </FormLabel>

        <FormLabel text="Relationship to BBC">
          <InputField>
            <DropDown
              name="relationship"
              register={register}
              errors={errors}
              required="Please select your relationship to BBC"
              items={relationDropDownItems}
            />
          </InputField>
        </FormLabel>

        <FormLabel text="BBC contact name">
          <TextInputField name="contact_name" register={register} />
        </FormLabel>

        <FormLabel text="BBC contact email" subtext="(if applicable)">
          <TextInputField name="contact_email" register={register} />
        </FormLabel>

        <Logos
          logos={logos}
          assets={assets}
          requestType={RequestTypeId.Branding}
          register={register}
        />

        <FormLabel text="Or please state any other BBC logo (such as a programme logo):">
          <TextInputField name="brand_assets" register={register} />
        </FormLabel>

        <FormLabel
          text="Purpose of use:"
          subtext="Who is the target audience – public service, commercial, business trade, consumers?"
        >
          <TextAreaField
            name="purpose_of_use"
            errors={errors}
            register={register}
            required="Please state purpose of use"
          />
        </FormLabel>

        <FormLabel
          text="Where do you intend to use our asset(s)?"
          subtext="(e.g. website, invitation, book, film; how and where distributed)"
        >
          <TextInputField
            name="medium_format"
            required="Where do you intend to use our asset(s)?"
            register={register}
            errors={errors}
          />
        </FormLabel>

        <Label>
          <LabelText>How long do you intend to use it?</LabelText>
        </Label>

        <FormLabel text="Start date">
          <InputField>
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              onChange={handleStartDateChange}
              dateFormat={'dd-MM-yyyy'}
              className="datePickerField"
            />
          </InputField>
        </FormLabel>

        <FormLabel text="End date">
          <InputField>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              minDate={addDays(startDate, 1)}
              maxDate={addMonths(startDate, 6)}
              dateFormat={'dd-MM-yyyy'}
              name="enddate"
              className="datePickerField"
            />
          </InputField>
        </FormLabel>

        <FormLabel text="Territory" subtext="(UK only or other)">
          <TextInputField
            name="territory"
            required="Please enter a territory"
            register={register}
            errors={errors}
          />
        </FormLabel>

        <FormLabel
          text="What is the context?"
          subtext="Is the BBC appearing with other organisations or brands? If so, what
            are they?"
        >
          <TextAreaField
            name="context"
            errors={errors}
            register={register}
            required="Please enter the context"
          />
        </FormLabel>

        <FormLabel text="File Upload">
          <InputField>
            <FieldLabel>
              Upload a file with your design concept or visual – JPG, PDF, PNG
              or Word document only, no more than 3MB each please.
            </FieldLabel>
            <FileUploader
              name="files"
              setValue={setValue}
              register={register}
              errors={errors}
              required="You must upload one file at least"
            />
          </InputField>
        </FormLabel>

        <FormLabel text="Any other information to support your request:">
          <TextAreaField
            name="other_info"
            errors={errors}
            register={register}
            required="Please give us some info"
          />
        </FormLabel>

        {(isSubmitting || submitError) && (
          <FormLabel>
            <ErrorWrapper>{submitError ? `${submitError}` : 'Processing...'}</ErrorWrapper>
          </FormLabel>
        )}

        <Button>Submit</Button>
      </Form>
    </>
  );
};
