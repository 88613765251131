import React from 'react';
import { Link } from 'react-router-dom';

import { Decision, getDecisions } from '../../../types/workflow';
import { ActionButton } from 'components/styled/shared/ActionButton';
import { RequestType } from '../../../dataaccess/requests/types';
import { ActionTypes } from 'dataaccess/actions/types';
import SetInactiveButton from './SetInactiveButton';

interface WorkflowActionButtonsProps {
  requestType?: RequestType;
  decisions?: Decision[];
  brandingRequestId: string;
}

const WorkflowActionButtons: React.FC<WorkflowActionButtonsProps> = ({
  requestType,
  decisions,
  brandingRequestId,
}) => {
  const displayedDecisions: Decision[] =
    decisions ?? getDecisions(requestType) ?? [];

  const getLink = (decision: Decision) =>
    `/admin/requests/${decision.type}/${brandingRequestId}`;

  return (
    <div>
      {displayedDecisions.map((decision, index) => {
        if (decision.actionType === ActionTypes.SetAsInactive) {
          return (
            <SetInactiveButton
              key={index}
              label={decision.label}
              requestId={brandingRequestId}
            />
          );
        } else {
          return (
            <Link key={index} to={getLink(decision)}>
              <ActionButton>{decision.label}</ActionButton>
            </Link>
          );
        }
      })}
    </div>
  );
};

export default WorkflowActionButtons;
