import {
  RequestDetailsData,
  SearchResults,
  SummaryResponse,
} from 'dataaccess/requests/types';
import { Message, CommentState, MessageData, NotificationData } from '../../dataaccess/messages/types';

export interface RequestsState {
  readonly data?: any;
  readonly metadata?: any;
  readonly isLoading: boolean;
  readonly isMetadataLoading: boolean;
  readonly query: string;
  readonly searchResults?: SearchResults[];
  readonly error: string | null;
  readonly isDetailsLoading: boolean;
  readonly details?: RequestDetailsData;
  readonly isSearching?: boolean;
  readonly isNewRequestsLoading: boolean;
  readonly summary?: SummaryResponse;
  readonly messages: Message[];
  readonly notifications?: NotificationData[];
  readonly comment?: CommentState
  readonly message: MessageData | null;
}

export enum RequestTypeId {
  Branding,
  News,
}
