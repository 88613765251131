import { EndpointDescription, HttpMethod } from './request/types';
import { getBaseHeaders, getAuthHeaders } from './request/createRequest';

export class Endpoint {
  static readonly signIn: EndpointDescription = {
    /* NEW ENDPOINT */
    method: HttpMethod.POST,
    url: `/auth?action={action}`,
    /* OLD ENDPOINT */
    // method: HttpMethod.PUT,
    // url: `/login`,
    headers: getBaseHeaders,
  };

  static readonly requests: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests?status={status}&offset={offset}&amount={amount}',
    headers: getAuthHeaders,
  };

  static readonly requestsByStatus: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests?amount={amount}&offset={offset}',
    headers: getAuthHeaders,
  };

  static readonly metadata: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/requests/metadata',
    headers: getBaseHeaders,
  };

  static readonly adminMetadata: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests/metadata',
    headers: getAuthHeaders,
  };

  static readonly details: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests/{id}',
    headers: getAuthHeaders,
  };

  static readonly detailsWithSignature: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/requests/{id}?expires={expires}&signature={signature}',
    headers: getBaseHeaders,
  };

  static readonly search: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests/search?q={query}',
    headers: getAuthHeaders,
  };

  static readonly searchWithStatus: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests/search?q={query}&status={status}',
    headers: getAuthHeaders,
  };

  static readonly submitRequest: EndpointDescription = {
    method: HttpMethod.PUT,
    url: '/requests',
    headers: getBaseHeaders,
  };

  static readonly acceptLicense: EndpointDescription = {
    method: HttpMethod.POST,
    url: '/requests/{id}/license/accept',
    headers: getBaseHeaders,
  };

  static readonly newRequests: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests/new',
    headers: getAuthHeaders,
  };

  static readonly editRequest: EndpointDescription = {
    method: HttpMethod.POST,
    url: '/admin/requests/',
    headers: getAuthHeaders,
  };

  static readonly putAction: EndpointDescription = {
    method: HttpMethod.PUT,
    url: '/admin/actions',
    headers: getAuthHeaders,
  };

  static readonly requestsSummary: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests/summary   ',
    headers: getAuthHeaders,
  };

  static readonly fileUpload: string = '/requests/upload';

  static readonly submitFiles: EndpointDescription = {
    method: HttpMethod.PUT,
    url: '/requests/{id}/upload',
    headers: getBaseHeaders,
  };

  static readonly getMessages: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/requests/{id}/messages',
    headers: getAuthHeaders,
  };

  static readonly getNotifications: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/admin/notifications',
    headers: getBaseHeaders,
  };

  static readonly editComment: EndpointDescription = {
    method: HttpMethod.POST,
    url: '/admin/requests/{id}/comment',
    headers: getAuthHeaders,
  };
  static readonly getMessage: EndpointDescription = {
    method: HttpMethod.GET,
    url: '/messages/{id}?expires={expires}&signature={signature}',
    headers: getAuthHeaders,
  };

  static readonly replyToMessage: EndpointDescription = {
    method: HttpMethod.PUT,
    url: '/messages/{id}/reply?expires={expires}&signature={signature}',
    headers: getBaseHeaders,
  };
}
