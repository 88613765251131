import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as requestsAction from '../store/requests/actions';
import { ApplicationState } from '../store';

const usePublicRequest = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const expires = params.get('expires');
    const signature = params.get('signature');
    if (expires && signature) {
      dispatch(
        requestsAction.requestDetailsWithSignatureAction(id, expires, signature)
      );
    } else {
      dispatch(
        requestsAction.requestDetailsAction(id)
      );
    }
  }, [dispatch, id]);

  return useSelector((state: ApplicationState) => state.requests);
};

export default usePublicRequest;
