import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './store/configureStore';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import App from './App';
import { theme } from 'theme/defaultTheme';

/**
 * Redux store setup
 */

const initialState = window.INITIAL_REDUX_STATE;

const history = createBrowserHistory();

export const store = configureStore(initialState, history);

/**
 * React main DOM renderer
 */

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App history={history} />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

