import { Observable, of } from 'rxjs';
import { Epic, combineEpics } from 'redux-observable';
import { ActionType, isActionOf } from 'typesafe-actions';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import * as actionsActions from './actions';
import { RootState } from 'store';
import { AjaxError } from 'rxjs/ajax';
import { ActionsActions } from './index';

type PutActionAction = ActionType<typeof actionsActions.putActionRequest>;

const putAction: Epic<ActionsActions, ActionsActions, RootState> = (
  action$,
  _$,
  { dataRepositories }
) =>
  action$.pipe(
    filter(isActionOf(actionsActions.putActionRequest)),
    switchMap(
      (action: PutActionAction): Observable<ActionsActions> => {
        return dataRepositories.actions.putAction(action.payload).pipe(
          map(() => actionsActions.putActionSuccess()),
          catchError((error: AjaxError) => of(actionsActions.putActionError(error)))
        );
      }
    )
  );

export const actionsEpics = combineEpics(putAction);
