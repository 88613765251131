import React from 'react';
import { getAgreementParagraphs } from '../../../../utils/workflowUtils';
import { PDFField, PDFOrderedField } from './PdfComponents';

interface AgreementViewProps {
  agreement: string;
}

const AgreementView: React.FC<AgreementViewProps> = ({ agreement }) => {
  return (
    <>
      {getAgreementParagraphs(
        agreement
      ).map(([isOrdered, index, text], rowIndex) =>
        isOrdered ? (
          <PDFOrderedField value={text} index={index} key={rowIndex} />
        ) : (
          <PDFField value={text} key={index} />
        )
      )}
    </>
  );
};

export default AgreementView;
