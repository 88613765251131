import { Observable, of } from 'rxjs';
import { Epic, combineEpics } from 'redux-observable';
import { ActionType, isActionOf } from 'typesafe-actions';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import * as userActions from './actions';
import { LoginError, LoginRequestRespond } from 'dataaccess/user/types';
import { RootState } from 'store';

type UserActions = ActionType<typeof userActions>;
type LoginRequestAction = ActionType<typeof userActions.loginRequest>;
type AuthenticatorVerificationAction = ActionType<typeof userActions.authenticatorVerification>;
type AccountVerificationAction = ActionType<typeof userActions.accountVerification>;

type ChangePasswordAction = ActionType<typeof userActions.changePassword>;

const signInEpic: Epic<UserActions, UserActions, RootState> = (
  action$,
  _$,
  { dataRepositories },
) =>
  action$.pipe(
    filter(isActionOf(userActions.loginRequest)),
    switchMap(
      (action: LoginRequestAction): Observable<UserActions> =>
        dataRepositories.user
          .signIn(action.payload.user, action.payload.password)
          .pipe(
            map((data: LoginRequestRespond) => {
              if (data.tokens?.idToken) {
                //sessionStorage.setItem('session_id', data.tokens.idToken);
                return userActions.accountVerificationSuccess(data);
              }
              return userActions.loginRequestSuccess({
                ...data,
                user: action.payload.user,
              });
            }),
            catchError((error: LoginError) => {
              return of(userActions.loginError(error));
            }),
          ),
    ),
  );

const authenticatorVerificationEpic: Epic<UserActions, UserActions, RootState> = (
  action$,
  _$,
  { dataRepositories },
) =>
  action$.pipe(
    filter(isActionOf(userActions.authenticatorVerification)),
    switchMap(
      (action: AuthenticatorVerificationAction): Observable<UserActions> => {
        return dataRepositories.user
          .verifyCode(action.payload.code, action.payload.accessToken)
          .pipe(
            map((data: LoginRequestRespond) => {
              return userActions.authenticatorVerificationSuccess(data);
            }),
            catchError((error: LoginError) => {
              return of(userActions.loginError(error));
            }),
          );
      },
    ),
  );

const accountVerificationEpic: Epic<UserActions, UserActions, RootState> = (
  action$,
  _$,
  { dataRepositories },
) =>
  action$.pipe(
    filter(isActionOf(userActions.accountVerification)),
    switchMap(
      (action: AccountVerificationAction): Observable<UserActions> => {
        return dataRepositories.user
          .verifyLogin(
            action.payload.code,
            action.payload.session,
            action.payload.user,
          )
          .pipe(
            map((data: LoginRequestRespond) => {
              //sessionStorage.setItem('session_id', action.payload.session);
              return userActions.accountVerificationSuccess(data);
            }),
            catchError((error: LoginError) => {
              return of(userActions.loginError(error));
            }),
          );
      },
    ),
  );

const verifyTokenEpic: Epic<UserActions, UserActions, RootState> = (
  action$,
  _$,
  { dataRepositories },
) =>
  action$.pipe(
    filter(isActionOf(userActions.verifyToken)),
    switchMap(
      (_): Observable<UserActions> => {
        return dataRepositories.user
          .verifyToken()
          .pipe(
            map(() => {
              return userActions.tokenVerificationSuccess();
            }),
            catchError((_: LoginError) => {
              return of(userActions.tokenVerificationError());
            }),
          );
      },
    ),
  );

const changePasswordEpic: Epic<UserActions, UserActions, RootState> = (
  action$,
  _$,
  { dataRepositories },
) =>
  action$.pipe(
    filter(isActionOf(userActions.changePassword)),
    switchMap(
      (action: ChangePasswordAction): Observable<UserActions> => {
        return dataRepositories.user
          .changePassword(
            action.payload.user,
            action.payload.newPassword,
            action.payload.session,
          )
          .pipe(
            map(() => {
              return userActions.changePasswordSuccess();
            }),
            catchError((_: LoginError) => {
              return of(userActions.changePasswordError());
            }),
          );
      },
    ),
  );

const signOutEpic: Epic<UserActions, UserActions, RootState> = (
  action$,
  _$,
  { dataRepositories },
) =>
  action$.pipe(
    filter(isActionOf(userActions.logout)),
    switchMap(
      (_): Observable<UserActions> => {
        return dataRepositories.user
          .signOut()
          .pipe(
            map(() => {
              return userActions.logoutSuccess();
            }),
            catchError((error: LoginError) => {
              return of(userActions.loginError(error));
            }),
          );
      },
    ),
  );

export const userEpics = combineEpics(
  signInEpic,
  authenticatorVerificationEpic,
  accountVerificationEpic,
  verifyTokenEpic,
  signOutEpic,
  changePasswordEpic,
);
