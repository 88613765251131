import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Decision } from 'types/workflow';
import DumbFormSection from 'components/presentational/shared/DumbFormSection';
import FormFieldRead from '../presentational/workflow/FieldRead';
import {
  RequestDetailsData,
  RequestType,
} from '../../dataaccess/requests/types';

import { useParams } from 'react-router';
import { LoadingView } from 'components/presentational/shared/LoadingView';
import { SecondaryButton } from 'components/styled/shared/SecondaryButton';
import TMLDocument from 'components/presentational/workflow/pdf/TMLDocument';
import PLADocument from 'components/presentational/workflow/pdf/PLADocument';
import { ContentWrapper } from 'components/styled/shared/ContentWrapper';
import AdminPageHeader from 'components/presentational/workflow/AdminPageHeader';
import FormFieldReadLogos from 'components/presentational/workflow/FieldReadLogos';
import usePublicRequest from '../../hooks/usePublicRequest';
import { getSchedulesWithLink } from '../../utils/workflowUtils';

interface WithTMLFormProps {
  data: RequestDetailsData;
  decision: Decision;
  register?: any;
  errors: any;
  control: any;
  setValue: any;
}

const ViewAgreement: React.FC<WithTMLFormProps> = ({}) => {
  const { id } = useParams();
  const { details, isDetailsLoading, error } = usePublicRequest();

  if (isDetailsLoading || !id || !details) {
    return <LoadingView />;
  }

  if (error || !details) return <h1>Resource does not exist.</h1>;

  const { schedules, license, logos, uploads } = details;

  const schedulesWithLink = schedules
    ? getSchedulesWithLink(schedules, uploads)
    : null;

  const {
    request_type,
    email,
    address,
    company_name,
    name,
    start_date,
    end_date,
    brand_assets,
    position,
    medium_format,
    purpose_of_use,
    telephone,
    territory,
    other_assets,
    film_name,
  } = details.data;

  const TML = () => (
    <>
      <AdminPageHeader
        title="Trade Mark Licence"
        subtitle="LICENCE FOR SHORT-TERM USE OF BBC TRADE MARKS ON SPECIFIC ITEMS"
      />
      <DumbFormSection title="1. LICENCEE">
        <FormFieldRead labelText="Company name:" value={company_name} />
        <FormFieldRead labelText="Contact name:" value={name} />
        <FormFieldRead labelText="Email address:" value={email} />
        <FormFieldRead labelText="Position:" value={position} />
        <FormFieldRead labelText="Address:" value={address} />
        <FormFieldRead labelText="Telephone:" value={telephone} />
      </DumbFormSection>

      <DumbFormSection title="2. TRADE MARK(S)">
        {logos && (
          <FormFieldReadLogos labelText="BBC logos requested:" logos={logos} />
        )}
        {brand_assets && (
          <FormFieldRead
            labelText="BBC programme logos:"
            value={brand_assets}
          />
        )}
      </DumbFormSection>

      <DumbFormSection title="3. DESCRIPTION OF THE ITEM(S)">
        <FormFieldRead value={medium_format} />
      </DumbFormSection>

      <DumbFormSection title="4. PURPOSE OF THE ITEM(S)">
        <FormFieldRead value={purpose_of_use} />
      </DumbFormSection>

      <DumbFormSection title="5. PERIOD OF USE">
        <FormFieldRead
          labelText="Start date:"
          value=""
          dateValue={start_date}
        />
        <FormFieldRead labelText="End date:" value="" dateValue={end_date} />
      </DumbFormSection>

      <DumbFormSection title="6. TERRITORY">
        <FormFieldRead value={territory} />
      </DumbFormSection>

      <DumbFormSection title="7. MAIN AGREEMENT">
        <FormFieldRead value={license?.main} />
        <FormFieldRead value="OR" />
        <FormFieldRead value={license?.main2} />
      </DumbFormSection>

      <DumbFormSection title="8. ADDITIONAL CONDITIONS">
        <FormFieldRead value={license?.conditions} />
      </DumbFormSection>

      <DumbFormSection title="" fullWidth>
        <div
          dangerouslySetInnerHTML={{
            __html:
              license?.agreement ||
              'something went wrong with the license text',
          }}
        />
      </DumbFormSection>

      <DumbFormSection title="Agreed by or on behalf of the Licensee:">
        <FormFieldRead
          labelText="Company name:"
          value={license?.licensee_company ?? company_name}
        />
        <FormFieldRead
          labelText="Contact name:"
          value={license?.licensee_name ?? name}
        />
        <FormFieldRead
          labelText="Position:"
          value={license?.licensee_position ?? position}
        />
        <FormFieldRead labelText="Date:" dateValue={license?.licensee_date} />
      </DumbFormSection>

      <DumbFormSection title="Agreed on behalf of the BBC:">
        <FormFieldRead labelText="Name:" value={license?.bbc_name} />
        <FormFieldRead labelText="Position:" value={license?.bbc_position} />
        <FormFieldRead labelText="Date:" dateValue={license?.bbc_date} />
      </DumbFormSection>
    </>
  );

  const PLA = () => (
    <>
      <AdminPageHeader
        title="Production Licence Agreement"
        subtitle={`Production Licence Agreement for the use of BBC assets in ${film_name ||
          '"missing film_name"'}`}
      />
      <DumbFormSection title={'1. LICENSEE'}>
        <FormFieldRead labelText="Company Name:" value={company_name} />
        <FormFieldRead labelText="Contact Name:" value={name} />
        <FormFieldRead labelText="Position:" value={position} />
        <FormFieldRead labelText="Address:" value={address} />
        <FormFieldRead labelText="Telephone:" value={telephone} />
        <FormFieldRead labelText="Email adress:" value={email} />
      </DumbFormSection>

      <DumbFormSection title="2. TRADE MARK(S) / ASSETS">
        {logos && (
          <FormFieldReadLogos labelText="BBC logos requested:" logos={logos} />
        )}
        {other_assets && (
          <FormFieldRead
            labelText="BBC programme logos:"
            value={other_assets}
          />
        )}
      </DumbFormSection>

      <DumbFormSection title={'3. TITLE OF THE FILM/PROGRAMME'}>
        <FormFieldRead value={film_name} />
      </DumbFormSection>

      <DumbFormSection title={'4. TERRITORY'}>
        <FormFieldRead value={territory} />
      </DumbFormSection>

      <DumbFormSection
        fullWidth={true}
        title="5. THE AGREEMENT"
        subtitle={`USE OF BBC LOGO/ASSETS IN "${film_name ||
          'missing film_name'}" (the "Film")`}
      >
        {schedulesWithLink?.map(s => (
          <FormFieldRead
            labelText={`Schedule ${s.letter}`}
            link={s.link !== undefined}
            url={s.link}
            value={s.name}
            key={s.name}
          />
        ))}
      </DumbFormSection>

      <DumbFormSection fullWidth title="">
        <div
          dangerouslySetInnerHTML={{
            __html:
              license?.agreement ||
              'something went wrong with the license text',
          }}
        />
      </DumbFormSection>

      <DumbFormSection title="">
        <h4>Agreed by or on behalf of the Company:</h4>
        <br />

        <FormFieldRead value={company_name} labelText="Company Name:" />
        <FormFieldRead value={license?.licensee_name} labelText="Name:" />
        <FormFieldRead
          value={license?.licensee_position}
          labelText="Position:"
        />
        <FormFieldRead dateValue={license?.licensee_date} labelText="Date:" />

        <h4>Approved on behalf of the BBC:</h4>
        <br />
        <FormFieldRead labelText="Name:" value={license?.bbc_name} />
        <FormFieldRead labelText="Position:" value={license?.bbc_position} />
        <FormFieldRead labelText="Date:" dateValue={license?.bbc_date} />
      </DumbFormSection>
    </>
  );

  return (
    <ContentWrapper>
      {request_type === RequestType.BRANDING ? <TML /> : <PLA />}
      <PDFDownloadLink
        document={
          request_type === RequestType.BRANDING ? (
            <TMLDocument
              title="Trade Mark License"
              subtitle="LICENCE FOR SHORT-TERM USE OF BBC TRADE MARKS ON SPECIFIC ITEMS"
              details={details}
            />
          ) : (
            <PLADocument
              title="Production Licence Agreement"
              subtitle="PRODUCTION LICENCE AGREEMENT FOR USE OF BBC TRADE MARK(S)/ASSETS ON SPECIFIC ITEMS"
              details={details}
            />
          )
        }
        fileName={`${company_name || name}_${
          request_type === RequestType.BRANDING ? 'tml' : 'pla'
        }_agreement.pdf`}
      >
        {({ blob, url, loading, error }) => (
          <SecondaryButton>
            {loading ? 'loading pdf...' : 'Download PDF'}
          </SecondaryButton>
        )}
      </PDFDownloadLink>
    </ContentWrapper>
  );
};

export default ViewAgreement;
