export interface LoginRequest {
  user: string;
  password: string;
}

export interface User {
  name: string;
  email: string;
}

export interface ProjectPermission {
  read: boolean;
  write: boolean;
  create: boolean;
  delete: boolean;
}

export interface ActiveProjectPermissions {
  upload: boolean;
  contacts: true;
  send: string;
  can_create_users: boolean;
  can_process_requests: boolean;
  project_access_expiration: string;
  PIXNote: ProjectPermission;
  PIXTag: ProjectPermission;
}

export interface ActiveProject {
  id: string;
  name: string;
  root_folder: string;
  default_upload_folder: string;
  has_approvers: boolean;
  can_use_airplay: boolean;
  can_use_display_mirroring?: boolean;
  permissions: ActiveProjectPermissions;
}

export interface UserData {
  userData: User;
  tokens: {
    idToken: string;
    accessToken: string;
  };
}

export enum LoginError {
  InvalidUser,
  UnauthorizedUser,
  NetworkFailure,
}

export interface LoginRequestRespond {
  secretCode?: string | null;
  accessToken?: string | null;
  success?: boolean | null;
  challengeName?: string | null;
  session?: string | null;
  user?: string | null;
  // TODO: remove when the new auth is launched
  tokens?: null | {
    accessToken: string;
    idToken: string;
  }
}
export interface Authenticator {
  code: string;
  accessToken: string;
}
export interface Account {
  code: string;
  session: string;
  user: string;
}

export interface ChangePasswordRequest {
  user: string;
  newPassword: string;
  session: string;
}
