import { map, catchError } from 'rxjs/operators';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { Observable, throwError } from 'rxjs';
import { UserData, LoginError } from './types';
import { Endpoint } from 'dataaccess/endpoints';
import { createRequest } from 'dataaccess/request/createRequest';

const signIn = (email: string, password: string): Observable<UserData> =>
  createRequest({
    endpoint: Endpoint.signIn,
    urlParams: new Map().set('action', 'login'),
    body: {
      user: email,
      password,
    },
  }).pipe(
    map((response: AjaxResponse) => response.response),
    catchError((error: AjaxError) =>
      throwError(
        error.status === 401
          ? LoginError.InvalidUser
          : LoginError.NetworkFailure
      )
    )
  );

const verifyCode = (code: string, accessToken: string): Observable<UserData> =>
  createRequest({
    endpoint: Endpoint.signIn,
    urlParams: new Map().set('action', 'verify'),
    body: {
      code,
      accessToken,
    },
  }).pipe(
    map((response: AjaxResponse) => response.response),
    catchError((error: AjaxError) =>
      throwError(
        error.status === 401
          ? LoginError.InvalidUser
          : LoginError.NetworkFailure
      )
    )
  );

const verifyLogin = (
  code: string,
  session: string,
  user: string
): Observable<UserData> =>
  createRequest({
    endpoint: Endpoint.signIn,
    urlParams: new Map().set('action', 'respond'),
    body: {
      code,
      session,
      user,
    },
  }).pipe(
    map((response: AjaxResponse) => response.response),
    catchError((error: AjaxError) =>
      throwError(
        error.status === 401
          ? LoginError.InvalidUser
          : LoginError.NetworkFailure
      )
    )
  );

const verifyToken = (): Observable<UserData> =>
  createRequest({
    endpoint: Endpoint.signIn,
    urlParams: new Map().set('action', 'verifyToken'),
    body: {}
  }).pipe(
    map((response: AjaxResponse) => response.response),
    catchError((error: AjaxError) =>
      throwError(
        error.status === 401
          ? LoginError.InvalidUser
          : LoginError.NetworkFailure
      )
    )
  );

const changePassword = (
  user: string,
  newPassword: string,
  session: string,
): Observable<UserData> =>
  createRequest({
    endpoint: Endpoint.signIn,
    urlParams: new Map().set('action', 'changePassword'),
    body: {
      user: user,
      new_password: newPassword,
      session: session,
    }
  }).pipe(
    map((response: AjaxResponse) => response.response),
    catchError((error: AjaxError) =>
      throwError(
        error.status === 401
          ? LoginError.InvalidUser
          : LoginError.NetworkFailure
      )
    )
  );

const signOut = (): Observable<void> =>
  createRequest({
    endpoint: Endpoint.signIn,
    urlParams: new Map().set('action', 'logout'),
    body: {}
  }).pipe(
    map((response: AjaxResponse) => response.response)
  );

export const userRepository = { signIn, verifyCode, verifyLogin, verifyToken, signOut, changePassword };
