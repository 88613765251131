import React from 'react';
import { Decision, Licenses } from 'types/workflow';
import FormField from '../Field';
import DumbFormSection from 'components/presentational/shared/DumbFormSection';
import FormFieldRead from '../FieldRead';
import { RequestDetailsData } from '../../../../dataaccess/requests/types';
import { ActionTypes } from '../../../../dataaccess/actions/types';
import { getFrontEndUrlForPath } from '../../../../utils/workflowUtils';
import FormFieldReadLogos from '../FieldReadLogos';
import { useDispatch } from 'react-redux';
import * as layoutActions from 'store/layout/actions';
import FormFieldOpenPreview from 'components/presentational/workflow/FieldOpenPreview';

interface WithTMLFormProps {
  data: RequestDetailsData;
  decision: Decision;
  register?: any;
  errors: any;
  control: any;
  setValue: any;
  watch: any;
}

const WithTMLForm: React.FC<WithTMLFormProps> = ({
  register,
  data,
  decision,
  errors,
  control,
  watch,
}) => {
  const dispatch = useDispatch();

  const onPreviewDefaultTML = (e: any) => {
    e.preventDefault();
    dispatch(layoutActions.setPreviewText(Licenses.TML));
    dispatch(layoutActions.previewToggle());
  };

  const {
    branding_request_id,
    email,
    address,
    company_name,
    name,
    start_date,
    end_date,
    brand_assets,
    position,
    medium_format,
    purpose_of_use,
    telephone,
    territory,
  } = data.data;

  const { logos } = data;

  const approvalCategories = [
    {
      value: '',
      label: '---- Please select approval category ----',
      disabled: true,
      selected: true,
    },
    {
      value: 'logo_approved',
      label: 'Logo approved',
    },
    {
      value: 'text_approved',
      label: 'Text or copyright word approved',
    },
    {
      value: 'hyperlink_approved',
      label: 'Hyperlink approved',
    },
    {
      value: 'bbc_identity_approved',
      label: 'BBC identity approved',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];
  const currentCategory = watch('category');

  return (
    <>
      {decision.link && (
        <FormField
          inputType="hidden"
          fieldName="url"
          defaultValue={getFrontEndUrlForPath(
            decision.link,
            branding_request_id
          )}
          register={register}
        />
      )}

      <FormField
        inputType="hidden"
        fieldName="action_id"
        defaultValue={ActionTypes.ApproveWithTML}
        register={register}
      />

      <FormField
        labelText="Approval categories:"
        inputType="selector"
        fieldName="category"
        register={register}
        required="Please select approval category"
        errors={errors}
        control={control}
        options={approvalCategories}
      />
      {currentCategory === 'other' && (
        <FormField
          labelText="Detailed reason:"
          inputType="input"
          fieldName="category_other"
          register={register}
          required="Please provide a reason"
          errors={errors}
        />
      )}

      <DumbFormSection title="1. LICENCEE">
        <FormFieldRead labelText="Company name:" value={company_name} />
        <FormFieldRead labelText="Contact name:" value={name} />
        <FormFieldRead labelText="Email address:" value={email} />
        <FormFieldRead labelText="Position:" value={position} />
        <FormFieldRead labelText="Address:" value={address} />
        <FormFieldRead labelText="Telephone:" value={telephone} />
      </DumbFormSection>

      <DumbFormSection title="2. TRADE MARK(S)">
        {logos && (
          <FormFieldReadLogos labelText="BBC logos requested:" logos={logos} />
        )}
        {brand_assets && (
          <FormFieldRead
            labelText="BBC programme logos:"
            value={brand_assets}
          />
        )}
      </DumbFormSection>

      <DumbFormSection title="3. DESCRIPTION OF THE ITEM(S)">
        <FormFieldRead value={medium_format} />
      </DumbFormSection>

      <DumbFormSection title="4. PURPOSE OF THE ITEM(S)">
        <FormFieldRead value={purpose_of_use} />
      </DumbFormSection>

      <DumbFormSection title="5. PERIOD OF USE">
        <FormFieldRead
          labelText="Start date:"
          value=""
          dateValue={start_date}
        />
        <FormFieldRead labelText="End date:" value="" dateValue={end_date} />
      </DumbFormSection>

      <DumbFormSection title="6. TERRITORY">
        <FormFieldRead value={territory} />
      </DumbFormSection>

      <DumbFormSection title="7. MAIN AGREEMENT">
        <FormFieldRead value={data.license?.main} />
        <FormFieldRead value="OR" />
        <FormFieldRead value={data.license?.main2} />
      </DumbFormSection>

      <DumbFormSection title="8. ADDITIONAL CONDITIONS">
        <FormFieldRead value={data.license?.conditions} />
        <FormFieldOpenPreview
          value="Text of the standard Trade Mark Licence"
          onPreview={onPreviewDefaultTML}
        />
      </DumbFormSection>

      <DumbFormSection title="Agreed by or on behalf of the Licensee:">
        <FormFieldRead
          labelText="Company name:"
          value={data.license?.licensee_company ?? company_name}
        />
        <FormFieldRead
          labelText="Contact name:"
          value={data.license?.licensee_name ?? name}
        />
        <FormFieldRead
          labelText="Position:"
          value={data.license?.licensee_position ?? position}
        />
        <FormFieldRead
          labelText="Start date:"
          value=""
          dateValue={start_date}
        />
      </DumbFormSection>

      <DumbFormSection title="Agreed on behalf of the BBC:">
        <FormField
          labelText="Name:"
          fieldName="bbc_name"
          inputType="input"
          register={register}
          errors={errors}
          required="Please provide your name"
        />

        <FormField
          labelText={'Position:'}
          fieldName={'bbc_position'}
          inputType={'input'}
          register={register}
          errors={errors}
          required="Please provide your position"
        />

        <FormField
          labelText={'Date:'}
          fieldName={'bbc_tml_date'}
          inputType={'date'}
          dateValue={undefined}
          register={register}
          errors={errors}
          required="Please provide the BBC signature date"
        />
      </DumbFormSection>

      <FormField inputType="hidden" fieldName="message" register={register} />
      <FormField inputType="hidden" fieldName="message2" register={register} />
      <FormField
        inputType="hidden"
        fieldName="agreement"
        register={register}
        defaultValue={data.license?.agreement}
      />
    </>
  );
};

export default WithTMLForm;
