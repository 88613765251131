import React from 'react';

import FormField from '../Field';
import { ActionTypes } from '../../../../dataaccess/actions/types';
import { DecisionType } from '../../../../types/workflow';
import { getFrontEndUrlForPath } from '../../../../utils/workflowUtils';

interface SendEmailFormProps {
  register: any;
  errors: any;
  decisionType: DecisionType;
}

const SendEmailForm: React.FC<SendEmailFormProps> = ({
  register,
  errors,
  decisionType,
}) => {
  return (
    <>
      {decisionType == DecisionType.SEND_EMAIL && (
        <FormField
          inputType="hidden"
          fieldName="action_id"
          defaultValue={ActionTypes.SendEmail}
          register={register}
        />
      )}

      <FormField
        inputType="hidden"
        fieldName="url"
        defaultValue={getFrontEndUrlForPath(`/messages/{id}/reply`, '')}
        register={register}
      />

      <FormField
        labelText="Email to:"
        inputType="input"
        fieldName="recipient"
        required="Please provide the recipient name"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Email address:"
        inputType="input"
        fieldName="recipient_address"
        required="Please provide the recipient email address"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Subject:"
        inputType="input"
        fieldName="subject"
        register={register}
        required="Please add your subject"
        errors={errors}
      />

      <FormField
        labelText="CC:"
        inputType="input"
        fieldName="cc_address"
        patternError="Only email address is allowed as CC"
        pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Message"
        inputType="editor"
        fieldName="message"
        required="Please compose your message"
        register={register}
        errors={errors}
      />

      <FormField
        labelText="Email from:"
        inputType="input"
        fieldName="sender"
        required="Please provide your name"
        register={register}
        errors={errors}
      />
    </>
  );
};

export default SendEmailForm;
