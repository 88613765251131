import React from 'react';
import { SelectFieldOption } from 'types/workflow';
import { Controller } from 'react-hook-form';

interface FieldSelectorProps {
  name: string;
  register: any;
  options?: SelectFieldOption[];
  disabled?: boolean;
  required?: string;
  control: any;
}

const FormSelector: React.FC<FieldSelectorProps> = ({
  name,
  options,
  disabled,
  required,
  control,
}) => {

  return (
    <Controller
      as={
        <select>
          {options?.map((o: SelectFieldOption) => (
            <option
              value={o.value}
              disabled={o.disabled || disabled}
              selected={o.selected || false}
              key={o.value}
            >
              {o.label}
            </option>
          ))}
        </select>
      }
      control={control}
      name={name}
      rules={{ required }}
      mode="onChange"
      defaultValue={undefined}
    />
  )
}

export default FormSelector;