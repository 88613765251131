import React from 'react';
import {
  CustomTable,
  DataCell,
  HeaderCell,
  TableRow,
} from 'components/styled/shared/CustomTable';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { CommentData } from 'dataaccess/messages/types';
import { Comment } from 'components/container/Comment';

interface MessagesProps {
  messages: {
    messageCount: number;
    forwardCount: number;
  };
  commentData?: CommentData;
}

export const Messages: React.FC<MessagesProps> = ({
  messages,
  commentData,
}) => {
  const { id } = useParams();

  return (
    <>
      <CustomTable>
        <tbody>
          <TableRow>
            <HeaderCell>
              <Link to={`/admin/requests/${id}/email_log`}>
                View direct email log
              </Link>
            </HeaderCell>
            <DataCell>You have sent {messages.messageCount} emails</DataCell>
          </TableRow>
          <TableRow>
            <HeaderCell>
              <Link to={`/admin/requests/${id}/forwarded_log`}>
                View forwarded email log
              </Link>
            </HeaderCell>
            <DataCell>There are {messages.forwardCount} emails</DataCell>
          </TableRow>
          <TableRow>
            <HeaderCell>General comments</HeaderCell>
            <DataCell>
              <Comment commentData={commentData} />
            </DataCell>
          </TableRow>
        </tbody>
      </CustomTable>
    </>
  );
};
