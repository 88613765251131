import styled from 'styled-components';
import { Button } from 'components/styled/shared/Button';

export const Form = styled.form`
  width: 100%;
  max-width: 1270px;
  ${Button} {
      margin-bottom: 25px;
  }
`;
