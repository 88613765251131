import React from 'react';
import styled from 'styled-components';
import Field from 'components/styled/workflow/Field';
import FieldLabelText from 'components/styled/workflow/FieldLabelText';
import FieldInput from 'components/styled/workflow/FieldInput';
import { PayloadAction } from 'typesafe-actions';

interface FieldOpenPreview {
  labelText?: string;
  value: string;
  onPreview: any
}

const FakeLinkItem = styled.div`
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
`;

const FieldOpenPreview: React.FC<FieldOpenPreview> = ({
  labelText,
  value,
  onPreview
}) => (
  <Field>
    <FieldLabelText>{labelText}</FieldLabelText>
    <FieldInput onClick={(e) => onPreview(e)}>
      <FakeLinkItem>{value}</FakeLinkItem>
    </FieldInput>
  </Field>
);

export default FieldOpenPreview;
