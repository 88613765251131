import React, { useState } from 'react';
import {
  CollapseWrapper,
  CollapseHeader,
  CollapseContent,
} from 'components/styled/shared/Collapse';

interface CollapseProps {
  headerText: string;
  children: React.ReactNode;
  isOpen?: boolean;
}

export const Collapse: React.FC<CollapseProps> = ({
  headerText,
  isOpen: defaultOpen,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(!!defaultOpen);
  return (
    <CollapseWrapper>
      <CollapseHeader isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {headerText}
      </CollapseHeader>
      <CollapseContent isOpen={isOpen}>
        {children}
      </CollapseContent>
    </CollapseWrapper>
  );
};
