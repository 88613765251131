import * as React from 'react';
import {
  CustomTable,
  TableRow,
  HeaderCell,
  DataCell,
} from 'components/styled/shared/CustomTable';
import { Collapse } from 'components/presentational/shared/Collapse';
import {
  License,
  RequestDetailsData,
} from '../../../dataaccess/requests/types';
import readableDate from '../../../utils/readableDate';
import { Link } from 'react-router-dom';

interface TradeMarkLicenseProps {
  data: RequestDetailsData;
}

export const TradeMarkLicense: React.FC<TradeMarkLicenseProps> = ({ data }) => (
  <CustomTable>
    <tbody>
      <TableRow>
        <HeaderCell>Main agreement:</HeaderCell>
        <DataCell>{data.license?.main}</DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Additional conditions:</HeaderCell>
        <DataCell>{data.license?.conditions}</DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>For the Company:</HeaderCell>
        <DataCell>
          <CustomTable>
            <tbody>
              <TableRow>
                <DataCell>
                  <strong>Agreed: </strong>
                  {data.license?.agree ? 'Yes' : 'No'}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Company name: </strong>
                  {data.license?.licensee_company}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Name: </strong>
                  {data.license?.licensee_name}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Position: </strong>
                  {data.license?.licensee_position}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Date: </strong>
                  {readableDate(data.license?.licensee_date)}
                </DataCell>
              </TableRow>
            </tbody>
          </CustomTable>
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>For the BBC:</HeaderCell>
        <DataCell>
          <CustomTable>
            <tbody>
              <TableRow>
                <DataCell>
                  <strong>Name: </strong>
                  {data.license?.bbc_name}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Position: </strong>
                  {data.license?.bbc_position}
                </DataCell>
              </TableRow>
              <TableRow>
                <DataCell>
                  <strong>Date: </strong>
                  {readableDate(data.license?.bbc_date)}
                </DataCell>
              </TableRow>
            </tbody>
          </CustomTable>
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Trade Mark Licence:</HeaderCell>
        <DataCell>
          {data.license?.agree && (
            <Link to={`/agreement/${data.data.branding_request_id}`}>
              View Trade Mark licence
            </Link>
          )}
        </DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>TML expiry reminder sent:</HeaderCell>
        <DataCell>{data.license?.reminder}</DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>Assets links sent:</HeaderCell>
        <DataCell>{readableDate(data.data.assets_sent)}</DataCell>
      </TableRow>
      <TableRow>
        <HeaderCell>No assets message sent:</HeaderCell>
        <DataCell></DataCell>
      </TableRow>
    </tbody>
  </CustomTable>
);
