import styled from 'styled-components';

export const Button = styled.button<{ uppercase?: true }>`
  color: ${({ theme }) => theme.colors.whiteText};
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none !important;
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.size.default};
  padding: 0.15rem 0.65rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  line-height: ${({ theme }) => theme.font.lineHeight.button};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  &:disabled {
    opacity: 0.3;
  }
  a {
    color: ${({ theme }) => theme.colors.whiteText};
    text-decoration: none;
  }
`;
