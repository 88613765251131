import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component?: any;
  render?: ({ match }: { match: any }) => React.ReactNode;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, render, location, exact, ...rest } = props;
  const { isAuth } = useAuth();

  if (render && isAuth) {
    return <Route {...rest} render={render} exact={exact}/>;
  }

  if (isAuth) {
    return (
      <Route {...rest} exact={exact} render={routeProps => <Component {...routeProps} />} />
    );
  }
  const redirectPathWithParams = `?destination=${location?.pathname}${location?.search}`;
  return (
    <Route
      {...rest}
      render={routeProps => (
        <Redirect
          to={{
            pathname: '/login',
            search: redirectPathWithParams,
            state: { from: routeProps.location },
          }}
        />
      )}
    />
  );
};
