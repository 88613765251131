import React from 'react';
import styled from 'styled-components';
import Field from 'components/styled/workflow/Field';
import FieldLabelText from 'components/styled/workflow/FieldLabelText';
import FieldInput from 'components/styled/workflow/FieldInput';

interface FormFieldReadLogosProps {
  labelText?: string;
  logos: any[];
}

const LogoItem = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.defaultText};
  font-size: ${({ theme }) => theme.font.size.default};
  margin-right: 0.5rem;
`;

const FormFieldReadLogos: React.FC<FormFieldReadLogosProps> = ({
  labelText,
  logos,
}) => (
  <Field>
    <FieldLabelText>{labelText}</FieldLabelText>
    <FieldInput>
      {logos.map(l => (
        <LogoItem key={l.name}>{l.name}</LogoItem>
      ))}
    </FieldInput>
  </Field>
);

export default FormFieldReadLogos;
