import * as React from 'react';
import { ThankYouWrapper } from 'components/styled/shared/ThankYouWrapper';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { ErrorBlock } from '../styled/shared/ErrorField';
import { RequestType } from '../../dataaccess/requests/types';

export const AcceptLicenseSuccess: React.FC = () => {
  const { details } = useSelector((state: ApplicationState) => state.requests);
  const { logoGuidelines, licenseUrl } = useSelector(
    (state: ApplicationState) => state.submit
  );

  if (!logoGuidelines || !details) {
    return <ErrorBlock>Something went wrong please try again later</ErrorBlock>;
  }

  const licenseTypeText =
    details.data.request_type === RequestType.BRANDING
      ? `Trade Mark licence`
      : 'Agreement';

  const logoGuidelinesView =
    details.data.request_type === RequestType.BRANDING ? (
      <>
        <li>
          Download your requested BBC assets here. Links will expire in 7 days.
        </li>
        <ul>
          {logoGuidelines &&
            logoGuidelines.map(logo => (
              <li>
                <a href={logo.url}>{logo.name}</a>
              </li>
            ))}
        </ul>
      </>
    ) : null;

  return (
    <ThankYouWrapper>
      <h2>Thank you</h2>
      <p>
        Thank you for accepting the terms and conditions of the{' '}
        {licenseTypeText}.
      </p>
      <ul style={{ marginLeft: '10px' }}>
        {logoGuidelinesView}
        <li>
          <a href={licenseUrl}>View and print your {licenseTypeText}</a>
        </li>
      </ul>
      <p>
        Thank you.
        <br />
        BBC Brand Guardianship
      </p>
    </ThankYouWrapper>
  );
};
