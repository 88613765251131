import React from 'react';

import { Decision } from 'types/workflow';
import FormField from '../Field';
import DumbFormSection from 'components/presentational/shared/DumbFormSection';
import FieldMessageEditor from 'components/presentational/workflow/FieldMessageEditor';
import {
  RequestDetailsData,
} from '../../../../dataaccess/requests/types';
import {
  getFrontEndUrlForPath,
  scheduleFieldNames,
  getScheduleFieldData,
  scheduleDropDownItems,
} from '../../../../utils/workflowUtils';
import { ActionTypes } from '../../../../dataaccess/actions/types';
import FormFieldRead from '../FieldRead';
import FormFieldReadLogos from '../FieldReadLogos';
import { DropDown } from '../../form/DropDown';

interface WithPLAFormProps {
  data: RequestDetailsData;
  decision: Decision;
  errors: any;
  register: any;
  control: any;
  watch: any;
}

const WithPLAForm: React.FC<WithPLAFormProps> = ({
  data,
  decision,
  register,
  errors,
  control,
  watch,
}) => {

  const {
    branding_request_id,
    email,
    address,
    company_name,
    name,
    start_date,
    position,
    telephone,
    territory,
    film_name,
    other_assets,
  } = data.data;

  const { logos } = data;

  const approvalCategories = [
    {
      value: '',
      label: '---- Please select approval category ----',
      disabled: true,
      selected: true,
    },
    {
      value: 'logo_approved',
      label: 'Logo Approved',
    },
    {
      value: 'bbc_identity_approved',
      label: 'BBC identity approved',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  const currentCategory = watch('category');

  const scheduleDropDownItemsData = scheduleDropDownItems(data);

  return (
    <>
      {decision.link && (
        <FormField
          inputType="hidden"
          fieldName="url"
          defaultValue={getFrontEndUrlForPath(
            decision.link,
            branding_request_id
          )}
          register={register}
        />
      )}

      <FormField
        inputType="hidden"
        fieldName="action_id"
        defaultValue={ActionTypes.ApproveWithPLA}
        register={register}
      />

      <FormField
        inputType="selector"
        fieldName="category"
        labelText="Approval categories:"
        required="Please select an approval category"
        options={approvalCategories}
        register={register}
        errors={errors}
        control={control}
      />
      {currentCategory === 'other' && (
        <FormField
          labelText="Detailed reason:"
          inputType="input"
          fieldName="category_other"
          register={register}
          required="Please provide a reason"
          errors={errors}
        />
      )}

      <FormField
        inputType="input"
        fieldName="recipient_address"
        labelText="Email to:"
        register={register}
        errors={errors}
      />

      <DumbFormSection title="1. COMPANY">
        <FormFieldRead labelText="Company name:" value={company_name} />
        <FormFieldRead labelText="Contact name:" value={name} />
        <FormFieldRead labelText="Email address:" value={email} />
        <FormFieldRead labelText="Position:" value={position} />
        <FormFieldRead labelText="Address:" value={address} />
        <FormFieldRead labelText="Telephone:" value={telephone} />
      </DumbFormSection>

      <DumbFormSection title="2. TRADE MARK(S)">
        {logos && (
          <FormFieldReadLogos labelText="BBC logos requested:" logos={logos} />
        )}
        {other_assets && (
          <FormFieldRead
            labelText="BBC programme logos:"
            value={other_assets}
          />
        )}
      </DumbFormSection>

      <DumbFormSection title="3. TITLE OF THE FILM / PROGRAMME">
        <FormFieldRead value={film_name} />
      </DumbFormSection>

      <DumbFormSection title="4. TERRITORY">
        <FormFieldRead value={territory} />
      </DumbFormSection>

      <DumbFormSection
        title="SCHEDULE"
        subtitle="Select from the dropdown lists Schedules as required in the Production Licence Agreement text below."
      >
        {scheduleFieldNames.map((fieldName, index) => {
          return (
            <FormField
              labelText={`Schedule ${fieldName}`}
              fieldName={`schedule[${fieldName}]`}
              inputType="schedule-selector"
              register={register}
              errors={errors}
              control={control}
              required={index === 0 ? 'Please setup a Schedule' : undefined}
              key={fieldName}
              scheduleOptions={scheduleDropDownItemsData}
              scheduleValue={getScheduleFieldData(fieldName, data.schedules)}
            />
          );
        })}
      </DumbFormSection>

      <DumbFormSection
        title={`Use of BBC logo/assets in "${film_name || 'unnamed film'}"`}
        subtitle={'NB **[REMOVE ALL INSTRUCTIONAL AND PLACEHOLDER COPY IN SQUARE BRACKETS BEFORE ISSUING THIS AGREEMENT]**'}
        fullWidth
        warningColor
      >
        <FieldMessageEditor
          name="agreement"
          defaultValue={data.license?.agreement}
          register={register}
        />
      </DumbFormSection>

      <DumbFormSection title="Agreed by or on behalf of the requesting Company">
        <FormFieldRead labelText="Company name:" value={company_name} />
        <FormFieldRead labelText="Contact name:" value={name} />
        <FormFieldRead labelText="Position:" value={position} />
        <FormFieldRead labelText="Date:" value="" />
      </DumbFormSection>

      <DumbFormSection title="Agreed on behalf of the BBC:">
        <FormField
          labelText="Name:"
          fieldName="bbc_name"
          inputType="input"
          register={register}
          errors={errors}
          required="Please provide your name"
        />

        <FormField
          labelText="Position:"
          fieldName="bbc_position"
          inputType="input"
          register={register}
          errors={errors}
          required="Please provide your position"
        />

        <FormField
          labelText="Date:"
          fieldName="bbc_tml_date"
          inputType="date"
          dateValue={undefined}
          register={register}
          errors={errors}
          required="Please provide the BBC signature date"
        />
      </DumbFormSection>

      <FormField inputType="hidden" fieldName="message" register={register} />
      <FormField inputType="hidden" fieldName="message2" register={register} />
    </>
  );
};

export default WithPLAForm;
