import * as React from 'react';
import { FormHeadline } from 'components/styled/form/FormHeadline';
import {
  LabelInline,
  LabelPlaceholder,
  LabelInlineText,
} from 'components/styled/shared/Label';
import { SmallText } from 'components/styled/shared/SmallText';
import { DropDown } from 'components/presentational/form/DropDown';
import { InputField } from 'components/styled/shared/InputField';
import { LabelText } from 'components/styled/shared/LabelText';
import MessageEditor from '../workflow/FieldMessageEditor';
import { scheduleFieldNames, getScheduleFieldData } from 'utils/workflowUtils';

interface ScheduleData {
  letter: string;
  name: string;
}

type SchedulesData = ScheduleData[];
interface ProductionLicenseAgreementProps {
  data: any;
  register: any;
  schedulesData?: SchedulesData;
  scheduleDropDownItems: {
    [key: string]: string;
  };
}

export const ProductionLicenseAgreement: React.FC<ProductionLicenseAgreementProps> = ({
  data,
  register,
  scheduleDropDownItems,
  schedulesData,
}) => {

  return (
    <>
      <FormHeadline>Production Licence Agreement</FormHeadline>
      <LabelPlaceholder>
        <LabelText>
          SCHEDULE:
          <SmallText>
            Edit the dropdown lists Schedules as required in the Production
            Licence Agreement text below
          </SmallText>
        </LabelText>
        <InputField>
          {scheduleFieldNames.map((fieldName, index) => {
            return (
              <LabelInline width="full" key={index}>
                <LabelInlineText>SCHEDULE {fieldName}:</LabelInlineText>
                <DropDown
                  name={`schedule[${fieldName}]`}
                  register={register}
                  items={scheduleDropDownItems}
                  value={getScheduleFieldData(fieldName, schedulesData)}
                  disableDefaultText={true}
                />
              </LabelInline>
            );
          })}
          <LabelInline width="full">
            <InputField>
              <MessageEditor
                name="agreement"
                defaultValue={data.agreement}
                register={register}
              />
            </InputField>
          </LabelInline>
        </InputField>
      </LabelPlaceholder>
    </>
  );
};
