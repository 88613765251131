import styled from 'styled-components';

export const Pagination = styled.div`
  display: flex;
  padding-left: 0;
  list-style: none;
  display: block;
  ul {
    display: inline-block;
    vertical-align: bottom;
  }
  button,
  li {
    position: relative;
    display: inline-block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: ${({ theme }) => theme.font.lineHeight.listItem};
    color: ${({ theme }) => theme.colors.paginationBlue};
    background-color: ${({ theme }) => theme.colors.defaultBackground};
    border: 1px solid ${({ theme }) => theme.colors.table.border};
    cursor: pointer;
    font-size: 1rem;
  }
  li.empty {
    cursor: default;
  }
  button > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.paginationBlue};
  }
  li > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.paginationBlue};
  }
  li.active {
    color: ${({ theme }) => theme.colors.whiteText};
    background-color: ${({ theme }) => theme.colors.paginationBlue};
    border-color: ${({ theme }) => theme.colors.paginationBlue};
  }
  li.active > a {
    color: ${({ theme }) => theme.colors.whiteText};
  }
  button:first-of-type {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  button:last-of-type {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  button:focus {
    outline: none;
  }
`;
