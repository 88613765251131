export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface EndpointDescription {
  method: HttpMethod;
  url: string;
  headers: () => any;
}

export interface FetchRequest {
  endpoint: EndpointDescription;
  urlParams?: Map<string, string>;
  headers?: any;
  body?: any;
}
