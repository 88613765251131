import React from 'react';
import { useForm } from 'react-hook-form';
import readableDate from 'utils/readableDate';
import TextAreaField from 'components/presentational/shared/TextAreaField';
import { Button } from 'components/styled/shared/Button';
import { AlertSpan } from 'components/styled/shared/AlertSpan';
import {
  ReplyToMessageWrapper,
  MessageWrapper,
  MessageData,
} from 'components/styled/reply/ReplyToMessage';
import useMessageReply from '../../hooks/useMessageReply';

interface FormData {
  message: string;
}

const ReplyToMessagePage: React.FC = () => {
  const { messageData, sendReply, isSubmitting, error } = useMessageReply();
  const { register, handleSubmit, errors } = useForm<FormData>();

  const onSubmit = handleSubmit(submitData => {
    sendReply(submitData.message);
  });

  if (error) {
    return (
      <ReplyToMessageWrapper>
        <MessageWrapper>
          <AlertSpan>You can't reply to this message</AlertSpan>
        </MessageWrapper>
      </ReplyToMessageWrapper>
    );
  }

  if (messageData?.isReplied) {
    return (
      <ReplyToMessageWrapper>
        <MessageWrapper>
          Thank you for your reply! <br /> Your message has been delivered.
        </MessageWrapper>
      </ReplyToMessageWrapper>
    );
  }

  return (
    <ReplyToMessageWrapper>
      <MessageWrapper>

        {messageData?.has_reply && (
          <AlertSpan>You have already replied to this message.</AlertSpan>
        )}

        {!messageData?.has_reply && (
          <>
            <MessageData>
              Please reply to the message received on{' '}
              <strong>{readableDate(messageData?.email_date)}</strong>:
            </MessageData>
            <form onSubmit={onSubmit}>
              <div>
                <TextAreaField
                  register={register}
                  name="message"
                  required="The reply message is required!"
                  errors={errors}
                />
              </div>
              <Button disabled={isSubmitting}>Reply</Button>
            </form>
          </>
        )}
      </MessageWrapper>
    </ReplyToMessageWrapper>
  );
};

export default ReplyToMessagePage;
