import * as React from 'react';
import { ThankYouWrapper } from 'components/styled/shared/ThankYouWrapper';
import { useLocation } from 'react-router';
import { RequestTypeId } from 'store/requests/types';

export const RequestThankYou: React.FC = () => {
  const { state }: { state: { type?: RequestTypeId } } = useLocation();

  return (
    <ThankYouWrapper>
      {state?.type === RequestTypeId.Branding ? (
        <>
          <h2>Thank you for your branding request submission.</h2>
          <ul>
            <li>
              Your request has been received and is now being processed by the
              BBC Brand Guardianship team.
            </li>
            <li>
              It may be necessary to consult with other BBC departments such as
              editorial, fair trading and legal, so please be patient while we
              consider your request.
            </li>
            <li>
              {' '}
              You will receive a response from us within 15 working days.
            </li>
          </ul>
          <p>
            Thank you.
            <br /> BBC Brand Guardianship
          </p>
        </>
      ) : (
        <>
          <h2>Thank you for your submission</h2>
          <ul>
            <li>
              Your request has been received by BBC Brand Guardianship and is
              being processed{' '}
            </li>
            <li>
              Please be aware your request must be signed off by various
              internal departments including Fair Trading, Editorial Policy and
              Legal which can take additional time
            </li>
            <li>
              You will receive a response from BBC Brand Guardianship within 15
              working days
            </li>
          </ul>
          <p>
            Thank you.
            <br /> BBC Brand Guardianship
          </p>
        </>
      )}
    </ThankYouWrapper>
  );
};
