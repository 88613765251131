import styled from 'styled-components';

export const ThankYouWrapper = styled.div`
  margin: 0 auto;
  width: 65%;
  padding: 20px;
  ul {
      list-style-type: disc;
      padding-left: 15px; 
      li {
        margin: 5px 0;
      }
  }
  @media screen and (max-width: ${({ theme }) => theme.screen.size.small}px) {
    width: 100%;
  }
`;
