import { useDispatch, useSelector } from 'react-redux';
import * as layoutActions from 'store/layout/actions';
import { ApplicationState } from 'store';

const useSideNavigation = () => {
  const dispatch = useDispatch();

  const { sideNavIsOpen } = useSelector(
    (state: ApplicationState) => state.layout
  );

  const sideNavToggle = () => {
    dispatch(layoutActions.sideNavToggle(!sideNavIsOpen));
  };

  return {
    sideNavIsOpen,
    sideNavToggle,
  };
};

export default useSideNavigation;
