import styled from 'styled-components';
import { Button } from './Button';

export const SecondaryButton = styled(Button)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.whiteText};
  background-color: ${({ theme }) => theme.colors.alert};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.small};
  border-radius: 0.25rem;
  padding: 0.2rem 0.4rem;
`;
