import { ErrorField } from '../../styled/shared/ErrorField';
import { ErrorMessage, FieldError, NestDataObject } from 'react-hook-form';
import { InputField } from '../../styled/shared/InputField';
import React from 'react';

interface RegisterType {
  required?: boolean | string;
  pattern?: RegExp;
  maxLength?: number;
}

type RegisterFuncType = (
  e: HTMLTextAreaElement | null,
  arg1: RegisterType
) => (instance: HTMLTextAreaElement) => void;

interface TextAreaFieldProps<T> {
  name: string;
  className?: string;
  required?: boolean | string;
  pattern?: RegExp;
  maxLength?: number;
  register: RegisterFuncType;
  errors?: NestDataObject<T, FieldError>;
  value?: string;
}

type TextAreaFieldType<T = any> = React.FC<TextAreaFieldProps<T>>;

const TextAreaField: TextAreaFieldType = ({
  name,
  className,
  required,
  pattern,
  maxLength,
  register,
  errors,
  value,
  children,
}) => {
  const finalClassName = className ?? (errors && errors[name] ? 'error' : '');
  return (
    <InputField>
      <textarea
        name={name}
        ref={e => register(e, { required, pattern, maxLength })}
        className={finalClassName}
        defaultValue={value}
      />
      {errors ? (
        <ErrorField>
          <ErrorMessage errors={errors} name={name} />
        </ErrorField>
      ) : null}
      {children}
    </InputField>
  );
};

export default TextAreaField;
