import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import {
  TableWrapper,
  TableHeader,
  TableFooter,
} from '../../styled/shared/Table';
import { Pagination } from '../../styled/table/Pagination';
import Search from 'components/container/Search';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { TablePageNumbers } from './TablePageNumbers';

interface TableProps {
  columns: {
    Header: string;
    accessor?: string;
    Cell?: any;
    disableSortBy?: boolean;
  }[];
  data: [];
  isSearching?: boolean;
  sortable?: boolean;
  displayOnlyTableBody?: boolean;
  pageSize?: number;
  setPageSize?: any;
  total?: number;
  page?: number;
  statusId?: string;
}

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  isSearching,
  sortable,
  displayOnlyTableBody,
  pageSize,
  setPageSize,
  total,
  page = 0,
  statusId,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sortable ? [
          {
            id: 'created_at',
            desc: true,
          },
        ] : [],
      },
    },
    useSortBy,
    usePagination
  );

  const columnNumber = headerGroups[0]?.headers?.length || 1;

  const pageNumber = page + 1;

  const hasPreviousPage = () => page > 0;
  const hasNextPage = () => pageSize && total && (page + 1) * pageSize < total;
  const maxPageNumber = (pageSize && total && Math.ceil(total / pageSize)) || 1;

  return (
    <TableWrapper>
      {!displayOnlyTableBody && (
        <TableHeader>
          <div>
            Show{' '}
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[1, 10, 25, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>{' '}
            entries
          </div>
          <Search statusId={statusId} />
        </TableHeader>
      )}
      <table {...getTableProps()}>
        {sortable && (
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={clsx(
                        { ['sortable']: !column.disableSortBy },
                        { ['asc']: column.isSorted && !column.isSortedDesc },
                        { ['desc']: column.isSorted && column.isSortedDesc }
                      )}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {!sortable &&
            headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
          {rows.length === 0 && isSearching && (
            <tr>
              <td colSpan={columnNumber}>Searching...</td>
            </tr>
          )}
          {rows.length === 0 && !isSearching && (
            <tr>
              <td colSpan={columnNumber}>There is no data</td>
            </tr>
          )}
        </tbody>
      </table>
      {!displayOnlyTableBody && total && pageSize ? (
        <TableFooter>
          {(page || page === 0) && (
            <div>
              Showing {page * pageSize + 1} to{' '}
              {page * pageSize + pageSize > total
                ? total
                : page * pageSize + pageSize}{' '}
              of {total} entries
            </div>
          )}
          <Pagination>
            {hasPreviousPage() && (
              <button>
                <Link to={`?page=${page - 1}&amount=${pageSize}`}>
                  Previous
                </Link>
              </button>
            )}
            <TablePageNumbers
              page={page}
              maxPageNumber={maxPageNumber}
              pageSize={pageSize}
              pageNumber={pageNumber}
            />
            {hasNextPage() && (
              <button>
                <Link to={`?page=${page + 1}&amount=${pageSize}`}>Next</Link>
              </button>
            )}
          </Pagination>
        </TableFooter>
      ) : null}
    </TableWrapper>
  );
};
