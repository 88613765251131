export const sideNavigationData = [
  {
    title: 'New requests',
    url: '/admin',
  },
  {
    title: 'View all requests',
    url: '/admin/requests',
  },
  {
    title: 'Approved requests',
    url: '/admin/requests/approved',
  },
  {
    title: 'Requests in process',
    url: '/admin/requests/inprocess',
  },
  {
    title: 'Declined requests',
    url: '/admin/requests/declined',
  },
  {
    title: 'Redirected',
    url: '/admin/requests/redirected',
  },
  {
    title: 'Brand advice',
    url: '/admin/requests/brandadvice',
  },
  {
    title: 'Cancelled',
    url: '/admin/requests/cancelled',
  },
  {
    title: 'Inactive',
    url: '/admin/requests/inactive',
  },
  {
    title: 'Summary',
    url: '/admin/summary',
  },
];

export const topNavigationData: {
  title: string;
  url: string;
  external?: boolean;
  authenticated?: boolean;
}[] = [
  {
    title: 'Branding requests',
    url: 'https://www.bbc.co.uk/branding',
    external: true,
  },
  {
    title: 'Use of BBC assets',
    url: 'https://www.bbc.co.uk/branding/logo-use',
    external: true,
  },
  {
    title: 'Using BBC Assets',
    url: '/brandrequest',
  },
  {
    title: 'Using BBC News Assets',
    url: '/newsrequest',
  },
  {
    title: 'Admin',
    url: '/admin',
    authenticated: true,
  },
];
