import styled from 'styled-components';

export const SideNavigationWrapper = styled.div`
  width: 17%;
  background-color: ${({ theme }) => theme.colors.sideNavBackground};
  color: ${({ theme }) => theme.colors.whiteText};
  transition: width 300ms;
  &.hidden {
    width: 0;
    overflow: hidden;
    ul {
      opacity: 0;
    }
  }
  ul {
    li {
      a {
        color: ${({ theme }) => theme.colors.lightText};
        padding: 18px 6px 16px 22px;
        &:hover {
          background-color: ${({ theme }) =>
            theme.colors.sideNavHoverBackground};
        }
      }
    }
  }
`;
