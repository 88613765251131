import styled from 'styled-components';

export const InlineTableWrapper = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  /* flex: 0 0 ${({ width }) => (width ? `${width}%` : '100%')}; */
  display: inline-block;
  padding: 0 15px;
`;

export const InlineTable = styled.table`
  border: 1px solid #000;
  border-collapse: collapse;
  width: 100%;
  td {
    border: 1px solid #000;
  }
`;

export const InlineTableHeaderRow = styled.div`
  width: 100%;
  border: 1px solid #000;
  border-bottom: none;
  font-size: ${({ theme }) => theme.font.size.h5};
  padding: 4px 4px 12px 4px;
`;

export const InlineTableRow = styled.tr`
  td {
    padding: 8px 8px;
  }
`;

export const InlineTableTitleCell = styled.td<{ bold?: boolean }>`
  width: 80%;
  font-weight: ${({ theme, bold }) => (bold ? theme.font.weight.bold : '')};
`;

export const InlineTableDataCell = styled.td`
  width: 20%;
`;
