import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { Decision } from '../../types/workflow';

import { ApplicationState } from '../../store';
import * as actionsActions from 'store/actions';
import * as layoutActions from 'store/layout/actions';
import { RequestDetailsData } from '../../dataaccess/requests/types';
import { ActionTypes } from 'dataaccess/actions/types';

import FormSelector from '../presentational/workflow/forms/FormSelector';
import FormDescription from 'components/styled/workflow/FormDescription';
import FormBody from 'components/styled/workflow/FormBody';
import FormButtons from 'components/presentational/workflow/FormButtons';
import ServerMessage from 'components/presentational/workflow/ServerMessage';
import PreviewModal from 'components/presentational/workflow/forms/PreviewModal';

import { createHTMLEmail, prepareScheduleData } from 'utils/workflowUtils';

interface AdminResponseFormProps {
  decision: Decision;
  requestDetails: RequestDetailsData;
  onSubmitted?: () => void;
}

const AdminResponseForm: React.FC<AdminResponseFormProps> = ({
  decision,
  requestDetails,
  onSubmitted,
}) => {
  const { description, answerString, helpText } = decision;
  const [messageToSend, setMessageToSend] = useState('');

  const dispatch = useDispatch();
  const { isPutActionSuccess, isActionProcessing, error } = useSelector(
    (state: ApplicationState) => state.actions
  );

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    errors,
    setValue,
    control,
    watch,
  } = useForm({
    defaultValues: {
      ...requestDetails.data,
      message: answerString,
      message2: helpText,
      recipient: requestDetails.data.name,
      sender: 'BBC Brand Guardianship',
      bbc_name: 'BBC Brand Guardianship Team',
      bbc_position: 'BBC Brand Guardianship',
      recipient_address: requestDetails.data.email,
    },
    submitFocusError: true,
  });

  useEffect(() => {
    setMessageToSend(createHTMLEmail(decision.type, getValues));
    setValue('msg_sent', messageToSend);
  }, [formState]);

  useEffect(() => {
    dispatch(layoutActions.setPreviewText(messageToSend));
  }, [messageToSend])

  const onSubmit = async (data: any) => {
    if (!formState.isSubmitting) {
      data.request_id = requestDetails.data.branding_request_id;
      if (data.action_id === ActionTypes.ApproveWithPLA) {
        data.schedules = prepareScheduleData(data.schedule);
      }
      dispatch(actionsActions.putActionRequest(data));
    }
  };

  if (isPutActionSuccess) {
    if (onSubmitted) {
      onSubmitted();
    }
  }

  const onPreview = (e: any) => {
    e.preventDefault();
    dispatch(layoutActions.previewToggle());
  };

  return (
    <>
      <FormBody onSubmit={handleSubmit(onSubmit)}>
        <FormDescription>{description}</FormDescription>
        <FormSelector
          register={register}
          data={requestDetails}
          decision={decision}
          errors={errors}
          setValue={setValue}
          control={control}
          watch={watch}
        />
        {(isActionProcessing || (formState.isSubmitted && error)) && (
          <ServerMessage error={error} />
        )}
        <FormButtons onPreview={onPreview} disabled={formState.isSubmitting} />
      </FormBody>

      <PreviewModal onClose={onPreview} />
    </>
  );
};

export default AdminResponseForm;
