import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

interface TablePageNumbersProps {
  page: number;
  pageSize: number;
  maxPageNumber: number;
  pageNumber: number;
}

export const TablePageNumbers: React.FC<TablePageNumbersProps> = ({
  page,
  pageSize,
  maxPageNumber,
  pageNumber,
}) => {
  let numbers = [];
  if (page - 1 >= 2) {
    numbers.push(
      <Fragment key="emptyFirst">
        <li key="first">
          <Link to={`?page=${0}&amount=${pageSize}`}>{1}</Link>
        </li>
        <li className="empty">...</li>
      </Fragment>
    );
  }
  for (let i = page - 1; i <= page + 3; i++) {
    if (i > 0 && i <= maxPageNumber) {
      numbers.push(
        <li key={i} className={i === pageNumber ? 'active' : ''}>
          <Link to={`?page=${i - 1}&amount=${pageSize}`}>{i}</Link>
        </li>
      );
    }
  }
  if (page + 3 < maxPageNumber) {
    numbers.push(
      <Fragment key="emptyMax">
        <li className="empty">...</li>
        <li key="max">
          <Link to={`?page=${maxPageNumber - 1}&amount=${pageSize}`}>
            {maxPageNumber}
          </Link>
        </li>
      </Fragment>
    );
  }
  return <ul>{numbers}</ul>;
};
