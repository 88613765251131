import { RequestDetailsData } from '../../../../dataaccess/requests/types';
import { Decision, DecisionType } from '../../../../types/workflow';
import WithTML from './WithTMLForm';
import WithPLAForm from './WithPLAForm';
import SendLinkForm from './SendLinkForm';
import Decline from './DeclineForm';
import SendEmail from './SendEmailForm';
import Forward from './ForwardForm';
import React from 'react';
import { LoadingView } from '../../shared/LoadingView';

interface FormSelectorProps {
  register: any;
  data: RequestDetailsData;
  decision: Decision;
  errors: any;
  setValue: any;
  control: any;
  watch: any;
}

const FormSelector: React.FC<FormSelectorProps> = ({
  register,
  data,
  decision,
  errors,
  setValue,
  control,
  watch,
}) => {
  switch (decision.type) {
    case DecisionType.APPROVE_TML:
      return (
        <WithTML
          data={data}
          register={register}
          decision={decision}
          errors={errors}
          control={control}
          setValue={setValue}
          watch={watch}
        />
      );
    case DecisionType.APPROVE_PLA:
      return (
        <WithPLAForm
          data={data}
          register={register}
          decision={decision}
          errors={errors}
          control={control}
          watch={watch}
        />
      );
    case DecisionType.APPROVE:
    case DecisionType.NO_VISUAL:
    case DecisionType.SEND_TML_REMINDER:
    case DecisionType.SEND_ARTWORK_REMINDER:
    case DecisionType.APPROVE_ARTWORK:
    case DecisionType.DECLINE_ARTWORK:
      return (
        <SendLinkForm
          data={data}
          register={register}
          decision={decision}
          errors={errors}
          control={control}
          watch={watch}
        />
      );
    case DecisionType.DECLINE:
      return (
        <Decline
          data={data.data}
          register={register}
          decision={decision}
          errors={errors}
          control={control}
          watch={watch}
        />
      );
    case DecisionType.SEND_EMAIL:
      return (
        <SendEmail
          register={register}
          errors={errors}
          decisionType={decision.type}
        />
      );
    case DecisionType.FORWARD:
    case DecisionType.REDIRECT:
    case DecisionType.FORWARD_ARTWORK:
      return (
        <Forward
          data={data}
          register={register}
          errors={errors}
          decision={decision}
          control={control}
          setValue={setValue}
          watch={watch}
        />
      );
  }

  return <LoadingView />;
};

export default FormSelector;
