import { userRepository } from './user/userRepository';
import { requestsRepository } from './requests/requestsRepository';
import { actionsRepository } from './actions/actionsRepository';
import { uploadRepository } from './upload/uploadRepository';
import { messagesRepository } from './messages/messagesRepository';

export const dataRepositories = {
  user: userRepository,
  requests: requestsRepository,
  actions: actionsRepository,
  upload: uploadRepository,
  messages: messagesRepository,
};
