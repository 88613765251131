import { DecisionType } from 'types/workflow';
import { FileData, FileStatus, RequestDetailsData, Schedule, ScheduleWithLink } from 'dataaccess/requests/types';

interface ScheduleData {
  letter: string;
  name: string;
}

export function getFrontEndUrlForPath(link: string, requestId: string): string {
  let hostName = new URL(window.location.href);
  const path = link.replace("{request_id}", requestId);
  return `${hostName.protocol}//${hostName.host}${path}`;
}

export const createHTMLEmail = (decisionType: DecisionType, getValues: any) => {
  const values = getValues();
  const name = getValues('name');

  const { message, message2, bbc_name, url, sender } = values;

  const messageTemplate = (options?: 'withAssets' | 'empty') => {
    return `<p>Dear ${name},</p>
      </br>
      <p>${message}</p>
      </br>
      ${
        options === 'withAssets'
          ? `<p><a href=${url}>missing asset links</a></p></br>`
          : ''
      }
      ${
        options === 'empty'
          ? ``
          : `<p>${message2}</p>
      </br>
      <p><a target="_blank" href=${url}>${url}</a></p>
      </br>`
      }
      <p>${bbc_name || sender}</p>
      `;
  };

  switch (decisionType) {
    case DecisionType.APPROVE_TML:
    case DecisionType.APPROVE_PLA:
      return messageTemplate();
    case DecisionType.APPROVE:
    case DecisionType.NO_VISUAL:
      return messageTemplate('withAssets');
    default:
      return messageTemplate('empty');
  }
};
export const scheduleFieldNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

export const getScheduleFieldData = (fieldName: string, schedulesData?: any[]) => {
  if (!schedulesData) {
    return null;
  }
  const dataIndex = schedulesData.findIndex(
    (data: ScheduleData) => data.letter === fieldName
  );
  return dataIndex === -1 ? "" : schedulesData[dataIndex].name;
};

export const scheduleDropDownItems = (details: RequestDetailsData) => {
  let items: {
    [key: string]: string;
  } = {};

  if (details.logos) {
    details.logos.forEach(item => {
      items[item.name] = item.name;
    });
  }
  if (details.assets) {
    details.assets.forEach(item => {
      items[item.name] = item.name;
    });
  }
  if (details.uploads) {
    details.uploads.forEach(item => {
      if (item.status === FileStatus.cleared) {
        items[item.name] = item.name;
      }
    });
  }
  return items;
};

export const prepareScheduleData = (schedules: any) => {
  let schedulesArray = [];
  for (let scheduleField in schedules) {
    if (schedules[scheduleField]) {
      schedulesArray.push({
        letter: scheduleField,
        name: schedules[scheduleField],
      });
    }
  }
  return schedulesArray;
};

export const getSchedulesWithLink = (schedules: Schedule[], uploads?: FileData[]): ScheduleWithLink[] | undefined => schedules.map(
  schedule => {
    return {
      letter: schedule.letter,
      name: schedule.name,
      link: uploads?.find(file => file.name === schedule.name)?.url,
    };
  }
);

export const getAgreementParagraphs = (agreement: string) => {
  const paragraphList: [boolean, number, string][] = [];
  const html = document.createElement('html');
  html.innerHTML = agreement;
  const paragraphs = html.querySelectorAll('ol, ul, p, div');
  Array.from(paragraphs).forEach((element) => {
    let index = 0;
    Array.from(element.childNodes).forEach((child) => {
      if (child.textContent != null) {
        const isOrdered = element.tagName === 'OL';
        if (isOrdered) index += 1;
        paragraphList.push([isOrdered, index, child.textContent.trim()]);
      }
    });
  });
  return paragraphList;
}
