import { createGlobalStyle } from 'styled-components';
import { Theme } from '../../../theme/defaultTheme';

interface GlobalStyleProps {
  theme: Theme;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.font.family.default};
    font-size: ${({ theme }) => theme.font.size.default};
    font-weight: ${({ theme }) => theme.font.weight.default};
    line-height: ${({ theme }) => theme.font.lineHeight.default};
    color: ${({ theme }) => theme.colors.defaultText};
    background-color: ${({ theme }) => theme.colors.defaultBackground};
  }
  * {
    box-sizing: border-box; 
  }
  ul { 
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      a {
        display: block;
        text-decoration: none;
      }
    }
  }
  h5 {
    font-weight: ${({ theme }) => theme.font.weight.default};
    font-size: ${({ theme }) => theme.font.size.h5};
  }
`;
