import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Form } from 'components/styled/shared/Form';
import { InputField } from 'components/styled/shared/InputField';
import { Button } from 'components/styled/shared/Button';
import { TextInputField } from 'components/presentational/shared/TextInputField';
import * as requestsActions from 'store/requests/actions';
import * as submitActions from 'store/submit/actions';
import { RequestTypeId } from 'store/requests/types';
import { Logos } from 'components/presentational/form/Logos';
import { LoadingView } from 'components/presentational/shared/LoadingView';
import FormLabel from '../presentational/form/FormLabel';
import { DropDown } from '../presentational/form/DropDown';
import TextAreaField from '../presentational/shared/TextAreaField';
import 'filepond/dist/filepond.min.css';
import FileUploader from '../presentational/form/FileUploader';
import { File } from 'react-filepond';
import { relationDropDownItems } from '../../config/SubmitFormConfig';
import { ErrorWrapper } from 'components/styled/workflow/ErrorWrapper';
import { FieldLabel } from 'components/styled/shared/Label';
import ErrorMessage from '../presentational/shared/ErrorMessage';

type FormData = {
  company_name: string;
  name: string;
  reqref: string;
  position: string;
  address: string;
  telephone: string;
  email: string;
  business: string;
  relationship: string;
  purpose_of_use: string;
  medium_format: string;
  territory: string;
  context: string;
  picture1: string;
  other_info: string;
  files: File[];
  uploadedFiles: string[];
};

export const NewsRequestForm: React.FC = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, errors } = useForm<FormData>();

  const {
    isMetadataLoading,
    error,
    metadata: { assets, logos },
  } = useSelector((state: ApplicationState) => state.requests);

  const { isSubmitting, isSubmitSuccess, submitError } = useSelector(
    (state: ApplicationState) => state.submit
  );

  const onSubmit = handleSubmit(data => {
    dispatch(
      submitActions.submitRequest({
        ...data,
        type: RequestTypeId.News,
      })
    );
  });

  useEffect(() => {
    dispatch(requestsActions.requestsMetadata());
  }, [dispatch]);

  if (isSubmitSuccess) {
    return <Redirect to="/thankyou" />;
  }

  if (isMetadataLoading) {
    return <LoadingView />;
  }

  if (error) {
    return (
      <ErrorMessage>
        An error occurred while requesting data. Please try again.
      </ErrorMessage>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      <h1>Submit your request to use BBC News assets in a film/programme</h1>
      <hr />
      <p>
        In order for us to consider your request to use BBC News assets in your
        production, we must understand the context in which they will be used.
      </p>
      <p>
        Please note while any information you provide to us will only be shared
        on a need to know basis within the organisation, we are not in a
        position to enter any confidentiality or non-disclosure agreement prior
        to receiving any information that you provide with your request.
      </p>
      <p>
        Further, if we are prepared to grant permission to use BBC News assets,
        we will only do so on our standard terms, a copy of which can be
        provided, after you have applied.
      </p>
      <p>
        Please fill in the form and upload a copy of the synopsis, script,
        storyboard and/or artwork, as relevant, to accompany your request.
      </p>

      <FormLabel
        text="Please enter a reference name:"
        subtext="This should be maximum 8 characters long (no spaces) and will help
              us process your request."
      >
        <TextInputField
          name="reqref"
          required="Please enter a reference name"
          maxLength={8}
          register={register}
          errors={errors}
        />
      </FormLabel>

      <FormLabel text="Company name">
        <TextInputField
          name="company_name"
          required="Please enter your company name"
          register={register}
          errors={errors}
        />
      </FormLabel>

      <FormLabel text="Contact name">
        <TextInputField
          name="name"
          required="Please enter your name"
          register={register}
          errors={errors}
        />
      </FormLabel>

      <FormLabel text="Position">
        <TextInputField
          name="position"
          required="Please enter your position"
          register={register}
          errors={errors}
        />
      </FormLabel>

      <FormLabel text="Address">
        <TextInputField
          name="address"
          required="Please enter your address"
          register={register}
          errors={errors}
        />
      </FormLabel>

      <FormLabel text="Telephone">
        <TextInputField
          name="telephone"
          required="Please enter your telephone number"
          register={register}
          errors={errors}
        />
      </FormLabel>

      <FormLabel text="Email address">
        <TextInputField
          name="email"
          required="Please enter a valid email address"
          pattern={/^\S{3,}@\S{3,}.[a-z]{2,}$/i}
          register={register}
          errors={errors}
          errorMessage="Invalid email address"
        />
      </FormLabel>

      <FormLabel text="Nature of business">
        <TextInputField
          name="business"
          required="Please enter the nature of your business"
          register={register}
          errors={errors}
        />
      </FormLabel>

      <FormLabel text="Relationship to BBC">
        <InputField>
          <DropDown
            name="relationship"
            register={register}
            errors={errors}
            required="Please select your relationship to BBC"
            items={relationDropDownItems}
          />
        </InputField>
      </FormLabel>

      <FormLabel text="BBC contact name">
        <TextInputField name="contact_name" register={register} />
      </FormLabel>

      <FormLabel text="BBC contact email" subtext="(if applicable)">
        <TextInputField name="contact_email" register={register} />
      </FormLabel>

      <Logos
        logos={logos}
        assets={assets}
        requestType={RequestTypeId.News}
        register={register}
      />

      <FormLabel text="Other BBC programme/channel logo:">
        <TextAreaField name="brand_assets" register={register} />
      </FormLabel>

      <FormLabel text="Other BBC assets (please state):">
        <TextAreaField name="other_assets" register={register} />
      </FormLabel>

      <FormLabel
        text="Title of the Film/Programme:"
        subtext="(Working title if final title is unknown)"
      >
        <TextAreaField
          name="film_name"
          register={register}
          required="Please enter the title of the Film/Programme:"
          errors={errors}
        />
      </FormLabel>

      <FormLabel text="Territory" subtext="(Worldwide or other)">
        <TextInputField
          name="territory"
          required="Please enter a territory"
          register={register}
          errors={errors}
        />
      </FormLabel>

      <FormLabel
        text="What is the context?"
        subtext="Is the BBC appearing with other organisations or brands? If so,
              what are they?"
      >
        <TextAreaField
          name="context"
          errors={errors}
          register={register}
          required="Please enter the context"
        />
      </FormLabel>

      <FormLabel text="File Upload">
        <InputField>
          <FieldLabel>
            Upload a file with your design concept or visual – JPG, PDF, PNG or
            Word document only, no more than 3MB each please.
          </FieldLabel>
          <FileUploader
            name="files"
            setValue={setValue}
            register={register}
            errors={errors}
          />
        </InputField>
      </FormLabel>

      <FormLabel text="Any other information to support your request:">
        <TextAreaField
          name="other_info"
          errors={errors}
          register={register}
          required="Please give us some info"
        />
      </FormLabel>

      {(isSubmitting || submitError) && (
        <FormLabel>
          <ErrorWrapper>
            {submitError ? `${submitError}` : 'Processing...'}
          </ErrorWrapper>
        </FormLabel>
      )}

      <Button>Submit</Button>
    </Form>
  );
};
