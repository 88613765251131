import { Store, createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  ApplicationState,
  createRootReducer,
  createRootEpics,
  RootAction,
  RootState,
} from './';
import { dataRepositories } from 'dataaccess';
import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';

export function configureStore(
  initialState: ApplicationState,
  history: History
): Store<ApplicationState> {
  const rootEpics = createRootEpics();
  const rootReducer = createRootReducer(history);
  const composeEnhancers = composeWithDevTools({});
  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState
  >({
    dependencies: {
      dataRepositories: dataRepositories,
      localStorage: localStorage,
      sessionStorage: sessionStorage,
    },
  });

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), epicMiddleware))
  );

  epicMiddleware.run(rootEpics);

  return store;
}
