import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    paddingVertical: 24,
    paddingHorizontal: 48,
    display: 'flex',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 12,
  },
  subtitle: {
    fontSize: 10,
  },
  section: {
    paddingVertical: 12,
    borderBottom: '1px solid black',
  },
  inlineSection: {
    flexDirection: 'row',
    paddingVertical: '6px',
    borderBottom: '1px solid black',
  },
  agreedSection : {
    flexDirection: 'row',
    marginTop: '14px'
  },
  agreedSectionTitle : {
    flex: 1,
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  inlineSectionContent: {
    flex: 5,
  },
  sectionTitle: {
    flex: 2,
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  sectionSubtitle: {
    fontSize: 12,
    fontWeight: 400,
    marginVertical: 6,
  },

  sectionContent: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 6,
  },
  block: {
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  field: {
    flexDirection: 'row',
    marginBottom: '10px',
  },
  value: {
    flex: 3,
    fontSize: 10
  },
  orderedIndex: {
    flexGrow: 1,
    fontSize: 10,
    width: 18,
  },
  orderedValue: {
    flexGrow: 5,
    fontSize: 10,
    textAlign: 'left',
    width: '100%',
  },
  scheduleValue: {
    flex: 5,
    fontSize: 10,
  },
  scheduleLink: {
    flex: 5,
    fontSize: 10,
    color: 'blue',
  },
  label: {
    flex: 2,
    fontSize: 10,
    fontWeight: 'bold'
  },
  scheduleLabel: {
    flex: 2,
    fontSize: 10,
    fontWeight: 'bold',
    paddingLeft: '10px',
    textAlign: 'right',
    marginRight: 10,
  },
});
