import { SecondaryButton } from '../../styled/shared/SecondaryButton';
import { Link } from 'react-router-dom';
import { Button } from '../../styled/shared/Button';
import { AlertSpan } from 'components/styled/shared/AlertSpan';
import React from 'react';
import readableDate from '../../../utils/readableDate';
import { RequestStatuses } from '../../../dataaccess/requests/types';
import {
  Decision,
  sendArtworkReminderDecision,
  sendTmlReminderDecision,
} from '../../../types/workflow';

export const getTableColumnsForStatus = (status: RequestStatuses) => {
  switch (status) {
    case RequestStatuses.Approved:
      return approvedTableColumns;
    case RequestStatuses.InProcess:
      return inProcessTableColumns;
    case RequestStatuses.Declined:
      return declinedTableColumns;
    case RequestStatuses.Redirected:
      return redirectedTableColumns;
    case RequestStatuses.Inactive:
    case RequestStatuses.Cancelled:
    case RequestStatuses.BrandAdvice:
      return minimalTableColumns;
    default:
      return defaultTableColumns;
  }
};

const editColumn = {
  Header: 'Edit',
  Cell: ({ row }: { [key: string]: any }) => {
    return (
      <Link to={`/admin/edit/${row.original.branding_request_id}`}>
        <Button>Edit</Button>
      </Link>
    );
  },
  disableSortBy: true,
};

const detailsColumn = {
  Header: 'Details',
  Cell: ({ row }: { [key: string]: any }) => {
    return (
      <Link to={`/admin/details/${row.original.branding_request_id}`}>
        <Button>View Details</Button>
      </Link>
    );
  },
  disableSortBy: true,
};

const reminderColumn = {
  Header: 'Reminder',
  Cell: ({ row }: { [key: string]: any }) => {
    const requestId = row.original.branding_request_id;
    let decision: Decision | undefined;

    if (!row.original.license_date && row.original.tml) {
      decision = sendTmlReminderDecision;
    } else if (!row.original.artwork_received) {
      decision = sendArtworkReminderDecision;
    }

    if (decision) {
      let path:
        | string
        | null = `/admin/requests/${decision?.type}/${requestId}`;
      return (
        <Link to={path}>
          <Button>{decision?.label}</Button>
        </Link>
      );
    }
    return null;
  },
  disableSortBy: true,
};

export const defaultTableColumns = [
  {
    Header: 'Received',
    accessor: 'created_at',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Request Type',
    accessor: 'request_type',
  },
  {
    Header: 'Status',
    accessor: 'request_status',
    Cell: ({ value }: { value: string }) => (
      <SecondaryButton>{value}</SecondaryButton>
    ),
  },
  detailsColumn,
  editColumn,
];

export const approvedTableColumns = [
  {
    Header: 'Received',
    accessor: 'created_at',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Request Type',
    accessor: 'request_type',
  },
  detailsColumn,
];

export const inProcessTableColumns = [
  {
    Header: 'Received',
    accessor: 'created_at',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Request Type',
    accessor: 'request_type',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }: { [key: string]: any }) => {
      if (!row.original.license_date && row.original.tml) {
        return <AlertSpan>Waiting for TML agreement</AlertSpan>;
      } else if (!row.original.artwork_received) {
        return <AlertSpan>Waiting for final artwork upload</AlertSpan>;
      }
      return null;
    },
  },
  {
    Header: 'TML agreed',
    accessor: 'tml_agreed',
    Cell: ({ row }: { [key: string]: any }) => {
      if (row.original.tml) {
        return <span>{readableDate(row.original.license_date) ?? '-'}</span>;
      } else {
        return <span>No TML</span>;
      }
    },
  },
  detailsColumn,
  reminderColumn,
];

export const declinedTableColumns = [
  {
    Header: 'Received',
    accessor: 'created_at',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Request Type',
    accessor: 'request_type',
  },
  {
    Header: 'Reason declined',
    accessor: 'decline_reason',
  },
  {
    Header: 'Date declined',
    accessor: 'final_decline',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  detailsColumn,
];

export const redirectedTableColumns = [
  {
    Header: 'Received',
    accessor: 'created_at',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Request Type',
    accessor: 'request_type',
  },
  {
    Header: 'Redirected to',
    accessor: 'redirected_to',
  },
  {
    Header: 'Email',
    Cell: ({ row }: { [key: string]: any }) => {
      return (
        <Link to={`/admin/requests/${row.original.branding_request_id}/forwarded_log`}>
          Forwarded email log
        </Link>
      );
    },
    disableSortBy: true,
  },
  detailsColumn,
];

export const minimalTableColumns = [
  {
    Header: 'Received',
    accessor: 'created_at',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Request Type',
    accessor: 'request_type',
  },
  detailsColumn,
];

export const brandingTableColumns = [
  {
    Header: 'Date',
    accessor: 'created_at',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
    disableSortBy: true,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Purpose of use',
    accessor: 'purpose_of_use',
    Cell: ({ value }: { value: string }) => (
      <span>
        {value && value.substring(0, 40).trimEnd()}
        {value && value.length > 41 && '...'}
      </span>
    ),
    disableSortBy: true,
  },
  {
    Header: 'TML start',
    accessor: 'start_date',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'request_status',
    Cell: ({ value }: { value: string }) => (
      <SecondaryButton>{value}</SecondaryButton>
    ),
    disableSortBy: true,
  },
  detailsColumn,
];

export const tmlTableColumns = [
  {
    Header: 'Date accepted',
    accessor: 'license_date',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'TML/Upload',
    accessor: 'is_artwork_uploaded',
    Cell: ({ value }: { value: number }) => (
      <AlertSpan>{value ? 'Final A/W upload' : 'TML accepted'}</AlertSpan>
    ),
  },
  {
    Header: 'Status',
    accessor: 'request_status',
    Cell: ({ value }: { value: string }) => (
      <SecondaryButton>{value}</SecondaryButton>
    ),
  },
  detailsColumn,
  {
    Header: 'TML Link',
    Cell: ({ row }: { [key: string]: any }) => (
      <Link to={`/agreement/${row.original.branding_request_id}`}>
        <Button>View TML</Button>
      </Link>
    ),
    disableSortBy: true,
  },
];

export const newsTableColumns = [
  {
    Header: 'Date',
    accessor: 'created_at',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Relationship',
    accessor: 'relationship',
  },
  {
    Header: 'Status',
    accessor: 'request_status',
    Cell: ({ value }: { value: string }) => (
      <SecondaryButton>{value}</SecondaryButton>
    ),
  },
  detailsColumn,
];

export const plaTableColumns = [
  {
    Header: 'Date accepted',
    accessor: 'agree_date_bbc',
    Cell: ({ value }: { value: Date }) => <span>{readableDate(value)}</span>,
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  detailsColumn,
  {
    Header: 'PLA Link',
    Cell: ({ row }: { [key: string]: any }) => (
      <Link to={`/agreement/${row.original.branding_request_id}`}>
        <Button>View PLA</Button>
      </Link>
    ),
    disableSortBy: true,
  },
];
