import React, { useEffect, useState } from 'react';
import { NotificationWrapper } from 'components/styled/header/NotificationWrapper';
import { NotificationCounter } from 'components/styled/header/NotificationCounter';
import { Icon } from 'components/styled/shared/Icon';
import { Icons } from 'theme/Icons';
import {
  NotificationDropDown,
  NotificationDropDownContent,
  NotificationDropDownHeader,
} from 'components/styled/header/NotificationDropDown';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { RequestType } from '../../dataaccess/requests/types';
import * as requestActions from 'store/requests'
import readableDate from '../../utils/readableDate';

export const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state: ApplicationState) => state.requests)
  const [isShowDropDown, setIsShowDropDown] = useState(false);

  useEffect(() => {
    dispatch(requestActions.getNotifications());
  }, [dispatch])

  if (!notifications || notifications.length === 0) {
    return null;
  }

  return (
    <NotificationWrapper onClick={() => isShowDropDown ? setIsShowDropDown(false) : null}>
      <NotificationDropDown show={isShowDropDown}>
        <NotificationDropDownHeader>Notifications</NotificationDropDownHeader>
        <NotificationDropDownContent>
          <ul>
            {notifications.map((notification, index) => {
              return (
                <li key={index} className={notification.request_type === RequestType.BRANDING ? 'branding' : 'news'}>
                  <Link to={`/admin/details/${notification.branding_request_id}`}>
                    <span>{notification.notification}</span>{' '}
                    <span className="date">{readableDate(notification.date)}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </NotificationDropDownContent>
      </NotificationDropDown>
      <Icon
        type={Icons.notification}
        onClick={() => setIsShowDropDown(!isShowDropDown)}
      />
      <NotificationCounter>{notifications.length}</NotificationCounter>
    </NotificationWrapper>
  );
};
