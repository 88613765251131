import React from 'react';
import QRCode from 'qrcode.react';

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import * as userActions from 'store/user/actions';

import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router';
import { Button } from 'components/styled/shared/Button';
import { TextInputField } from 'components/presentational/shared/TextInputField';
import {
  AuthenticatorForm,
  ButtonWrapper,
  LoginFormErrorField,
  LoginFormWrapper,
} from 'components/styled/login/LoginFormWrapper';

interface AuthenticatorProps {}

type FormData = {
  code: string;
};

export const Authenticator: React.FC<AuthenticatorProps> = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm<FormData>();
  const userState = useSelector((state: ApplicationState) => state.user);
  const { verification } = userState;

  if (!verification || !verification.accessToken || !verification.secretCode) {
    return <Redirect to="/login" />;
  }

  const onSubmit = handleSubmit(({ code }) => {
    verification.accessToken &&
      dispatch(
        userActions.authenticatorVerification(code, verification.accessToken)
      );
  });

  const onCancelVerification = () => {
    dispatch(userActions.cancelVerification());
  };

  return (
    <LoginFormWrapper>
      <h2>
        Open Google Authenticator on your mobile device and scan the below QR
        code to link your account.
      </h2>
      <QRCode value={verification?.secretCode} />
      <AuthenticatorForm onSubmit={onSubmit}>
        {(userState.error || userState.error === 0) && (
          <LoginFormErrorField>
            <span>
              The code is invalid or the token is expired, please try to log in
              again!
            </span>
          </LoginFormErrorField>
        )}
        <TextInputField
          name="code"
          required="Please enter your code"
          register={register}
          errors={errors}
          placeholder="Code"
          autoFocus={true}
        />
        <ButtonWrapper>
          <Button>Verify</Button>
          <Button onClick={() => onCancelVerification()}>
            Cancel verification
          </Button>
        </ButtonWrapper>
      </AuthenticatorForm>
    </LoginFormWrapper>
  );
};
