import * as React from 'react';
import { TopNavigationWrapper } from 'components/styled/navigation/TopNavigation';
import { NavLink } from 'react-router-dom';
import { topNavigationData } from '../../../config/NavigationConfig';

interface TopNavigationProps {
  isAuth: boolean;
}

export const TopNavigation: React.FC<TopNavigationProps> = ({ isAuth }) => {
  return (
    <TopNavigationWrapper>
      <ul>
        {topNavigationData.map((item, index) => {
          if (item.authenticated && !isAuth) {
            return null;
          }
          return (
            <li key={index}>
              {item.external ? (
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              ) : (
                <NavLink to={item.url} activeClassName="active">
                  {item.title}
                </NavLink>
              )}
            </li>
          );
        })}
      </ul>
    </TopNavigationWrapper>
  );
};
