import React from 'react';
import {
  ScreenContainer,
  ModalBody,
  ModalContainer,
  ModalHeader,
  CloseButton,
} from 'components/styled/shared/Modal';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
}) => {
  return (
    <ScreenContainer isOpen={isOpen}>
      <ModalContainer>
        <ModalHeader>
          {title}
          <CloseButton onClick={onClose}>X</CloseButton>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContainer>
    </ScreenContainer>
  );
};
