import { LabelText } from '../../styled/shared/LabelText';
import { SmallText } from '../../styled/shared/SmallText';
import { Label } from '../../styled/shared/Label';
import React from 'react';

interface FormLabelProps {
  text?: string;
  subtext?: string;
  hidden?: boolean;
}

const FormLabel: React.FC<FormLabelProps> = ({
  text,
  subtext,
  hidden,
  children,
}) => {
  return (
    <Label hidden={hidden}>
      <LabelText>
        {text}
        {subtext ? <SmallText>{subtext}</SmallText> : null}
      </LabelText>
      {children}
    </Label>
  );
};

export default FormLabel;
