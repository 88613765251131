import styled from 'styled-components';

export const ReplyToMessageWrapper = styled.div`
  width: 50%;
  margin: 0px auto;
  padding: 25px;
`;

export const MessageWrapper = styled.div`
  padding: 15px 0;
`;

export const MessageData = styled.div`
  padding: 8px 0;
  width: 100%;
`;
