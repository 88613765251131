import React from 'react';

interface PageTitleProps {
  title: String;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return <h3>{title}</h3>
}

export const PageSubTitle: React.FC<PageTitleProps> = ({ title }) => {
  return <h5>{title}</h5>
}
