import React, { useEffect, useState } from 'react';
import { RequestsList } from 'components/presentational/request/RequestsList';
import { ListComponentProps } from 'components/container/types';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../presentational/shared/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import * as requestsAction from '../../store/requests/actions';
import { LoadingView } from '../presentational/shared/LoadingView';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { RouterProps } from 'react-router';
import { RequestStatuses } from '../../dataaccess/requests/types';
import { getTableColumnsForStatus } from '../presentational/request/RequestsTableColumns';

enum StatusParamMapping {
  declined = 2,
  approved = 3,
  redirected = 4,
  brandadvice = 5,
  inprocess = 6,
  cancelled = 7,
  inactive = 8,
}

export const Requests: React.FC<ListComponentProps & RouterProps> = ({
  history,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    page = 0,
    amount = 25,
  }: { page?: number; amount?: number } = queryString.parse(location.search, {
    parseNumbers: true,
  });

  const [pageSize, setPageSize] = useState(amount || 25);

  const offset = page * amount;

  const {
    isLoading,
    data,
    error,
    query,
    searchResults,
    isSearching,
  } = useSelector((state: ApplicationState) => state.requests);

  const { items: dataItems = [] } = data;

  const statusId = StatusParamMapping[id];

  useEffect(() => {
    dispatch(requestsAction.requestListAction(statusId, offset, amount));
  }, [dispatch, statusId, amount, offset]);

  useEffect(() => {
    history.push(`?page=${page}&amount=${pageSize}`);
  }, [pageSize, page, history]);

  const displayData = query.length < 3 ? dataItems : searchResults;
  const totalResult = query.length < 3 ? data.total : searchResults?.length;

  const title = t(`requests_title.${id ?? 'all'}`);

  if (error) return <>Something went wrong, please try again later.</>;

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <PageTitle title={title} />
      <RequestsList
        sortable={true}
        customTableColumns={getTableColumnsForStatus(
          Number(statusId) as RequestStatuses
        )}
        data={displayData}
        isSearching={isSearching}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={totalResult}
        page={page}
        statusId={statusId}
      />
    </>
  );
};
