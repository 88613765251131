import styled from 'styled-components';

export const FieldInput = styled.div`
  flex: 3;
  padding: 0 6px 0 6px;

  input,
  textarea,
  select {
    display: block;
    width: 100%;
    padding: 6px;
    font-family: inherit;
    font-size: ${({ theme }) => theme.font.size.default};
    border: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

export default FieldInput;
