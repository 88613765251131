import styled from 'styled-components';
import { Button } from 'components/styled/shared/Button';

export const CustomTable = styled.table`
  max-width: 1000px;
  width: 100%;
  tr {
    td:first-child {
      font-weight: ${({ theme }) => theme.font.weight.bold};
      width: 30%;
    }
    td {
      width: 70%;
      padding: 8px 0;
      vertical-align: top;
    }
  }
`;

export const TableRow = styled.tr<{ showIf?: number; requestType?: number }>`
  display: ${({ showIf, requestType }) =>
    typeof showIf === 'undefined'
      ? 'table-row'
      : showIf === requestType
      ? 'table-row'
      : 'none'};
`;

export const HeaderCell = styled.td`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  width: 30%;
  a {
    color: ${({ theme }) => theme.colors.link};
    text-decoration: none;
  }
`;
export const DataCell = styled.td`
  width: 70%;
  padding: 8px 0;
  vertical-align: top;
  ${Button} {
    margin-left: 15px;
  }
`;

export const PicturePad = styled.div`
  a {
    color: ${({ theme }) => theme.colors.link};
    text-decoration: none;
  }
  margin-bottom: 8px;
`;

export const LogTable = styled.table`
  width: 100%;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.table.darkBorder};
  }
  td {
    vertical-align: top;
    padding: 8px;
    &:last-of-type {
      width: 50%;
    }
  }
`;

export const HeaderRow = styled.tr`
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const DisabledData = styled.div`
  color: ${({ theme }) => theme.colors.disabledText};
  margin-bottom: 12px;
`;

export const ReplyRow = styled.tr`
  background: ${({ theme }) => theme.colors.table.replyBackground};
`;

export const MessageBlock = styled.div`
  margin: 1rem 0;
  & > p {
    margin: 0;
  }
`;
