import styled from 'styled-components';
import { ErrorField } from 'components/styled/shared/ErrorField';

export const InputField = styled.div<{ fullWidth?: boolean }>`
  display: inline-block;

  width: ${({ fullWidth }) => (fullWidth ? '100%' : '70%')};
  @media screen and (max-width: ${({ theme }) => theme.screen.size.small}px) {
    width: 100%;
  }
  input,
  textarea,
  select {
    font-family: inherit;
    width: 100%;
    padding: 6px;
    font-size: ${({ theme }) => theme.font.size.default};
    display: block;
    border: 1px solid ${({ theme }) => theme.colors.border};
    &.error {
      border: 1px solid ${({ theme }) => theme.colors.alert};
      & ~ ${ErrorField} {
        display: inline-block;
      }
    }
  }

  & > input[type='checkbox'] {
    width: 15px;
  }
  textarea {
    height: 100px;
  }
  .asset {
    width: 83px;
    display: inline-block;
    text-align: left;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
