import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
  global: {
    backgroundColor: '#333',
    moduleContentPanelBackgroundColor: '#222',
    loadingSpinnerSize: '35px',
    loadingSpinnerBorder: '3px',
  },
  font: {
    family: {
      default: 'ReithSans,Arial,Helvetica,freesans,sans-serif',
    },
    size: {
      default: '1rem',
      small: '0.8rem',
      h3: '1.75rem',
      h4: '1.5rem',
      h5: '1.25rem',
    },
    weight: {
      default: 400,
      semiBold: 600,
      bold: 700,
    },
    lineHeight: {
      button: 1.5,
      default: 1.3,
      headline: 1.2,
      listItem: 1.25,
    },
  },
  colors: {
    defaultBackground: '#ffffff',
    defaultText: '#333333',
    lightText: '#d8d8d8',
    whiteText: '#ffffff',
    brandBackground: '#275680',
    sideNavBackground: '#1f313d',
    sideNavHoverBackground: '#0f181f',
    alert: '#dc3545',
    success: '#28a745',
    secondary: '#aaaaaa',
    link: '#0782C1',
    paginationBlue: '#007bff',
    headerBorder: '#7d9ab3',
    border: '#cccccc',
    disabledText: '#cccccc',
    collapseHeaderBackground: '#f1f1f1',
    lightGrey: 'rgb(170,170,170)',
    table: {
      darkerRow: 'rgba(0,0,0,.05)',
      border: '#dee2e6',
      color: '#212529',
      darkBorder: `#000000`,
      replyBackground: '#eaf3f7',
    },
  },
  screen: {
    size: {
      small: 768,
    },
  },
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
