import React from 'react';
import { View, Text, Font, Link } from '@react-pdf/renderer';
import { pdfStyles } from './pdf-styles';
import { RequestDetailsData } from 'dataaccess/requests/types';

import fontDefault from '../../../../theme/fonts/BBCReithSans_W_Rg.woff';
import fontBold from '../../../../theme/fonts/BBCReithSans_W_Bd.woff';
import fontLight from '../../../../theme/fonts/BBCReithSans_W_Lt.woff';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: fontDefault }, // font-style: normal, font-weight: normal
    { src: fontLight, fontWeight: 300 },
    { src: fontBold, fontWeight: 700 },
  ],
});

export interface PDFDocProps {
  title: string;
  subtitle: string;
  details: RequestDetailsData;
}

interface PDFInlineSectionProps {
  value?: any;
  title?: string;
  label?: string;
  children: any;
}

interface PDFSectionProps {
  title?: string;
  subtitle?: string;
  children: any;
}

interface PDFAgreedSectionProps {
  children: any;
  title: string;
}

interface PDFBlockSectionProps {
  value: any;
}

interface PDFFieldProps {
  index?: number;
  value?: any;
  label?: string;
}

interface FlexSpacerProps {
  flexNumber?: number;
}

interface PDFScheduleFieldProps extends PDFFieldProps {
  url?: string;
}

export const PDFSection: React.FC<PDFSectionProps> = ({
  title,
  children,
  subtitle,
}) => (
  <View style={pdfStyles.section}>
    <Text style={pdfStyles.sectionTitle}>{title}</Text>
    {subtitle && <Text style={pdfStyles.sectionSubtitle}>{subtitle}</Text>}
    <br />
    <View style={pdfStyles.sectionContent}>{children}</View>
  </View>
);

export const PDFAgreedSection: React.FC<PDFAgreedSectionProps> = ({
  children,
  title,
}) => (
  <View style={pdfStyles.agreedSection}>
    <FlexSpacer />
    <View style={{ flex: 2 }}>
      <Text style={pdfStyles.agreedSectionTitle}>{title}</Text>
      <View>{children}</View>
    </View>
  </View>
);

export const FlexSpacer: React.FC<FlexSpacerProps> = ({ flexNumber = 1 }) => (
  <View style={{ flex: flexNumber }} />
);

export const PDFInlineSection: React.FC<PDFInlineSectionProps> = ({
  title,
  children,
}) => (
  <View style={pdfStyles.inlineSection}>
    <Text style={pdfStyles.sectionTitle}>{title}</Text>
    <View style={pdfStyles.inlineSectionContent}>{children}</View>
  </View>
);

export const PDFBlockSection: React.FC<PDFBlockSectionProps> = ({ value }) => (
  <View style={pdfStyles.block}>
    <Text style={pdfStyles.field}>{value}</Text>
  </View>
);

export const PDFField: React.FC<PDFFieldProps> = ({ label, value }) => (
  <View style={pdfStyles.field}>
    {label && <Text style={pdfStyles.label}>{label}</Text>}
    <Text style={pdfStyles.value}>{value}</Text>
  </View>
);

export const PDFOrderedField: React.FC<PDFFieldProps> = ({ index, value }) => (
  <View style={pdfStyles.field}>
    <Text style={pdfStyles.orderedIndex}>{index}.</Text>
    <Text style={pdfStyles.orderedValue}>{value}</Text>
  </View>
);

export const PDFScheduleField: React.FC<PDFScheduleFieldProps> = ({
  label,
  url,
  value,
}) => (
  <View style={pdfStyles.field}>
    {label && <Text style={pdfStyles.scheduleLabel}>{label}</Text>}
    {!url ? (
      <Text style={pdfStyles.scheduleValue}>{value}</Text>
    ) : (
      <Link style={pdfStyles.scheduleLink} src={url}>{value}</Link>
    )}
  </View>
);
