import { File } from 'react-filepond';

export enum UploadType {
  artworks,
  files
}

export interface FileSubmissionRequest {
  requestId: string;
  uploadedFiles: string[];
  tempFiles: File[];
  other_comments: string;
  uploadType: UploadType;
}
