import styled from 'styled-components';

export const SectionTitle = styled.div<{ fullWidth?: boolean }>`
  width: ${props => (props.fullWidth ? '100%' : '43%')};

  & > p {
    size: ${props => props.theme.font.size.h4};
    font-weight: ${props => props.theme.font.weight.bold};
    margin: 0;
  }
`;

export default SectionTitle;
