import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import * as actionsActions from 'store/actions';

import { ActionButton } from 'components/styled/shared/ActionButton';

interface SetInactiveButtonProps {
  label?: string;
  requestId: string;
}

const SetInactiveButton: React.FC<SetInactiveButtonProps> = ({
  label = 'Set as inactive',
  requestId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const args = {
    action_id: 'SetAsInactive',
    request_id: requestId,
    status: 8,
  };
  const handleSetInactive = () => {
    dispatch(actionsActions.putActionRequest(args));
    return history.push('/admin/inactive');

  };

  return (
    <ActionButton onClick={() => handleSetInactive()}>{label}</ActionButton>
  );
};

export default SetInactiveButton;
